import axios from "axios";
import { useEffect, useState } from "react";
import { PrintItemType } from "../../utils/type";
import { PrintLabelTable } from "../tables/PrintLabelTable";
import { handleError, handleSuccess } from "../../utils/functions";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { useShowPrintStore } from "../../utils/store/showPrintStore";
import { useInputValuesStore } from "../../utils/store/inputValuesStore";

export const PrintLabel = () => {
  const [queueId, setQueueId] = useState<number>();
  const [templateId, setTemplateId] = useState<number>();
  const [priceListId, setPriceListId] = useState<string>();
  const [uomId, setUomId] = useState<number | undefined>();
  const [freeText, setFreeText] = useState<string>();
  const { darkMode } = useDarkModeStore();
  const [printItem, setPrintItem] = useState<PrintItemType[]>([]);

  const { inputValues } = useInputValuesStore();
  const { setShowPrint, printItemSku, setPrintItemSku } = useShowPrintStore();
  const sendAllItemLabel = () => {
    printItem.forEach((item) => {
      sendItemLabel(item, true);
    });
    handleSuccess("All label sent successfully");
    handleClosePrintSelection();
  };

  const handlePrintSelection = async (sku: string | undefined) => {
    if (!sku) return;
    try {
      const itemResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/inventory/item-labels/item/${sku}`
      );
      if (!itemResponse?.data) {
        return;
      }
      setPrintItem([...printItem, itemResponse.data.results[0]]);

      setShowPrint(true);
    } catch (error: any) {
      handleError(error);
    }
  };
  useEffect(() => {
    handlePrintSelection(printItemSku);
  }, []);

  const sendItemLabel = async (
    product: PrintItemType,
    isAllItem: boolean = false
  ) => {
    const { id, quantity, itemWeight, batch, individualFreeText, item } =
      product;
    const body = {
      jobs: {
        id,
        uomId: item.defaultUomId,
        quantity,
        itemWeight,
        batch,
        freeText: individualFreeText || freeText,
      },
      queueId,
      templateId,
      priceListId,
      uomId,
    };
    try {
      const updateResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/inventory/item-labels/item/${item.sku}`,
        {
          body,
        }
      );

      const { error, errors, warning } = updateResponse.data;
      if (error || errors || warning) {
        handleError(updateResponse.data);
        return;
      }
      if (!isAllItem) {
        handleSuccess("Label sent");
        handleClosePrintSelection();
      }
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };
  const handleClosePrintSelection = () => {
    setPrintItemSku(undefined);
    setShowPrint(false);
  };

  return (
    <div className=" dark:bg-bgDarkColor">
      <div className="w-full p-2 bg-mainColor dark:bg-mainColorDarkMode transition-colors duration-500 flex justify-between items-center gap-1 rounded-t-sm">
        <h2 className="font-semibold text-lg text-white">
          Parts label printing
        </h2>
        <div className="flex gap-1 items-center">
          <button
            onClick={sendAllItemLabel}
            className={`border dark:border-gray-400 bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 shadow-md dark:shadow-slate-600 h-[35px] p-2 rounded flex justify-center items-center text-white`}
          >
            Print
          </button>
          <button
            onClick={handleClosePrintSelection}
            className={`border dark:border-gray-400 bg-red-600 hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-800 shadow-md dark:shadow-slate-600 h-[35px] p-2 rounded flex justify-center items-center text-white`}
          >
            Close
          </button>
        </div>
      </div>
      <div className="flex gap-4 md:gap-2 flex-wrap justify-between p-2 py-6 dark:bg-bgDarkColor transition-colors duration-500">
        <div className="relative shadow-md">
          <label
            htmlFor="printerQueue"
            className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 left-1`}
          >
            Printer Queue
          </label>
          <select
            required
            onChange={(e) => setQueueId(Number(e.target.value))}
            className="bg-white border border-gray-300 text-gray-900 dark:text-gray-200 dark:bg-bgDarkColor  text-sm rounded block h-[41px] w-[150px] px-2 py-1 disabled:bg-gray-100 transition duration-200 ease-in-out"
          >
            <option value="">Any</option>
            {inputValues?.printQueues.map((queue) => (
              <option key={queue.id} value={queue.id}>
                {queue.name}
              </option>
            ))}
          </select>
        </div>
        <div className="relative shadow-md">
          <label
            htmlFor="template"
            className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 left-1`}
          >
            Template
          </label>
          <select
            required
            onChange={(e) => setTemplateId(Number(e.target.value))}
            className="bg-white border border-gray-300 text-gray-900 dark:text-gray-200 dark:bg-bgDarkColor  text-sm rounded block h-[41px] w-[150px] px-2 py-1 disabled:bg-gray-100 transition duration-200 ease-in-out"
          >
            <option value={0}>Any</option>
            {inputValues?.itemPrintTemplates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
        </div>
        <div className="relative shadow-md">
          <label
            htmlFor="priceList"
            className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 left-1`}
          >
            Pricelist
          </label>
          <select
            required
            onChange={(e) => setPriceListId(e.target.value)}
            className="bg-white border border-gray-300 text-gray-900 dark:text-gray-200 dark:bg-bgDarkColor  text-sm rounded block h-[41px] w-[150px] px-2 py-1 disabled:bg-gray-100 transition duration-200 ease-in-out"
          >
            <option value="">Any</option>
            {inputValues?.pricelists.map((price) => (
              <option key={price.id} value={price.id}>
                {price.name}
              </option>
            ))}
          </select>
        </div>
        <div className="relative shadow-md">
          <label
            htmlFor="uom"
            className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 left-1`}
          >
            UOM
          </label>
          <select
            required
            onChange={(e) => setUomId(Number(e.target.value))}
            className="bg-white border border-gray-300 text-gray-900 dark:text-gray-200 dark:bg-bgDarkColor  text-sm rounded block h-[41px] w-[150px] px-2 py-1 disabled:bg-gray-100 transition duration-200 ease-in-out"
          >
            <option defaultChecked>Item default</option>
            {inputValues?.itemUoms.map((uom) => (
              <option key={uom.id} value={uom.id}>
                {uom.name}
              </option>
            ))}
          </select>
        </div>
        <div className="relative shadow-md">
          <label
            htmlFor="freeText"
            className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 left-1`}
          >
            Free text
          </label>
          <input
            type="text"
            onChange={(e) => setFreeText(e.target.value)}
            className="border h-[41px] w-[200px] pl-2 rounded border-gray-300 dark:text-gray-200 dark:bg-bgDarkColor transition-colors duration-500"
          />
        </div>
      </div>
      <div className="bg-white dark:bg-bgDarkColor pb-2">
        <div className="mb-2 mx-2 border border-gray-300 dark:border-gray-500 rounded shadow">
          <PrintLabelTable data={printItem} sendItemLabel={sendItemLabel} />
          {/*      
          <div className="w-full p-2 py-1.5 flex justify-end items-center gap-1 rounded-sm">
            <div className="relative shadow-md">
              <select
                required
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded block h-[30px] w-[150px] px-2 disabled:bg-gray-100 transition duration-200 ease-in-out"
              >
                <option value="">Any</option>
              </select>
            </div>
            <div className="flex shadow-md">
              <button
                onClick={() => {
                  data[0].label = 0;
                  setLabels(0);
                }}
                className="bg-white hover:bg-gray-100 text-sm rounded-l p-1 border border-gray-300"
              >
                Set to 0
              </button>
              <button
                onClick={() => {
                  data[0].label = 1;
                  setLabels(1);
                }}
                className="bg-white hover:bg-gray-100 text-sm p-1 border-y border-gray-300"
              >
                Set to 1
              </button>
              <button
                onClick={() => {
                  data[0].label = 1;
                  setLabels(1);
                }}
                className="bg-white hover:bg-gray-100 text-sm rounded-r p-1 border border-gray-300"
              >
                Reset
              </button>
            </div>
          </div> 
          */}
        </div>
      </div>
    </div>
  );
};
