import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { TbFilterOff, TbFilterCheck } from "react-icons/tb";
import { CustomSelectedValuesProps } from "../../utils/type";

export const CustomSelectedValue: FC<CustomSelectedValuesProps> = ({
  data,
  handleInputChange,
  setFormValues,
  tag,
  formValues,
  itemType,
  tagType,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const updatedTag = formValues[itemType][tagType].includes(data.value)
      ? formValues[itemType][tagType].filter(
          (tagValue: number) => tagValue !== data.value
        )
      : [...formValues[itemType][tagType], data.value];
    const updatedTagExclude = formValues[itemType][
      `${tagType}Exclude`
    ].includes(data.value)
      ? formValues[itemType][`${tagType}Exclude`].filter(
          (tagValue: number) => tagValue !== data.value
        )
      : [...formValues[itemType][`${tagType}Exclude`], data.value];

    handleInputChange(
      setFormValues,
      navigate,
      tagType,
      itemType,
      updatedTag,
      true
    );
    handleInputChange(
      setFormValues,
      navigate,
      `${tagType}Exclude`,
      itemType,
      updatedTagExclude,
      true
    );
  };

  return (
    <div className={`flex items-center gap-1 px-1 z-10`}>
      <button
        style={{ pointerEvents: "auto" }}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleClick();
        }}
      >
        {!tag.includes(data.value) ? (
          <TbFilterOff color="red" />
        ) : (
          <TbFilterCheck color="green" />
        )}
      </button>
      <p className="line-clamp-1 mb-0 text-sm py-0.5">{data.label}</p>
    </div>
  );
};
