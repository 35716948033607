import { FC } from "react";
import { useTable, useFlexLayout } from "react-table";
import { TurnoverTableType } from "../../../utils/type";
import { turnoverColumns } from "../../../utils/columns";

export const TurnoverTable: FC<{
  data: TurnoverTableType[];
}> = ({ data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: turnoverColumns, data }, useFlexLayout);

  return (
    <div className="w-full max-w-[720px] overflow-x-auto">
      <table
        {...getTableProps()}
        className="w-full divide-y border-b-0 divide-gray-200 dark:divide-gray-500 dark:border-gray-500 table-auto"
      >
        <thead className="bg-gray-50 dark:bg-gray-700  ">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  className={`${
                    columnIndex !== headerGroup.headers.length - 1 && "border-r"
                  } shadow relative p-2 text-left text-xs font-medium text-gray-500 border-b border-gray-300 flex dark:text-gray-300 dark:border-gray-500`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200 dark:divide-gray-500 dark:bg-gray-900"
        >
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr
                className="bg-white dark:bg-bgDarkColor divide-gray-200 dark:divide-gray-500"
                {...row.getRowProps()}
              >
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`text-left text-xs p-1 text-gray-500 border-gray-300 dark:text-gray-100 dark:border-gray-500 ${
                        cellIndex !== row.cells.length - 1 && "border-r"
                      }`}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
