import dayjs from "dayjs";
import { IoMenu } from "react-icons/io5";
import { FaXmark } from "react-icons/fa6";
import { FC, useState, useEffect } from "react";
import { DynamicSelection } from "./DynamicSelection";
import type { DateRangeProps } from "../../utils/type";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { dateRangeShortcuts } from "../../utils/functions";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import Datepicker, { DateType } from "react-tailwindcss-datepicker";
import { DateValueType, Configs } from "react-tailwindcss-datepicker";
import { useInputValuesStore } from "../../utils/store/inputValuesStore";
import { Popover, Position, PopoverInteractionKind } from "@blueprintjs/core";

export const DateRange: FC<DateRangeProps> = ({
  setFormValues,
  formValues,
  name,
  title,
  help,
}) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const [value, setValue] = useState<DateValueType>({
    startDate: formValues[name].start ? formValues[name].start : null,
    endDate: formValues[name].end ? formValues[name].end : null,
  });
  const { inputValues } = useInputValuesStore();
  const [loading, setLoading] = useState<boolean>(true);

  const [shortCutsUpdated, setShortCutsUpdated] = useState<Configs>();

  useEffect(() => {
    if (inputValues?.dateRanges?.length) {
      for (let i = 0; i < inputValues?.dateRanges.length; i++) {
        let { name, starts, ends } = inputValues.dateRanges[i];

        //@ts-ignore
        dateRangeShortcuts.shortcuts[name] = {
          text: name,
          period: {
            start: dayjs(new Date(starts.split("T")[0])),
            end: dayjs(new Date(ends.split("T")[0])),
          },
        };
      }
      setShortCutsUpdated(dateRangeShortcuts);
      setLoading(false);
    }
  }, [inputValues]);

  const handleRemove = () => {
    setFormValues({
      ...formValues,
      [name]: {
        dynamicEndingDate: "",
        dynamicStartingDate: "",
        start: "",
        end: "",
      },
    });
    setValue({ startDate: null, endDate: null });
    params.delete(`${name}.start`);
    params.delete(`${name}.end`);
    window.history.replaceState(null, "", `${url.pathname}?${params}`);
  };

  const updateUrlParams = (
    startDate: DateType | undefined,
    endDate: DateType | undefined
  ) => {
    if (startDate) {
      params.set(`${name}.start`, startDate.toString());
    } else {
      params.delete(`${name}.start`);
    }

    if (endDate) {
      params.set(`${name}.end`, endDate.toString());
    } else {
      params.delete(`${name}.end`);
    }

    window.history.replaceState(null, "", `${url.pathname}?${params}`);
  };

  const handleDataChange = (date: DateValueType) => {
    setFormValues({
      ...formValues,
      [name]: {
        ...formValues[name],
        dynamicEndingDate: "",
        dynamicStartingDate: "",
        start: date?.startDate ? date?.startDate : "",
        end: date?.endDate ? date?.endDate : "",
      },
    });
    updateUrlParams(date?.startDate, date?.endDate);

    setValue(date);
  };

  const getPlaceholderText = () => {
    const formatDate = (dateStr: string) =>
      dateStr.charAt(0).toUpperCase() + dateStr.slice(1).split("-").join(" ");

    const { dynamicStartingDate, dynamicEndingDate } = formValues[name];

    if (dynamicStartingDate && dynamicEndingDate) {
      const startingDateText = formatDate(dynamicStartingDate);
      const endingDateText = formatDate(dynamicEndingDate);
      const maxLength = 30;
      const strLength = startingDateText.length + endingDateText.length;

      if (strLength <= maxLength) {
        return `${startingDateText} ~ ${endingDateText}`;
      }

      const lengthToRemove = strLength - maxLength;
      const truncate = (text: string) =>
        text.slice(0, text.length - Math.ceil(lengthToRemove / 2));

      return `${truncate(startingDateText)}... ~ ${truncate(
        endingDateText
      )}...`;
    } else {
      return "dd/mm/yy ~ dd/mm/yy";
    }
  };

  return (
    <div className="relative z-[10] flex border-[1px] border-[#cccccc] rounded-[4px] w-[240px] h-[38px] shadow-md">
      {help && (
        <div className="absolute right-0 -top-3.5">
          <span data-tooltip-id="my-tooltip" data-tooltip-content={help}>
            <HiOutlineQuestionMarkCircle color="gray" size={14} />
          </span>
          <ReactTooltip id="my-tooltip" className="text-xs" />
        </div>
      )}
      <label
        htmlFor="departmentExclude"
        className={`absolute text-sm text-[#427dab] dark:text-blue-200 -translate-y-[19px] translate-x-[5px] z-[9] origin-[0] bg-white dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 top-2 left-1`}
      >
        {title}
      </label>
      <div className="relative pt-2.5">
        <div className="">
          {formValues[name].dynamicStartingDate ? (
            <button
              onClick={handleRemove}
              className="w-fit text-red-500 hover:text-red-600 text-sm pl-2 pr-0.5"
            >
              <FaXmark size={20} />
            </button>
          ) : (
            <Popover
              content={
                <DynamicSelection
                  title={name}
                  updateUrlParams={updateUrlParams}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  setValue={setValue}
                />
              }
              position={Position.TOP}
              hoverOpenDelay={100}
              interactionKind={PopoverInteractionKind.CLICK}
            >
              <button className="w-fit text-blue-600 hover:text-blue-700 text-sm px-2">
                <IoMenu size={20} />
              </button>
            </Popover>
          )}
        </div>
      </div>
      <div id="data-picker" className="w-full">
        {!loading && (
          <Datepicker
            displayFormat={"DD/MM/YY"}
            value={value}
            showShortcuts={true}
            useRange={false}
            configs={shortCutsUpdated}
            placeholder={getPlaceholderText()}
            toggleClassName="absolute right-0 h-full text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed pr-1 pt-2"
            inputClassName="w-full rounded-md focus:ring-0 font-normal text-gray-700 dark:text-gray-200 pt-2.5 text-sm placeholder:text-gray-700 dark:placeholder:text-gray-200 dark:bg-bgDarkColor transition-colors duration-500"
            onChange={handleDataChange}
          />
        )}
      </div>
    </div>
  );
};
