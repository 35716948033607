import {
  InventoryService,
  ItemInventory3dSummary,
  ItemInventoryWithWarehouse,
  ItemQuickViewResultsListResults,
  PricelistEntryPerItemUnpagedListResults,
} from "../../api-client";
import {
  shouldIncludeColumn,
  as2decimals,
  itemTypeOption,
  handleError,
} from "../../utils/functions";
import { FC, useState } from "react";
import ReactLoading from "react-loading";
import { BinTable } from "./tables/BinTable";
import { BsGraphUpArrow } from "react-icons/bs";
import { SummaryTable } from "./tables/SummaryTable";
import { TurnoverTable } from "./tables/TurnoverTable";
import { inventoryColumns } from "../../utils/columns";
import { turnoverTableData } from "../../utils/mockData";
import { useUserInfoStore } from "../../utils/store/userStore";
import { TurnoverTableType, InventoryProps } from "../../utils/type";
import { InventoryHistoryTable } from "./tables/InventoryHistoryTable";
import { FaCubes, FaEye, FaPrint, FaExternalLinkAlt } from "react-icons/fa";

export const Inventory: FC<InventoryProps> = ({
  sku,
  getInventoryLog,
  inventorySummary,
  setInventorySummary,
}) => {
  const { userInfo } = useUserInfoStore();

  const [loading, setLoading] = useState<boolean>(false);

  const [inventoryPricing, setInventoryPricing] =
    useState<PricelistEntryPerItemUnpagedListResults>();
  const [inventoryHistory, setInventoryHistory] = useState<
    ItemQuickViewResultsListResults | undefined
  >();

  const [inventoryType, setInventoryType] = useState<string>("SUMMARY");
  const [binData, setBinData] = useState<
    ItemInventory3dSummary[] | null | undefined
  >();
  const [turnoverData, setTurnoverData] = useState<TurnoverTableType[]>();

  const getInventorySummary = async (uomId: number): Promise<void> => {
    try {
      setLoading(true);
      setInventoryType("SUMMARY");

      const response = await InventoryService.getInventoryItemItemskuInventory({
        path: { itemSku: sku! },
        query: { uomId: uomId },
      });

      if (response.error) {
        const { error, errors } = response.error;
        if (error || errors) {
          handleError(response.error);
          return;
        }
      }

      setInventorySummary({
        ...inventorySummary,
        selectedUom: uomId,
        data: response.data?.data,
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const getItemPricing = async (): Promise<void> => {
    try {
      setLoading(true);

      const response = await InventoryService.getInventoryItemItemskuPricing({
        path: { itemSku: sku! },
      });

      if (response.error) {
        const { error, errors } = response.error;
        if (error || errors) {
          handleError(response.error);
          return;
        }
      }

      setInventoryPricing(response.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const getItemBin = async (): Promise<void> => {
    try {
      setLoading(true);
      setInventoryType("BIN");

      const response =
        await InventoryService.getInventoryItemItemskuInventory3D({
          path: { itemSku: sku! },
        });

      if (response.error) {
        const { error, errors } = response.error;
        if (error || errors) {
          handleError(response.error);
          return;
        }
      }

      setBinData(response.data?.results);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const getItemTurnover = async (): Promise<void> => {
    try {
      setLoading(true);
      setInventoryType("TURNOVER");

      /*      const itemHistoryCall = await axios.get(
        `${process.env.REACT_APP_API_URL}/inventory/item/${sku}/quick-view/${type}?limit=5&offset=${offset}`
      ); */

      setTurnoverData(turnoverTableData);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const getItemHistory = async (
    type: "SALES" | "PURCHASES",
    offset: number = 0
  ): Promise<void> => {
    try {
      setLoading(true);
      setInventoryType(type);

      const response =
        await InventoryService.getInventoryItemItemskuQuickViewQvtype({
          path: { itemSku: sku!, qvType: type },
          query: { limit: 5, offset: offset },
        });

      if (response.error) {
        const { error, errors } = response.error;
        if (error || errors) {
          handleError(response.error);
          return;
        }
      }

      setInventoryHistory(response.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const filteredColumns = inventoryColumns.filter((column) => {
    const accessor = column.accessor! as keyof ItemInventoryWithWarehouse;
    if (accessor === undefined) return false;

    if (
      ["uninvoiced", "backToBack", "blocked", "inTransit"].includes(accessor) &&
      inventorySummary?.data?.summary
    ) {
      return shouldIncludeColumn(
        [
          ...inventorySummary?.data?.summary,
          ...inventorySummary?.data?.inventories!,
        ],
        accessor
      );
    }
    return true;
  });

  return (
    <div className="w-[160px] xs:w-[220px] xs2:w-[260px] md:w-[380px] lg:w-[640px] xl:w-fit overflow-x-auto">
      {inventorySummary?.basicInfo ? (
        <div>
          <div className="w-full p-2 bg-mainColor rounded-t-md shadow dark:bg-mainColorDarkMode flex flex-col md:flex-row justify-between gap-4">
            <div className="flex gap-1">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${userInfo?.organization?.appUrl}wapp/en-gb/inventory/products/details/${inventorySummary?.basicInfo?.id}`}
                className="bg-blue-400 hover:bg-blue-500 dark:bg-blue-800 dark:hover:bg-blue-950 border border-blue-300 hover:text-white h-[35px] text-white text-[17px] rounded-md p-1 flex items-center gap-1.5"
              >
                {inventorySummary?.basicInfo?.sku}
                <FaExternalLinkAlt size={15} />
              </a>
              <span data-tooltip-id="my-tooltip" data-tooltip-content="Print">
                <button
                  onClick={() => {
                    /*      setShowPrint(true);
                    setPrintItemSku([
                      inventorySummary?.basicInfo?.sku,
                    ] as string[]); */
                  }}
                  className="border rounded-md bg-blue-400 hover:bg-blue-500 dark:bg-blue-800 dark:hover:bg-blue-950 border-blue-300 h-[35px] w-[35px] flex justify-center items-center"
                >
                  <FaPrint color="white" size={20} />
                </button>
              </span>
            </div>
            <div className="flex items-center">
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Show Inventory"
              >
                <button
                  onClick={() =>
                    getInventorySummary(inventorySummary.selectedUom as number)
                  }
                  className={`border rounded-l-md ${
                    inventoryType === "SUMMARY"
                      ? "bg-blue-500 dark:bg-blue-950"
                      : "bg-blue-400 dark:bg-blue-800"
                  } hover:bg-blue-500 dark:hover:bg-blue-950 border-r-0 border-blue-300 h-[35px] w-[35px] flex justify-center items-center`}
                >
                  <FaEye color="white" size={20} />
                </button>
              </span>
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Turnover Overview"
              >
                <button
                  onClick={getItemTurnover}
                  className={`border ${
                    inventoryType === "TURNOVER"
                      ? "bg-blue-500 dark:bg-blue-950"
                      : "bg-blue-400 dark:bg-blue-800"
                  } hover:bg-blue-500 dark:hover:bg-blue-950 border-r-0 border-blue-300 h-[35px] w-[35px] flex justify-center items-center`}
                >
                  <BsGraphUpArrow color="white" size={20} />
                </button>
              </span>
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Show Purchase"
              >
                <button
                  onClick={() => getItemHistory("PURCHASES")}
                  className={`border ${
                    inventoryType === "PURCHASES"
                      ? "bg-blue-500 dark:bg-blue-950"
                      : "bg-blue-400 dark:bg-blue-800"
                  } hover:bg-blue-500 dark:hover:bg-blue-950 border-r-0 border-blue-300 h-[35px] w-[35px] text-lg flex justify-center items-center text-white`}
                >
                  P
                </button>
              </span>
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Show Sales"
              >
                <button
                  onClick={() => getItemHistory("SALES")}
                  className={`border ${
                    inventoryType === "SALES"
                      ? "bg-blue-500 dark:bg-blue-950"
                      : "bg-blue-400 dark:bg-blue-800"
                  } hover:bg-blue-500 dark:hover:bg-blue-950 border-r-0 border-blue-300 h-[35px] w-[35px] text-lg flex justify-center items-center text-white`}
                >
                  S
                </button>
              </span>
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Batches / BINs"
              >
                <button
                  onClick={getItemBin}
                  className={`${
                    inventoryType === "BIN"
                      ? "bg-blue-500 dark:bg-blue-950"
                      : "bg-blue-400 dark:bg-blue-800"
                  }
                    p-1.5 border bg-blue-400 hover:bg-blue-500 dark:bg-blue-800 dark:hover:bg-blue-950 rounded-r-md border-blue-300 h-[35px] w-[35px] flex justify-center items-center`}
                >
                  <FaCubes color="white" size={20} />
                </button>
              </span>
            </div>
          </div>
          <div className="p-4">
            <div className="flex flex-col sm:flex-row gap-2 mb-3">
              {inventorySummary?.basicInfo.imageUrl ? (
                <img
                  className=" w-24 h-24 rounded shadow  "
                  src={inventorySummary?.basicInfo.imageUrl}
                  alt="product"
                />
              ) : (
                <div className="w-24 h-24 rounded shadow"></div>
              )}
              <div className="flex flex-col justify-between w-full">
                <div>
                  <h3 className="font-semibold text-gray-800 dark:text-gray-200 text-lg">
                    {inventorySummary?.basicInfo.title}
                  </h3>
                  <p className="text-xs text-gray-700 dark:text-gray-300 -mt-0.5">
                    {itemTypeOption[inventorySummary?.basicInfo.type!]}
                  </p>
                </div>
                <div className="w-full flex flex-col md:flex-row gap-4 mt-4 items-end justify-between">
                  <select
                    value={inventorySummary?.selectedUom}
                    onChange={(e) =>
                      getInventorySummary(Number(e.target.value))
                    }
                    className="border p-1 px-2 focus:ring-0 focus:outline-none text-sm rounded-md border-gray-300 dark:border-gray-500"
                  >
                    {inventorySummary?.itemUom &&
                      inventorySummary?.itemUom.map((uom) => (
                        <option
                          key={uom.id}
                          value={uom.id}
                          className={`text-sm ${
                            inventorySummary?.selectedUom === uom.id
                              ? "bg-blue-500 text-white"
                              : "bg-white dark:bg-gray-800 text-gray-700"
                          }`}
                        >
                          {uom.name}
                        </option>
                      ))}
                  </select>
                  <div className="flex flex-wrap gap-4 text-xs dark:text-gray-100">
                    {inventorySummary?.itemUom &&
                      inventorySummary?.selectedUom !== undefined &&
                      (() => {
                        const selectedUomItem = inventorySummary?.itemUom.find(
                          (uom) => uom.id === inventorySummary?.selectedUom
                        );
                        const caseQuantity = inventorySummary?.itemUom.find(
                          (uom) =>
                            uom.id === inventorySummary?.basicInfo?.defaultUomId
                        )?.shelfQty;

                        return (
                          <>
                            {selectedUomItem?.shelfQty && (
                              <div className="flex flex-col items-start justify-center">
                                <p className="text-gray-700 dark:text-gray-100 mb-0">
                                  <span className="font-semibold text-gray-900 dark:text-gray-300">
                                    Shelf quantity:{" "}
                                  </span>
                                  {as2decimals(selectedUomItem?.shelfQty)}
                                </p>
                              </div>
                            )}
                            {selectedUomItem?.shelfQty && caseQuantity && (
                              <div className="flex flex-col items-start justify-center">
                                <p className="text-gray-700 dark:text-gray-100 mb-0">
                                  <span className="font-semibold text-gray-900 dark:text-gray-300">
                                    Case
                                    {selectedUomItem.shelfQty / caseQuantity >
                                      1 && "s"}{" "}
                                    :
                                  </span>
                                  {as2decimals(
                                    selectedUomItem.shelfQty / caseQuantity
                                  )}
                                </p>
                              </div>
                            )}
                            {selectedUomItem?.weightGross
                              ? selectedUomItem?.weightGross > 0 && (
                                  <div className="flex flex-col items-start justify-center">
                                    <p className="text-gray-700 dark:text-gray-100 mb-0">
                                      <span className="font-semibold text-gray-900 dark:text-gray-300">
                                        Weight:{" "}
                                      </span>
                                      {as2decimals(
                                        selectedUomItem?.weightGross
                                      ).toLocaleString()}
                                      {selectedUomItem?.weightUom}
                                    </p>
                                  </div>
                                )
                              : null}
                            {selectedUomItem?.volume && (
                              <div className="flex flex-col items-start justify-center">
                                <p className="text-gray-700 dark:text-gray-100 mb-0">
                                  <span className="font-semibold text-gray-900 dark:text-gray-300">
                                    Volume:{" "}
                                  </span>
                                  {selectedUomItem.volume}
                                </p>
                              </div>
                            )}
                          </>
                        );
                      })()}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 pr-[0.5px] rounded">
              {loading && (
                <div className="h-[190px] w-[710px] flex items-center justify-center">
                  <ReactLoading
                    type="spinningBubbles"
                    height={"200px"}
                    width={"200px"}
                    color="#4273B8"
                  />
                </div>
              )}
              {inventorySummary.data &&
                inventoryType === "SUMMARY" &&
                !loading && (
                  <SummaryTable
                    sku={inventorySummary.basicInfo.sku!}
                    data={[
                      ...inventorySummary.data.inventories!,
                      ...inventorySummary.data.summary!,
                    ]}
                    columns={filteredColumns}
                    locale={userInfo?.locale}
                    getInventoryLog={getInventoryLog}
                  />
                )}
              {inventoryType === "BIN" && binData && !loading && (
                <div className="border rounded shadow">
                  <BinTable data={binData} itemSku={sku} />
                </div>
              )}
              {inventoryType === "TURNOVER" && turnoverData && !loading && (
                <div className="border rounded">
                  <TurnoverTable data={turnoverData} />
                  <div className="border-t flex justify-between p-2">
                    <button className="bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 shadow text-white rounded-md cursor-pointer disabled:bg-gray-400 px-2 text-xs p-1.5">
                      2023
                    </button>
                    <button className="bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 shadow text-white rounded-md cursor-pointer disabled:bg-gray-400 px-2 text-xs p-1.5">
                      2025
                    </button>
                  </div>
                </div>
              )}
              {(inventoryType === "PURCHASES" || inventoryType === "SALES") &&
                inventoryHistory &&
                !loading && (
                  <InventoryHistoryTable
                    data={inventoryHistory}
                    getItemHistory={getItemHistory}
                  />
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className="p-2">
          <ReactLoading
            type="spinningBubbles"
            height="100px"
            width="100px"
            color="#4273B8"
          />
        </div>
      )}
    </div>
  );
};
