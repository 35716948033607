import { create } from "zustand";

export interface ShowPrintInterface {
  showPrint: boolean;
  setShowPrint: (value: boolean) => void;
  setPrintItemSku: (value: string | undefined) => void;
  printItemSku: string | undefined;
}

export const useShowPrintStore = create<ShowPrintInterface>((set) => ({
  showPrint: false,
  setShowPrint: (value: boolean) => set({ showPrint: value }),
  setPrintItemSku: (value: string | undefined) => set({ printItemSku: value }),
  printItemSku: undefined,
}));
