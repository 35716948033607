import { FC } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import { as3decimals, as2decimals } from "../../utils/functions";
import { CellContentSummaryProps } from "../../utils/type";

import { BsInfoCircle } from "react-icons/bs";

export const CellContentSummary: FC<CellContentSummaryProps> = ({
  cell,
  row,
  getDrillDown,
  getInventoryLog,
}) => {
  if (cell.column.id === "available") {
    return (
      <div className="flex items-center h-full">
        <p className={`${cell.value > 0 ? "text-green-500" : "text-red-500"}`}>
          {as2decimals(cell.value)}
        </p>
      </div>
    );
  } else if (cell.column.id === "Warehouse") {
    return (
      <div className="flex items-center h-full dark:text-gray-100">
        {cell.render("Cell")}
      </div>
    );
  } else if (cell.column.id === "Cost") {
    const costParts = cell.value.split(" | ");
    const valueParts = costParts[0].split("(");

    return (
      <div className="flex items-center h-full max-w-[200px]">
        <div>
          <div className="flex items-center gap-1">
            <p>{valueParts[0]}</p>{" "}
            {(costParts[1] !== " " || costParts[2] !== "") && (
              <span
                data-tooltip-id="cost"
                data-tooltip-html={`${costParts[1]}${
                  costParts[1] !== " " ? "<br />" : ""
                }${costParts[2]}`}
              >
                <p className="dark:text-gray-100">
                  <BsInfoCircle />
                </p>
              </span>
            )}
          </div>
          <p>{valueParts[1] && `(${valueParts[1]}`}</p>
        </div>
      </div>
      /*
          <div className="flex items-center h-full dark:text-gray-100 max-w-[170px]">
        <div>
          <p>{costParts[0]}</p>
          {costParts[1] !== " " && (
            <p className="bg-blue-500 text-white p-0.5 rounded w-fit mt-0.5 text-[11px]">
              {costParts[1]}
            </p>
          )}
          {costParts[2] !== "" && (
            <p className="bg-[#14C38E] text-white p-0.5 rounded w-fit mt-0.5 text-[11px]">
              {costParts[2]}
            </p>
          )}
        </div>
      </div>*/
    );
  } else if (row.original.warehouse?.id) {
    if (cell.column.id === "onHand") {
      return (
        <div className="flex justify-between items-center text-blue-600 dark:text-blue-500 text-left text-xs h-full">
          <button
            onClick={() => getInventoryLog(row.original.warehouse?.id)}
            className="mb-0"
          >
            {as3decimals(cell.value)}
          </button>
          <button
            onClick={() => getDrillDown(row.original.warehouse?.id, "aging")}
          >
            <FaRegCalendarAlt />
          </button>
        </div>
      );
    } else {
      return (
        <div className="flex items-center h-full">
          <button
            className="text-blue-600 dark:text-blue-500 text-left text-xs"
            onClick={() =>
              getDrillDown(row.original.warehouse?.id, cell.column.id)
            }
          >
            {as2decimals(cell.value)}
          </button>{" "}
        </div>
      );
    }
  } else {
    return <p> {as2decimals(cell.value)}</p>;
  }
};

export const getCellClassName = (
  rowIndex: number,
  firstNullIndex: number,
  isLastRow: boolean,
  isLastColumn: boolean,
  isFirstColumn: boolean
) => {
  let classNames =
    "text-left text-xs p-2 py-1 text-gray-500 border-b dark:border-gray-600 dark:text-gray-100";

  if (rowIndex === firstNullIndex) {
    classNames += " border-t-[1px] border-t-blue-500";
  } else if (rowIndex === firstNullIndex - 1) {
    classNames += " border-b-0";
  } else if (!isLastRow && rowIndex !== firstNullIndex) {
  } else {
    classNames += " border-b border-gray-200";
  }

  if (isLastRow || isLastColumn) {
    classNames += " !border-r-none";
  } else {
    classNames += " border-r";
  }
  if (isFirstColumn) {
    classNames += " !pl-0.5 !pr-1 py-0";
  }

  return classNames;
};
