import { useState, FC } from "react";
import { DashboardForm } from "./DashboardForm";
import { LuSettings } from "react-icons/lu/index.js";
import type { ModifyDashboardProps } from "../../utils/type";

export const EditDashboard: FC<ModifyDashboardProps> = ({
  dashboard,
  getDashboards,
}) => {
  const [showSettings, setShowSettings] = useState<boolean>(false);

  return (
    <>
      {showSettings && (
        <DashboardForm
          dashboard={dashboard}
          closeModal={() => setShowSettings(false)}
          getDashboards={getDashboards}
        />
      )}
      <div className="flex justify-center dark:text-gray-200">
        <LuSettings
          className="cursor-pointer"
          size={20}
          onClick={() => setShowSettings(true)}
        />
      </div>
    </>
  );
};
