import axios from "axios";
import { useState, useEffect } from "react";
import { Items } from "../reportInputs/Items";
import { Sales } from "../reportInputs/Sales";
import { handleError } from "../../utils/functions";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { ApiUser, ReportsService, SettingsService } from "../../api-client";

export const ReportServer = () => {
  const [listAPIUser, setListAPIUser] = useState<ApiUser[] | undefined>();
  const { darkMode } = useDarkModeStore();

  const getApiUser = async () => {
    try {
      const response = await SettingsService.getSettingsApiUsers();

      if (response.error) {
        handleError(response.error);
      }

      setListAPIUser(
        response.data?.results?.filter((user) => user.scopes?.reports)
      );
    } catch (error: any) {
      console.log(error);
      handleError(error.message);
      return;
    }
  };

  useEffect(() => {
    getApiUser();
  }, []);

  return (
    <div>
      <Items listAPIUser={listAPIUser} />
      <Sales listAPIUser={listAPIUser} />
    </div>
  );
};
