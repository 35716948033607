import ReactLoading from "react-loading";
import { useState, useEffect } from "react";
import { handleError } from "../../utils/functions";
import { ScheduleTable } from "../tables/ScheduleTable";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { ReportScheduleInfo, ReportsService } from "../../api-client";

export const Schedules = () => {
  const [schedules, setSchedules] = useState<
    ReportScheduleInfo[] | null | undefined
  >(null);
  const { darkMode } = useDarkModeStore();

  const getSchedules = async () => {
    try {
      const response = await ReportsService.getReportsSchedules();

      if (response.error) {
        handleError(response.error);
      }

      setSchedules(response.data?.results);
    } catch (error: any) {
      handleError(error.message);
    }
  };

  useEffect(() => {
    getSchedules();
  }, []);

  return (
    <div className="flex flex-wrap gap-4 shadow-md dark:shadow-slate-600 rounded-md">
      <div className="rounded-md  transition-colors duration-500 bg-white dark:bg-bgDarkColor w-full">
        <div className="bg-mainColor dark:bg-mainColorDarkMode transition-colors duration-500 rounded-t-md py-2 border-mainColor dark:border-mainColorDarkMode">
          <h1 className="text-[25px] pl-3 text-gray-100">Schedules</h1>
        </div>
        <div className="pb-1 rounded-b-md">
          {schedules ? (
            schedules.length > 0 ? (
              <ScheduleTable data={schedules} getSchedules={getSchedules} />
            ) : (
              <div className="w-full my-10 flex justify-center">
                <p className="text-2xl text-gray-600">
                  No schedules have been created yet.
                </p>
              </div>
            )
          ) : (
            <div className="w-full h-[50vh] flex justify-center dark:bg-bgDarkColor">
              <ReactLoading
                type="spinningBubbles"
                height={"200px"}
                width={"200px"}
                color="#4273B8"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
