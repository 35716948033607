import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import axios from "axios";
import ReactLoading from "react-loading";
import { useState, useEffect, FC } from "react";
import { handleError } from "../../utils/functions";
import { OrderDrilldownProps } from "../../utils/type";
import { OrderDrilldownTable } from "./tables/OrderDrilldownTable";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { SalesReportDrillDownQtyListResults } from "../../api-client";

export const OrderDrilldown: FC<OrderDrilldownProps> = ({
  itemSku,
  period,
  parametersString,
}) => {
  const [drilldown, setDrilldown] =
    useState<SalesReportDrillDownQtyListResults>();
  const [loading, setLoading] = useState<boolean>(false);
  const { darkMode } = useDarkModeStore();

  const getDrilldown = async (offset: number = 0): Promise<void> => {
    try {
      setLoading(true);
      if (period) {
      }
      const stringQuery = `startDate=${period[0].start}&endDate=${period[0].end}&`;

      const drilldownCall = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/reports/sales-drill-down/${itemSku}?offset=${offset}&limit=10&${
          period ? stringQuery : ""
        }${parametersString}`
      );

      setDrilldown(drilldownCall.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  useEffect(() => {
    getDrilldown();
  }, []);

  /* 	
  state	
  resends	
  status	
 */

  return (
    <div>
      {loading ? (
        <div className="p-2 flex justify-center items-center">
          <ReactLoading
            type="spinningBubbles"
            height="100px"
            width="100px"
            color="#4273B8"
          />
        </div>
      ) : drilldown ? (
        <div className="border border-gray-300 dark:border-gray-500">
          {drilldown.results && (
            <OrderDrilldownTable drilldown={drilldown.results} />
          )}
          {drilldown.results && drilldown.results.length > 0 ? (
            <div className="flex items-center justify-center gap-3 text-sm my-1">
              <button
                onClick={() => getDrilldown((drilldown.offset || 0) - 10)}
                className="disabled:opacity-30"
                disabled={drilldown.offset === 0}
              >
                <MdOutlineKeyboardDoubleArrowLeft size={20} />
              </button>
              <span>{(drilldown.offset || 0) / 10 + 1}</span>
              <button
                disabled={
                  (drilldown.totalCount || 0) <= (drilldown.offset || 0) + 10
                }
                className="disabled:opacity-30"
                onClick={() => getDrilldown((drilldown.offset || 0) + 10)}
              >
                <MdOutlineKeyboardDoubleArrowRight size={20} />
              </button>
            </div>
          ) : (
            <div className="w-full flex justify-center my-2">
              <p className="text-gray-600 dark:text-gray-300 text-sm">
                No data available
              </p>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
