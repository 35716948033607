import { useMemo, FC } from "react";
import { FaPrint } from "react-icons/fa";
import { printLabelColumn } from "../../utils/columns";
import { useTable, useFlexLayout, Column, Row } from "react-table";
import { PrintItemType, PrintLabelTableProps } from "../../utils/type";

export const PrintLabelTable: FC<PrintLabelTableProps> = ({
  data,
  sendItemLabel,
}) => {
  const columns: Column<PrintItemType>[] = useMemo(
    () => [
      ...printLabelColumn,
      ...(data.some((item) => item.batchMode === "BEST_BEFORE_DATE")
        ? [
            {
              Header: "Best Before Date",
              width: 90,
              Cell: ({ row }: { row: Row<PrintItemType> }) => (
                <div>
                  {row.original.batchMode === "BEST_BEFORE_DATE" && (
                    <input
                      type="text"
                      placeholder="Expiry date"
                      defaultValue={row.original.batch?.bestBeforeDate}
                      onChange={(e) => {
                        if (!row.original.batch) {
                          row.original.batch = {
                            name: "",
                            productionDate: "",
                            bestBeforeDate: "",
                            fields: undefined,
                          };
                        }

                        row.original.batch.bestBeforeDate = e.target.value;
                      }}
                      className="border h-[25px] w-full border-gray-400 rounded-sm pl-2 dark:bg-transparent dark:text-gray-100"
                    />
                  )}
                </div>
              ),
            },
            {
              Header: "Weight",
              width: 40,
              Cell: ({ row }: { row: Row<PrintItemType> }) => (
                <div>
                  {row.original.batchMode === "BEST_BEFORE_DATE" && (
                    <input
                      type="number"
                      onChange={(e) => {
                        const updatedValue = Number(e.target.value);
                        row.original.itemWeight = updatedValue;
                      }}
                      className="border h-[25px] w-full border-gray-400 rounded-sm pl-2 dark:bg-transparent dark:text-gray-100"
                    />
                  )}
                </div>
              ),
            },
          ]
        : []),
      {
        Header: "Labels",
        width: 50,
        Cell: ({ row }: { row: Row<PrintItemType> }) => (
          <div>
            <input
              type="number"
              className="border h-[25px] w-full border-gray-400 rounded-sm pl-2 dark:bg-transparent dark:text-gray-100"
              defaultValue={row.original.quantity}
              onChange={(e) => {
                const updatedValue = Number(e.target.value);
                row.original.quantity = updatedValue;
              }}
            />
          </div>
        ),
      },
      {
        Header: "FT",
        width: 60,
        Cell: ({ row }: { row: Row<PrintItemType> }) => (
          <div>
            <input
              type="text"
              onChange={(e) => {
                row.original.individualFreeText = e.target.value;
              }}
              className="border h-[25px] w-full border-gray-400 rounded-sm pl-2 dark:bg-transparent dark:text-gray-100"
            />
          </div>
        ),
      },
      {
        Header: "Print",
        accessor: "print",
        width: 40,
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useFlexLayout);

  return (
    <div className="h-[25vh] overflow-auto">
      <table
        {...getTableProps()}
        className="shadow-md dark:shadow-slate-600 border-b min-w-full max-w-full divide-gray-200 overflow-auto"
      >
        <thead className="bg-gray-100 dark:bg-gray-700 transition-colors duration-500">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  className={`${
                    columnIndex !== headerGroup.headers.length - 1 && "border-r"
                  } shadow-md relative p-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 border-b border-gray-300 flex`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200 dark:bg-gray-900 transition-colors duration-500 dark:divide-gray-500"
        >
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr className={`bg-white divide-gray-200`} {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  const columnAccessor = columns[cellIndex].accessor;

                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`text-left text-xs p-1 text-gray-500 border-gray-300 flex items-center dark:bg-gray-900 transition-colors duration-500 dark:text-gray-100 ${
                        cellIndex !== row.cells.length - 1 && "border-r"
                      }`}
                    >
                      {columnAccessor === "print" ? (
                        <div
                          onClick={(e) => sendItemLabel(row.original)}
                          className="pl-3 text-blue-600 hover:text-blue-800 cursor-pointer"
                        >
                          <FaPrint size={18} />
                        </div>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
