import dayjs from "dayjs";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { FaXmark } from "react-icons/fa6";
import { useState, useEffect, FC } from "react";
import { CgArrowsExpandLeft } from "react-icons/cg";
import Datepicker from "react-tailwindcss-datepicker";
import { dateRangeShortcuts } from "../../utils/functions";
import { DateValueType } from "react-tailwindcss-datepicker";
import { FaSearch, FaExternalLinkAlt } from "react-icons/fa";
import CustomSelect from "../reportInputsHelper/CustomSelect";
import { InventoryLogTable } from "./tables/InventoryLogTable";
import { useUserInfoStore } from "../../utils/store/userStore";
import { useInputValuesStore } from "../../utils/store/inputValuesStore";
import { InventoryLogProps, ValueLabelStringType } from "../../utils/type";

export const InventoryLog: FC<InventoryLogProps> = ({
  setInventoryLog,
  inventorySummary,
  inventoryLog,
  warehouseSelected,
  setWarehouseSelected,
  getInventoryLog,
  dateValues,
  setDateValues,
  transactionType,
  setTransactionType,
  setLogInventoryFilters,
  logInventoryFilters,
  itemUom,
  isDateFiltered,
}) => {
  const { userInfo } = useUserInfoStore();
  const handleDataChange = (date: DateValueType) => {
    setDateValues(date);
  };
  const [loading, setLoading] = useState<boolean>(true);
  const { inputValues } = useInputValuesStore();
  const [shortCutsUpdated, setShortCutsUpdated] = useState<any>();

  useEffect(() => {
    if (inputValues?.dateRanges?.length) {
      for (let i = 0; i < inputValues.dateRanges.length; i++) {
        let { name, starts, ends } = inputValues.dateRanges[i];
        //@ts-ignore
        dateRangeShortcuts.shortcuts[name] = {
          text: name,
          period: {
            start: dayjs(new Date(starts.split("T")[0])),
            end: dayjs(new Date(ends.split("T")[0])),
          },
        };
      }

      setShortCutsUpdated(dateRangeShortcuts);
      setLoading(false);
    }
  }, [inputValues]);

  const handleFilterRemove = (id: string | number | null | undefined): void => {
    const filteredArray = logInventoryFilters.filter(
      (filter) => filter.id !== id
    );
    setLogInventoryFilters(filteredArray);
    getInventoryLog(undefined, undefined, filteredArray);
  };

  const allOption = {
    value: "all",
    label: "Any",
  };

  const warehouseOptions = inputValues?.warehouses
    ? [
        allOption,
        ...inputValues?.warehouses.map((input) => ({
          value: input.id,
          label: input.name,
        })),
      ]
    : [allOption];

  return (
    <div className="w-[160px] xs:w-[220px] xs2:w-[260px] md:w-[380px] lg:w-[640px] xl:w-[1000px] overflow-x-auto max-h-[800px] text-gray-700 z-[200] p-2 pt-3 rounded">
      <div className="flex flex-col md:flex-wrap xl:flex-nowrap xl:flex-row items-center justify-between gap-2 mb-2">
        <div className="min-w-[250px] h-[82px] border dark:border-gray-400 rounded-md shadow p-1.5">
          <div className="flex items-end">
            <div className="flex gap-2">
              {inventorySummary?.basicInfo?.imageUrl ? (
                <img
                  className="w-[70px] h-[70px] rounded-md shadow"
                  src={inventorySummary?.basicInfo?.imageUrl}
                  alt="product"
                />
              ) : (
                <div className="w-[70px] h-[70px] rounded-md border shadow"></div>
              )}
              <div className="w-[145px]">
                <h3 className="text-sm truncate w-[150px] dark:text-gray-200">
                  {inventorySummary?.basicInfo?.title}
                </h3>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${userInfo?.organization?.appUrl}wapp/en-gb/inventory/products/details/${inventorySummary?.basicInfo?.id}`}
                  className="bg-green-500 hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 hover:text-white text-white rounded-md p-1 h-fit flex items-center gap-1.5 w-fit mt-0.5"
                >
                  <p className="max-w-[120px] truncate text-sm">
                    {inventorySummary?.basicInfo?.sku}
                  </p>
                  <FaExternalLinkAlt size={12} />
                </a>
              </div>
            </div>
            <button
              onClick={() => setInventoryLog(null)}
              className="w-fit hover:scale-[1.10] dark:text-gray-200"
            >
              <CgArrowsExpandLeft />
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 flex-col flex-wrap lg:flex-row xl:flex-nowrap items-center">
            <div className="relative">
              <label htmlFor="vendor" className="label-class z-[11]">
                Date range
              </label>
              <div
                id="data-picker"
                className="relative z-[10] flex border-[1px] border-[#cccccc] rounded-[4px] w-[220px] h-[41px] shadow"
              >
                {!loading && (
                  <Datepicker
                    displayFormat={"DD/MM/YY"}
                    placeholder="dd/mm/yy ~ dd/mm/yy"
                    value={dateValues}
                    showShortcuts={true}
                    useRange={false}
                    configs={shortCutsUpdated}
                    toggleClassName="absolute right-0 h-full text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed pr-1 "
                    inputClassName="w-full rounded-md focus:ring-0 font-normal border-none text-gray-700 pt-2.5 pl-1 text-sm placeholder:text-gray-700 dark:bg-[#2f343c] dark:text-gray-200 dark:placeholder:text-gray-200"
                    onChange={handleDataChange}
                  />
                )}
              </div>
            </div>
            <CustomSelect
              id={"warehouses"}
              isTransparent={true}
              label="Warehouses"
              options={warehouseOptions}
              onChange={(e: ValueLabelStringType) => {
                setWarehouseSelected(e?.value || "all");
              }}
              value={
                warehouseSelected !== "all"
                  ? {
                      value: warehouseSelected,
                      label: inputValues?.warehouses?.find(
                        (warehouse) => warehouse.id === warehouseSelected
                      )?.name,
                    }
                  : { value: "all", label: "Any" }
              }
            />

            <div className="relative">
              <label htmlFor="vendor" className="label-class">
                Transaction
              </label>
              <select
                className="input-class w-[120px]"
                value={transactionType}
                onChange={(e) => setTransactionType(e.target.value)}
              >
                <option value="">Any</option>
                <option value="in">In</option>
                <option value="out">Out</option>
                <option value="ret">Ret</option>
              </select>
            </div>
            <button
              onClick={() => getInventoryLog(undefined, undefined)}
              className="bg-blue-600 hover:bg-blue-700 shadow-md text-white rounded-md font-semibold disabled:bg-gray-400 px-2 py-1.5"
            >
              <FaSearch size={18} />
            </button>
          </div>
          <div className="flex gap-1 w-full">
            {logInventoryFilters?.length > 0 &&
              logInventoryFilters.map((filter) => (
                <div className="shadow  -md bg-mainColor text-white w-fit px-1 rounded flex items-center gap-2 text-sm h-fit">
                  <p>
                    {filter.name}: {filter.value}
                  </p>
                  <button
                    onClick={() => handleFilterRemove(filter.id)}
                    className="text-white hover:text-red-500"
                  >
                    <FaXmark />
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="rounded relative">
        {inventoryLog && (
          <div>
            <div className="max-h-[600px] overflow-x-auto">
              {inventoryLog.results ? (
                <div>
                  <InventoryLogTable
                    setLogInventoryFilters={setLogInventoryFilters}
                    logInventoryFilters={logInventoryFilters}
                    getInventoryLog={getInventoryLog}
                    data={inventoryLog}
                    itemUom={itemUom}
                    isDateFiltered={isDateFiltered}
                  />
                </div>
              ) : (
                <div className="w-full flex justify-center my-2">
                  <p>No data available</p>
                </div>
              )}
            </div>
            <div className="flex px-1 py-2 w-full bg-white dark:bg-[#2f343c] items-center justify-center gap-3 shadow">
              {/*  <div className="flex flex-col md:flex-row flex-wrap gap-2 sm:gap-5 items-center text-sm">
                <button
                  onClick={() =>
                    downloadExcel(
                      inventoryLogColumns as TableColumnType[],
                      inventoryLog.results,
                      "Inventory log",
                      true,
                      false
                    )
                  }
                  disabled={!inventoryLog}
                  className="flex gap-2 items-center bg-green-500 hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 disabled:bg-gray-400 text-white font-semibold py-1 px-2 rounded text-sm"
                >
                  <FaFileExcel size={18} />
                  Export to Excel
                </button>
                <button
                  onClick={() =>
                    downloadCSV(
                      inventoryLogColumns as TableColumnType[],
                      inventoryLog.results,
                      "Inventory log",
                      true,
                      false
                    )
                  }
                  disabled={!inventoryLog}
                  className="flex gap-2 items-center bg-indigo-500 hover:bg-indigo-600 dark:bg-indigo-700 dark:hover:bg-indigo-800 disabled:bg-gray-400 text-white font-semibold py-1 px-2 rounded text-sm"
                >
                  <FaFileCsv size={18} /> Export to CSV
                </button>
              </div> */}
              <div className="flex items-center gap-1 dark:text-gray-200">
                <button
                  onClick={() =>
                    getInventoryLog(undefined, (inventoryLog.offset || 0) - 50)
                  }
                  className="disabled:opacity-30"
                  disabled={inventoryLog.offset === 0}
                >
                  <MdOutlineKeyboardDoubleArrowLeft size={20} />
                </button>
                <span>{(inventoryLog.offset || 0) / 50 + 1}</span>
                <button
                  disabled={
                    inventoryLog.results
                      ? inventoryLog.results.length !== 50
                      : true
                  }
                  className="disabled:opacity-30"
                  onClick={() =>
                    getInventoryLog(undefined, 50 + (inventoryLog.offset || 0))
                  }
                >
                  <MdOutlineKeyboardDoubleArrowRight size={20} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
