export const binTableData = [
  { warehouseName: "Warehouse A", onHand: 150, bin: "Bin 1" },
  { warehouseName: "Warehouse B", onHand: 200, bin: "Bin 2" },
  { warehouseName: "Warehouse A", onHand: 120, bin: "Bin 3" },
];

export const turnoverTableData = [
  {
    month: "January",
    year: "2022",
    in: 100,
    out: 80,
    confirmed: 70,
    invoiced: 60,
    returns: 10,
  },
  {
    month: "February",
    year: "2022",
    in: 120,
    out: 90,
    confirmed: 80,
    invoiced: 70,
    returns: 15,
  },
  {
    month: "March",
    year: "2022",
    in: 150,
    out: 100,
    confirmed: 90,
    invoiced: 80,
    returns: 20,
  }
];
