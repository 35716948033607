import { useUserInfoStore } from "../../utils/store/userStore";

export const DateFormatterCell = ({
  date,
}: {
  date: string | Date | null | undefined;
}) => {
  const { userInfo } = useUserInfoStore();

  if (date) {
    return (
      <p className="mb-0">
        {new Intl.DateTimeFormat(userInfo?.locale!, {
          year: "2-digit",
          month: "numeric",
          day: "numeric",
        }).format(new Date(date))}
      </p>
    );
  } else {
    return null;
  }
};
