import {
  ErrorDetail,
  ReportScheduleCreateRequest,
  ReportScheduleUpdateRequest,
  ReportsService,
} from "../../api-client";
import {
  dayOfWeekArray,
  handleError,
  handleSuccess,
} from "../../utils/functions";
import { useState, FC, useEffect } from "react";
import type { ScheduleReportProps } from "../../utils/type";

export const ScheduleReport: FC<ScheduleReportProps> = ({
  setShowScheduleModal,
  parameters,
  reportType,
  schedule,
  reportName,
  getSchedules,
}) => {
  const [hour, setHour] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);
  const [dayOfWeek, setDayOfWeek] = useState<string>("0");
  const [dayOfMonth, setDayOfMonth] = useState<number>(1);
  const [removeColumns, setRemoveColumns] = useState<string>(
    schedule?.removeColumns ? schedule.removeColumns : ""
  );
  const [emailAddress, setEmailAddress] = useState<string>(
    schedule?.emailAddress ? schedule.emailAddress : ""
  );
  const [scheduleType, setScheduleType] = useState<string>("day");
  const [name, setName] = useState<string | null | undefined>(
    reportName ? reportName : "Report"
  );
  const [isActive, setIsActive] = useState<boolean | undefined>(
    schedule ? schedule.isActive : undefined
  );
  const [inputError, setInputError] = useState<ErrorDetail[]>([]);

  const createScheduleReport = async () => {
    const cron = generateCron();
    setInputError([]);
    const body: ReportScheduleCreateRequest = {
      report: { type: reportType!, parameters },
      name: name!,
      emailAddress,
      removeColumns,
      cron,
    };
    try {
      const response = await ReportsService.postReportsSchedules({
        body,
      });
      if (response.error) {
        if (response.error.errorDetails) {
          setInputError(response.error.errorDetails);
          return;
        }
        handleError(response.error);
        return;
      }

      handleSuccess("Schedule created", false);
      if (getSchedules) {
        getSchedules();
      }
      setShowScheduleModal(false);
    } catch (error: any) {
      const { data } = error?.response;
      if (data.errorDetails) {
        setInputError(data.errorDetails);
        return;
      }
      handleError(data || "");

      return;
    }
  };

  const updateReport = async () => {
    if (!schedule || !schedule.cron) return;
    setInputError([]);
    const cron = generateCron();
    const body: ReportScheduleUpdateRequest = {
      name: name!,
      emailAddress,
      removeColumns,
      isActive,
      cron,
    };
    try {
      const response = await ReportsService.patchReportsSchedulesId({
        body,
        path: { id: schedule.id! },
      });

      if (response.error) {
        if (response.error.errorDetails) {
          setInputError(response.error.errorDetails);
          return;
        }
        handleError(response.error);
        return;
      }

      handleSuccess("Schedule updated", false);
      if (getSchedules) {
        getSchedules();
      }
      setShowScheduleModal(false);
    } catch (error: any) {
      const { data } = error?.response;
      if (data.errorDetails) {
        setInputError(data.errorDetails);
        return;
      }
      handleError(data || "");

      return;
    }
  };

  const generateCron = (): string => {
    let cron: string = "";
    switch (scheduleType) {
      case "day":
        cron = `${minute} ${hour} * * *`;
        break;
      case "week":
        cron = `${minute} ${hour} * * ${dayOfWeek}`;
        break;
      case "month":
        cron = `${minute} ${hour} ${dayOfMonth} * *`;
        break;
      default:
        break;
    }
    return cron;
  };

  const setDefaultValuesFromCron = () => {
    if (!schedule || !schedule.cron) return;

    const [minute, hour, dayOfMonth, dayOfWeek] = schedule.cron.split(" ");

    if (dayOfWeek !== "*") {
      setScheduleType("week");
      setDayOfWeek(dayOfWeek);
    } else if (dayOfMonth !== "*") {
      setScheduleType("month");
      setDayOfMonth(Number(dayOfMonth));
    } else {
      setScheduleType("day");
    }

    setMinute(Number(minute));
    setHour(Number(hour));

    setName(schedule.name);
  };

  useEffect(() => {
    setDefaultValuesFromCron();
  }, [schedule]);

  return (
    <div className="shadow-2xl dark:shadow-slate-600 border-2 border-mainColor dark:border-mainColorDarkMode transition-colors duration-500 rounded-md">
      <div className="bg-mainColor dark:bg-mainColorDarkMode transition-colors duration-500 p-2 text-white">
        <h2 className="p-1 text-2xl">Scheduled Report </h2>
      </div>
      <div
        onSubmit={createScheduleReport}
        className="p-4 bg-white dark:bg-bgDarkColor transition-colors duration-500 rounded-b-md"
      >
        <div className="flex flex-col md:flex-row gap-5 md:gap-2 items-center justify-between mt-3">
          <div className="relative h-[61px]">
            <label
              htmlFor="name"
              className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 left-1`}
            >
              Report name
            </label>
            <input
              type="text"
              id="name"
              onChange={(e) => setName(e.target.value)}
              className="border-[1px] border-[#cccccc] hover:border-[#b3b3b3] w-[220px] h-[41px] rounded-[4px] p-2 dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200"
              value={name!}
            />
            {inputError.find((error) => error.field === "name") && (
              <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
                {
                  inputError.find((error) => error.field === "name")
                    ?.description
                }
              </div>
            )}
          </div>
          <div className="relative h-[61px]">
            <label
              htmlFor="emailAddress"
              className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 left-1`}
            >
              Email address
            </label>
            <input
              type="text"
              id="emailAddress"
              onChange={(e) => setEmailAddress(e.target.value)}
              value={emailAddress}
              className="border-[1px] border-[#cccccc] hover:border-[#b3b3b3] w-[220px] h-[41px] rounded-[4px] p-2 dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200"
            />
            {inputError.find((error) => error.field === "emailAddress") && (
              <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
                {
                  inputError.find((error) => error.field === "emailAddress")
                    ?.description
                }
              </div>
            )}
          </div>
        </div>
        <div
          className={`flex gap-4 mt-5 item-center ${
            (scheduleType === "month" || scheduleType === "week") &&
            "flex-col md:flex-row"
          }`}
        >
          <div className="flex gap-4">
            <div className="relative">
              <label
                htmlFor="every"
                className="absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1"
              >
                Every
              </label>
              <select
                value={scheduleType}
                onChange={(e) => setScheduleType(e.target.value)}
                className="p-2 rounded border-[1px] border-[#cccccc] hover:border-[#b3b3b3] dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200"
              >
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
              </select>
            </div>
            {scheduleType === "month" && (
              <div className="relative">
                <label
                  htmlFor="every"
                  className="absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1"
                >
                  Day of the month
                </label>
                <select
                  value={dayOfMonth}
                  onChange={(e) => setDayOfMonth(parseInt(e.target.value, 10))}
                  className="p-2 w-[130px] rounded border-[1px] border-[#cccccc] hover:border-[#b3b3b3] dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200"
                >
                  {Array.from({ length: 31 }, (_, i) => i + 1).map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {scheduleType === "week" && (
              <div className="relative">
                <label
                  htmlFor="every"
                  className="absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1"
                >
                  Day of the week
                </label>
                <select
                  value={dayOfWeek}
                  onChange={(e) => setDayOfWeek(e.target.value)}
                  className="p-2 w-[130px] rounded border-[1px] border-[#cccccc] hover:border-[#b3b3b3] dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200"
                >
                  {dayOfWeekArray.map((day) => {
                    return (
                      <option key={day.value} value={day.value}>
                        {day.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          <div className="flex gap-4">
            <div className="relative">
              <label
                htmlFor="hours"
                className="absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1"
              >
                HH
              </label>
              <select
                value={hour}
                onChange={(e) => setHour(parseInt(e.target.value, 10))}
                className="p-2 rounded w-[60px] border-[1px] border-[#cccccc] hover:border-[#b3b3b3] dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200"
              >
                {Array.from({ length: 24 }, (_, i) => i).map((h) => (
                  <option key={h} value={h}>
                    {h}
                  </option>
                ))}
              </select>
            </div>
            <div className="relative">
              <label
                htmlFor="minutes"
                className="absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1"
              >
                MM
              </label>
              <select
                value={minute}
                onChange={(e) => setMinute(parseInt(e.target.value, 10))}
                className="p-2 rounded w-[60px] border-[1px] border-[#cccccc] hover:border-[#b3b3b3] dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200"
              >
                {Array.from({ length: 12 }, (_, i) => i).map((m) => (
                  <option key={m * 5} value={m * 5}>
                    {m * 5}
                  </option>
                ))}
              </select>
            </div>
            {schedule && (
              <div className="relative">
                <p className="text-sm text-[#427dab] z-[9] bg-white px-1 absolute -top-[10.5px] dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500">
                  {isActive ? "Active" : "Inactive"}
                </p>
                <button
                  onClick={() => setIsActive(!isActive)}
                  className={`relative inline-block w-12 h-6 bg-gray-400 rounded-full ease-in-out mt-3 transition-colors duration-500 ${
                    isActive
                      ? "bg-green-400 dark:bg-green-600"
                      : "bg-gray-400 dark:bg-gray-600"
                  }`}
                >
                  <span
                    className={`absolute -top-0.5 inline-block w-6 h-6 mt-0.5 bg-white rounded-full shadow-md dark:shadow-slate-600 transform-gpu transition-transform duration-200 ease-in-out ${
                      isActive ? "translate-x-0" : "-translate-x-6"
                    }`}
                  ></span>
                </button>
              </div>
            )}
          </div>
        </div>
        {inputError.find((error) => error.field === "cron") && (
          <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5 w-fit">
            {inputError.find((error) => error.field === "cron")?.description}fff
          </div>
        )}
        <div className="relative mt-7 flex justify-center md:block">
          <label
            htmlFor="reportName"
            className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 left-1`}
          >
            Remove columns
          </label>
          <input
            type="text"
            id="removeColumns"
            className="border-[1px] border-[#cccccc] hover:border-[#b3b3b3] w-[220px] h-[41px] rounded-[4px] p-2 dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200"
            value={removeColumns}
            onChange={(e) => setRemoveColumns(e.target.value)}
          />
          {inputError.find((error) => error.field === "removeColumns") && (
            <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
              {
                inputError.find((error) => error.field === "removeColumns")
                  ?.description
              }
            </div>
          )}
        </div>
        <div className="flex justify-between md:px-10 mt-8">
          <button
            onClick={() => setShowScheduleModal(false)}
            className="p-2 rounded bg-red-600 hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-700 text-gray-100 shadow-md dark:shadow-slate-600 font-semibold w-[100px]"
          >
            Cancel
          </button>
          {schedule ? (
            <button
              onClick={updateReport}
              className="p-2 rounded bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 text-gray-100 shadow-md dark:shadow-slate-600 font-semibold w-[100px]"
            >
              Update
            </button>
          ) : (
            <button
              onClick={createScheduleReport}
              className="p-2 rounded bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 text-gray-100 shadow-md dark:shadow-slate-600 font-semibold w-[100px]"
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
