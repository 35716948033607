import {
  useTable,
  useFlexLayout,
  useResizeColumns,
  Cell,
  Row,
} from "react-table";
import "tailwindcss/tailwind.css";
import { FC, ReactElement } from "react";
import { FaFilter } from "react-icons/fa";
import {
  useUserInfoStore,
  UserInfoInterface,
} from "../../../utils/store/userStore";
import { ItemInventoryLog } from "../../../api-client";
import { inventoryLogColumns } from "../../../utils/columns";
import { handleColumnRounded } from "../../../utils/functions";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import { DateFormatterCell } from "../../tableHelpers/DateFormatterCell";
import { InventoryLogTableProps , LogInventoryFilterType} from "../../../utils/type";

export const InventoryLogTable: FC<InventoryLogTableProps> = ({
  data,
  setLogInventoryFilters,
  logInventoryFilters,
  getInventoryLog,
  itemUom,
  isDateFiltered,
}) => {
  const isColumnEmpty = (columnName: string): boolean => {
    return rows.every((row) => {
      //@ts-ignore
      const value = row.original[columnName];
      return value === null || value === undefined || value === 0;
    });
  };

  const renderColumn = (
    column: any,
    columnIndex: number
  ): ReactElement<any, any> | null => {
    const columnName = column.id;
    const shouldRenderColumn = !isColumnEmpty(columnName);

    if (isDateFiltered && columnName === "balanceUom") {
      return null;
    }

    if (shouldRenderColumn) {
      return (
        <th
          {...column.getHeaderProps()}
          className={`${handleColumnRounded(
            columnIndex,
            inventoryLogColumns.length
          )} text-left p-2 py-1.5 text-xs font-medium text-gray-500 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 relative`}
        >
          {column.render("Header")}
          <div
            {...column.getResizerProps()}
            className="absolute right-0 top-0 bottom-0 z-20 cursor-col-resize w-[1px] hover:bg-black hover:w-[2px]"
          />
        </th>
      );
    }

    return null;
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: inventoryLogColumns,
        data: data.results!,
      },
      useResizeColumns,
      useFlexLayout
    );

  const userInfo = useUserInfoStore(
    (state: UserInfoInterface) => state.userInfo
  );

  const handleFilter = (type: string, row: ItemInventoryLog): void => {
    let tempFilter;
    if (type === "batch") {
      const existingFilter = logInventoryFilters.find(
        (filter) => filter.id === row.batchInfo?.id
      );

      if (!existingFilter) {
        tempFilter = [
          ...logInventoryFilters,
          {
            name: "Batch",
            value: row.batchInfo?.bestBeforeDate
              ? new Intl.DateTimeFormat(userInfo?.locale!, {
                  year: "2-digit",
                  month: "numeric",
                  day: "numeric",
                }).format(new Date(row.batchInfo?.bestBeforeDate))
              : null,
            id: row.batchInfo?.id,
          },
        ];
      }
    } else if (type === "bin") {
      const existingFilter = logInventoryFilters.find(
        (filter) => filter.id === row.bin
      );
      if (!existingFilter) {
        tempFilter = [
          ...logInventoryFilters,
          {
            name: "Bin",
            value: row.bin,
            id: row.bin,
          },
        ];
      }
    }
    setLogInventoryFilters(tempFilter as LogInventoryFilterType[]);

    getInventoryLog(undefined, undefined, tempFilter);
  };

  const renderCell = (
    cell: Cell<ItemInventoryLog, any>,
    cellIndex: number,
    row: Row<ItemInventoryLog>
  ): ReactElement<any, any> | null => {
    const columnName = cell.column.id;
    const shouldRenderCell = !isColumnEmpty(columnName);

    if (isDateFiltered && columnName === "balanceUom") {
      return null;
    }

    if (shouldRenderCell) {
      const isBatch = columnName === "batchInfo";
      const isBin = columnName === "bin";
      const isDoc = columnName === "rootDocument";
      const isQty = columnName === "qty";

      let docLink = `${userInfo?.organization?.appUrl}go`;

      if (isDoc) {
        if (cell.value?.toLowerCase().startsWith("so")) {
          docLink += `/vendor/so/${cell.value.slice(2)}`;
        } else if (cell.value?.toLowerCase().startsWith("po")) {
          docLink += `/vendor/po/${cell.value.slice(2)}`;
        } else if (cell.value?.toLowerCase().startsWith("trc")) {
          docLink += `/vendor/trc/${cell.value.slice(3)}`;
        }
      }

      const rowLine = row.original;

      return (
        <td
          {...cell.getCellProps()}
          className={`text-left text-xs p-1 text-gray-500 border-gray-200 dark:text-gray-300 dark:border-gray-500 truncate ${
            cellIndex !== row.cells.length - 1 && "border-r"
          }`}
        >
          {isDoc && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={docLink}
              className="text-blue-500 hover:text-blue-500"
            >
              {cell.render("Cell")}
            </a>
          )}
          {isBin && cell.value !== null && (
            <div className="flex justify-between">
              <p>{cell.value}</p>
              {!logInventoryFilters.some(
                (filter) => filter.id === rowLine.bin
              ) && (
                <span
                  data-tooltip-id="filter"
                  data-tooltip-content="Add filter"
                >
                  <button
                    onClick={() => {
                      handleFilter("bin", rowLine);
                    }}
                  >
                    <FaFilter color="#4273B8" />
                  </button>
                </span>
              )}
            </div>
          )}
          {isBatch && cell.value?.bestBeforeDate !== undefined && (
            <div className="flex justify-between">
              <DateFormatterCell date={cell.value?.bestBeforeDate} />
              {!logInventoryFilters.some(
                (filter) => filter.id === rowLine.batchInfo?.id
              ) && (
                <span
                  data-tooltip-id="filter"
                  data-tooltip-content="Add filter"
                >
                  <button
                    onClick={() => {
                      handleFilter("batch", rowLine);
                    }}
                  >
                    <FaFilter color="#4273B8" />
                  </button>
                </span>
              )}
            </div>
          )}
          {isQty && rowLine.qty !== 0 && (
            <div className="flex justify-between">
              <p>
                {rowLine.qty} {rowLine.uomName}
              </p>
              <div className="flex">
                <p className="text-[10px]">
                  {calculateTotalShelfQuantity(rowLine.uomName, rowLine.qty)}
                </p>
                {rowLine.qty && (
                  <p>
                    {rowLine.qty < 0 ? (
                      <FaArrowDownLong color={"red"} />
                    ) : (
                      <FaArrowUpLong color={"green"} />
                    )}
                  </p>
                )}
              </div>
            </div>
          )}
          {!isBin && !isBatch && !isDoc && !isQty && cell.render("Cell")}
        </td>
      );
    }

    return null;
  };

  const calculateTotalShelfQuantity = (
    relativeName: string | null | undefined,
    quantity: number | undefined
  ) => {
    if (!itemUom?.length) return null;
    if (relativeName === itemUom[0].name) return null;

    const matchingItems = itemUom.find((item) => item.name === relativeName);
    if (!matchingItems) return;

    const totalShelfQuantity = quantity || 0 * (matchingItems.shelfQty || 1);
    if (totalShelfQuantity !== 0) {
      return `(${totalShelfQuantity})`;
    }
    return null;
  };

  return (
    <table {...getTableProps()} className="w-full dark:border-gray-500">
      <thead className=" -top-[0.3px]">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, columnIndex) =>
              renderColumn(column, columnIndex)
            )}
          </tr>
        ))}
      </thead>
      <tbody
        {...getTableBodyProps()}
        className="bg-white pt-10 dark:bg-gray-900 dark:divide-gray-500 dark:border-gray-500 dark:text-gray-100"
      >
        {rows.map((row, index) => {
          prepareRow(row);
          const isEvenRow =
            index % 2 === 0
              ? "bg-white dark:bg-gray-900"
              : "bg-blue-50 border-r-white dark:bg-black";
          return (
            <tr {...row.getRowProps()} className={`${isEvenRow}`}>
              {row.cells.map((cell, cellIndex) =>
                renderCell(cell, cellIndex, row)
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
