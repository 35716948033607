import "animate.css";
import dayjs from "dayjs";
import axios from "axios";
import { useEffect, useState } from "react";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { RiMenuUnfoldFill } from "react-icons/ri";
import { Topbar } from "./components/navbar/Topbar";
import { createClient } from "@hey-api/client-fetch";
import { Reports } from "./components/pages/Reports";
import { Sidebar } from "./components/navbar/Sidebar";
import { Insights } from "./components/pages/Insights";
import { Schedules } from "./components/pages/Schedules";
import { useUserInfoStore } from "./utils/store/userStore";
import { Dashboards } from "./components/pages/Dashboards";
import { handleError, handleCookie } from "./utils/functions";
import { useDarkModeStore } from "./utils/store/darkModeStore";
import { ReportServer } from "./components/pages/ReportServer";
import { PageNotFound } from "./components/pages/PageNotFound";
import { useInputValuesStore } from "./utils/store/inputValuesStore";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ReportsService, ReportTypeConfiguration, UserService } from "./api-client";

dayjs.extend(weekOfYear);

createClient({
  baseUrl: process.env.REACT_APP_API_URL,
});

function App() {
  const [reports, setReports] = useState<
    ReportTypeConfiguration[] | null | undefined
  >(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
  const { darkMode, setDarkMode } = useDarkModeStore();
  const { setUserInfo, userInfo } = useUserInfoStore();
  const { setInputValues } = useInputValuesStore();

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const getInputData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lists`
      );

      setInputValues(response.data);
    } catch (error: any) {
      handleError(error);
      return;
    }
  };

  const getReports = async () => {
    try {
      const inputsResponse = await ReportsService.getReportsListTypes();

      if (inputsResponse.error) {
        handleError(inputsResponse.error);
        return;
      }

      setReports(inputsResponse.data.results);
    } catch (error: any) {
      handleError(error.message);
    }
  };
  
  const getUser = async () => {
    try {
      const response = await UserService.getUserSelf();
 

      setUserInfo(response.data);
    } catch (error: any) {
      console.log(error);
      handleError(error.message);
    }
  };

  const handleDarkMode = () => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
      setDarkMode(true);
    } else {
      document.documentElement.classList.remove("dark");
      setDarkMode(false);
    }
  };

  handleCookie();

  useEffect(() => {
    document.title = `Report ${
      userInfo?.locale === "en-us" ? "Center" : "Centre"
    }`;

    handleDarkMode();
    getInputData();
    getReports();
    getUser();
  }, []);

  return (
    <>
      <BrowserRouter>
        <div className="flex lg:flex-row flex-col bg-[#edf1fa] dark:bg-[#0b1727] transition-colors duration-500 min-h-screen">
          <Sidebar
            reports={reports}
            isCollapsed={isSidebarCollapsed}
            onToggle={toggleSidebar}
          />
          <div
            className={`mt-[15px] mb-2 mr-2 transition-all duration-700 ${
              isSidebarCollapsed
                ? "ml-[70px] w-[calc(100vw-80px)]"
                : "ml-[300px] md:w-[calc(100vw-310px)] w-0 hidden md:block"
            }`}
          >
            {isSidebarCollapsed && (
              <button
                onClick={toggleSidebar}
                className="absolute top-[38px] left-2 text-mainColor"
              >
                <RiMenuUnfoldFill size={30} />
              </button>
            )}{" "}
            <Topbar />
            <Routes>
              <Route path="/reports/insights" element={<Insights />} />
              <Route
                path="/reports/inventory_reports/:reportType"
                element={<Reports reports={reports} />}
              />
              <Route
                path="/reports/sales_reports/:reportType"
                element={<Reports reports={reports} />}
              />
              <Route
                path="/reports/system_reports/reportServer"
                element={<ReportServer />}
              />
              <Route
                path="/reports/system_reports/schedules"
                element={<Schedules />}
              />
              <Route
                path="/reports/system_reports/dashboards"
                element={<Dashboards />}
              />
              <Route path="/reports/404" element={<PageNotFound />} />
              <Route path="/*" element={<Navigate to="/404" />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
