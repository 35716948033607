import axios from "axios";
import dayjs from "dayjs";
import type {
  InputValuesType,
  DefaultOptionsType,
  FormValuesType,
  TableColumnType,
  RowType,
  WarehousesType,
} from "./type";
import {
  ErrorResponse,
  ItemInventoryWithWarehouse,
  PropertyAttribute,
} from "../api-client";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { saveAs } from "file-saver";
import debounce from "debounce-promise";
import { client } from "@hey-api/client-fetch";
import { NavigateFunction } from "react-router-dom";
import { DateType } from "react-tailwindcss-datepicker";

const wait = 750;

export const loadContactOptions = async (value: string) => {
  // function used to get input value for contacts input
  try {
    if (value) {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/contacts/lookup?query=${value}`
      );

      return res.data.results.map((t: WarehousesType) => ({
        value: t.code,
        valueId: t.id,
        label: t.codeName,
      }));
    }
  } catch (error) {
    console.log(error);
  }
};


export const debouncedLoadContactOptions = debounce(loadContactOptions, wait);

export const loadItemOptions = async (value: string) => {
  // function used to get input value for contacts input
  try {
    if (value) {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/inventory/lookup?query=${value}`
      );

      return res.data.data.map((t: { sku: string }) => ({
        value: t.sku,
        label: t.sku,
      }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const debouncedLoadItemOptions = debounce(loadItemOptions, wait);

export const getUrl = (data: FormValuesType, parentKey = ""): string => {
  let queryParams = [];

  for (const [key, value] of Object.entries(data)) {
    let currentKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof value === "object" && !Array.isArray(value)) {
      // If the value is an object, recursively call the function

      queryParams.push(getUrl(value, currentKey));
    } else {
      // Handle arrays by joining them with commas
      if (Array.isArray(value) && value.length === 0) continue;
      if (!Array.isArray(value) && value === "") continue;
      if (typeof value == "boolean" && !value) continue;

      let paramValue = Array.isArray(value) ? value.join(",") : value;
      if (
        (currentKey === "sales.sku" || currentKey === "items.sku") &&
        typeof paramValue === "string"
      ) {
        paramValue = paramValue!.split(" ");
      }

      if (
        data.dynamicStartingDate &&
        currentKey === "date.dynamicStartingDate"
      ) {
        currentKey = "date.start";
      }
      if (data.dynamicEndingDate && currentKey === "date.dynamicEndingDate") {
        currentKey = "date.end";
      }

      queryParams.push(`${currentKey}=${paramValue}`);
    }
  }
  queryParams = queryParams.filter((value) => value !== "");
  return queryParams.join("&");
};

// add parameter when fetching table in the right format
export const addParameter = (
  parameters: { name: string; value: string }[],
  name: string,
  value: any
) => {
  if (Array.isArray(value) && value.length > 0) {
    parameters.push({ name, value: value.join(",") });
  } else if (typeof value === "string" && value.trim() !== "") {
    if (name.split(".")[1] === "dynamicStartingDate") {
      name = `${name.split(".")[0]}.start`;
    } else if (name.split(".")[1] === "dynamicEndingDate") {
      name = `${name.split(".")[0]}.end`;
    }

    parameters.push({ name, value: value.trim().replace(/\s+/g, ",") });
  } else if (typeof value === "boolean" && value) {
    parameters.push({ name, value: value.toString() });
  }
};

const moneyFormat = new Intl.NumberFormat("en-GB", {
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
const inventoryFormat = new Intl.NumberFormat("en-GB", {
  style: "decimal",
  maximumFractionDigits: 5,
  minimumFractionDigits: 0,
});
const toMax3decimals = new Intl.NumberFormat("en-GB", {
  style: "decimal",
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});

export const as2decimals = (
  value: number | string | undefined
): string | number => {
  if (!value) return "";
  if (typeof value !== "number") {
    return value;
  } else {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  }
};

export const as3decimals = (input: number | undefined) => {
  if (input != null && input !== undefined) {
    return toMax3decimals.format(input);
  }
  return null;
};

export const asMoney = (input: number | undefined) => {
  if (input != null && input !== undefined) {
    return moneyFormat.format(input);
  }
  return null;
};

export const asInventory = (input: number | undefined) => {
  if (input != null && input !== undefined) {
    return inventoryFormat.format(input);
  }
  return null;
};

// get the params from url
export const getQueryParams = (
  searchParams: URLSearchParams
): {
  [key: string]:
    | string
    | string[]
    | number
    | number[]
    | { [nestedKey: string]: string };
} => {
  const queryParams: {
    [key: string]: string | string[] | { [nestedKey: string]: string };
  } = {};

  searchParams.forEach((value, key) => {
    const regexDate = /^\d{4}-\d{2}-\d{2}$/;
    const [paramName, dateType] = key.split(".");

    if (dateType === "start" || dateType === "end") {
      // Handle date type parameters
      if (!queryParams[paramName]) {
        queryParams[paramName] = {};
      }

      if (!regexDate.test(value)) {
        const dynamicKey = `dynamic${
          dateType.charAt(0).toUpperCase() + dateType.slice(1)
        }ingDate`;
        (queryParams[paramName] as { [key: string]: string })[dynamicKey] =
          value;
      } else {
        (queryParams[paramName] as { [key: string]: string })[dateType] = value;
      }
    } else {
      // Handle regular parameters
      if (queryParams[key]) {
        if (Array.isArray(queryParams[key])) {
          queryParams[key] = [...(queryParams[key] as string[]), value];
        } else if (typeof queryParams[key] === "object") {
          queryParams[key] = {
            ...(queryParams[key] as { [nestedKey: string]: string }),
            value,
          };
        } else {
          queryParams[key] = [queryParams[key] as string, value];
        }
      } else {
        queryParams[key] = value;
      }
    }
  });

  return queryParams;
};

const setValue = (
  obj: FormValuesType,
  path: string[],
  value:
    | string[]
    | number[]
    | string
    | number
    | {
        [nestedKey: string]: string;
      }
) => {
  let current = obj;

  for (let i = 0; i < path.length - 1; i++) {
    const key = path[i];
    if (!current[key]) current[key] = {};
    current = current[key];
  }
  current[path[path.length - 1]] = value;
};

export const updateDynamicState = (
  prevState: { [key: string]: string[] | number[] },
  values: {
    [key: string]:
      | string
      | string[]
      | number
      | number[]
      | {
          [nestedKey: string]: string;
        };
  }
): FormValuesType => {
  const updatedState: FormValuesType = { ...prevState };

  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      const keys = key.split(".");
      let value = values[key];
      // if value is an empty string skip
      if (value === "") continue;

      // if it contains a comma is an array of value
      if (typeof value === "string" && value.includes(",")) {
        let values: string[] | number[] = value
          .split(",")
          .map((s: string) => s.trim());
        // check if the value is a number if it is transform in integer

        if (isStringAnInteger(values[0]) && key !== "items.sku") {
          values = values.map(Number);
        }
        setValue(updatedState, keys, values);
      } else {
        if (typeof value === "string" && isStringAnInteger(value)) {
          if (key !== "items.sku") {
            value = Number(value);
          }
          if (typeof value === "number") {
            setValue(updatedState, keys, [value]);
          }
        } else {
          setValue(updatedState, keys, value);
        }
      }
    }
  }

  return updatedState;
};
const isStringAnInteger = (str: string): boolean => {
  const num = Number(str);
  return Number.isInteger(num);
};
interface ReturnValue {
  value: string;
  label: string | undefined;
}

export const getValueInput = (
  formKey: string,
  inputKey: string,
  formValues: FormValuesType,
  inputValues: InputValuesType | null,
  idOrCodeKey: string
): ReturnValue[] | ReturnValue | undefined => {
  let valueFromForm = formValues[formKey];
  let isTag = false;
  let tagType = "";

  // if the formKey has a dot is a sub input like items.sku
  if (formKey.includes(".")) {
    const fromKeyArray = formKey.split(".");
    valueFromForm = formValues[fromKeyArray[0]][fromKeyArray[1]];
    // check if the input is a tag
    if (["tag", "contactTag", "docTag"].includes(fromKeyArray[1])) {
      isTag = true;
      tagType = fromKeyArray[1];
      valueFromForm = formValues[fromKeyArray[0]];
    }
  }

  if (!inputValues) return undefined;
  const relevantInputValues = inputValues[inputKey];

  // if there are no input return
  if (!Array.isArray(relevantInputValues)) {
    return undefined;
  }
  // if is a tag get both the included and excluded
  if (isTag) {
    const tagValues = valueFromForm[tagType] ?? [];
    const tagExcludeKey = `${tagType}Exclude`;
    const tagExcludedValues = valueFromForm[tagExcludeKey] ?? [];

    // Create a map to store the original order of items
    const originalOrderMap = new Map();
    relevantInputValues.forEach((item, index) => {
      originalOrderMap.set(item[idOrCodeKey], index);
    });

    // Combine and sort based on the original order
    const allTagValues = [...tagValues, ...tagExcludedValues].sort(
      (a, b) => originalOrderMap.get(a) - originalOrderMap.get(b)
    );

    const mapValueToLabel = (codeOrId: string) => {
      const foundItem = relevantInputValues.find(
        (item) => item[idOrCodeKey] === codeOrId
      );
      return {
        value: codeOrId,
        label: idOrCodeKey === "tagId" ? foundItem?.tagName : foundItem?.name,
      };
    };

    return allTagValues.map(mapValueToLabel);
  }

  // if the value is an array, get the label for each value
  if (Array.isArray(valueFromForm)) {
    return valueFromForm.map((codeOrId) => ({
      value: codeOrId,
      label: relevantInputValues.find(
        (item) => String(item[idOrCodeKey]) === String(codeOrId)
      )?.name,
    }));
  } else if (valueFromForm) {
    let label: string = relevantInputValues.find(
      (item) => item[idOrCodeKey] === valueFromForm
    )?.name;

    return {
      value: valueFromForm,
      label,
    };
  } else {
    return undefined;
  }
};

// get the option for contact type input
export const getContactOption = async (
  values: string[] | undefined,
  fieldName: string
) => {
  const defaultOptions: DefaultOptionsType[] = [];
  if (values) {
    for (let x = 0; x < values.length; x++) {
      // fetch the option with the value name
      const options: Record<string, string>[] = await loadContactOptions(
        values[x]
      );

      const defaultOption: DefaultOptionsType[] = options
        ? options
            .filter((option) => values[x] === option.value)
            .map((filteredOption) => ({
              value: filteredOption.value,
              label: filteredOption.label,
              fieldName: fieldName,
              isVisible: true,
            }))
        : [];
      defaultOptions.push(...defaultOption);
    }
  }
  return defaultOptions;
};

export const getLabelByValue = (value: string) => {
  const option = dateSelectOption.find((option) => option.value === value);
  return option ? option.label : value;
};

export const abbreviateNumber = (value: number): string => {
  const absValue = Math.abs(value);
  let newValue: string = "";
  let prefix: string = value < 0 ? "-" : "";

  if (absValue >= 1e6 && absValue < 1e9) {
    newValue = prefix + (absValue / 1e6).toFixed(2) + "M";
  } else if (absValue >= 1e9) {
    newValue = prefix + (absValue / 1e9).toFixed(2) + "B";
  } else {
    newValue = value.toLocaleString();
  }
  return newValue;
};

export const handleError = (response: ErrorResponse) => {
  const errorMessage = response?.error;
  const errorData = response?.errors;

  let errorMessageString = "";
  if (typeof response === "string") {
    errorMessageString = response;
  } else if (errorMessage) {
    errorMessageString = errorMessage;
  } else if (errorData) {
    const errorMessages: string[] = [];

    Object.keys(errorData).forEach((key) => {
      // @ts-ignore
      const messages = errorData[key];
      messages.forEach((message: string) => {
        errorMessages.push(`${message}`);
      });
    });

    errorMessageString = errorMessages.join("\n");
  }

  Swal.fire({
    title: "Something went wrong",
    text: errorMessageString,
    position: "top",
    confirmButtonText: "Close",
    ...swalStyle,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.close();
    }
  });
};

export const handleSuccess = (title: string, refresh: boolean = true) => {
  Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    customClass: {
      title: "!w-fit dark:!text-gray-200",
      popup: "!w-fit dark:!bg-gray-800",
    },
  })
    .fire({
      icon: "success",
      title,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (refresh) {
          window.location.reload();
        }

        Swal.close();
      }
    });
};

export const swalStyle = {
  allowOutsideClick: false,
  backdrop: false,
  customClass: {
    popup: "pb-2 w-fit dark:!bg-gray-800",
    modal: "w-fit bg-red-500",
    actions: "!mt-0",
    confirmButton: " !bg-gray-500 border-none !shadow dark: focus:!shadow py-1",
    cancelButton: "!bg-red-500 border-none !shadow focus:!shadow py-1",
    title: "text-xl font-normal pt-2 px-4 dark:text-gray-200",
    htmlContainer: "dark:!text-gray-400 !text-gray-500 !text-base !mt-1 mb-0",
  },
  showClass: {
    popup: "animate__animated animate__slideInDown animate__faster",
  },
  hideClass: {
    popup: "animate__animated animate__fadeOutUp animate__faster",
  },
};

export const handleCookie = async () => {
  const queryParameters = new URLSearchParams(window.location.search);
  let token = queryParameters.get("token");

  if (!token) {
    let cookieToken = Cookies.get("report-api-token");
    if (cookieToken) {
      token = cookieToken;
    }
  }

  if (token) {
    Cookies.set("report-api-token", token, { expires: 7, path: "/" });
  }

  const AuthorizationToken =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_TOKEN
      : token;

  axios.defaults.headers.common["Authorization"] = AuthorizationToken;

  client.interceptors.request.use((request) => {
    request.headers.set("Authorization", AuthorizationToken!);
    return request;
  });
};

export const delayedState = (
  state: boolean,
  setOverflowDelayed: (value: boolean) => void,
  timeOutId: NodeJS.Timeout | null,
  setTimeoutGeneralId: (value: NodeJS.Timeout | null) => void
) => {
  if (state) {
    const id = setTimeout(() => {
      setOverflowDelayed(true);
      setTimeoutGeneralId(null);
    }, 500);
    setTimeoutGeneralId(id);
  } else {
    if (timeOutId) {
      clearTimeout(timeOutId);
      setTimeoutGeneralId(null);
    }
    setOverflowDelayed(false);
  }
  return () => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
  };
};

export const shouldIncludeColumn = (
  data: ItemInventoryWithWarehouse[],
  accessor:
    | keyof ItemInventoryWithWarehouse
    | ((row: ItemInventoryWithWarehouse) => any | undefined)
) => {
  return data.some((current) => {
    const value =
      typeof accessor === "function" ? accessor(current) : current[accessor];
    return value !== null && value !== 0;
  });
};

export const isAnythingSelected = (object: FormValuesType) => {
  for (const key in object) {
    const value = object[key];

    if (Array.isArray(value) && value.length > 0) {
      return true;
    } else if (typeof value === "string" && value !== "") {
      return true;
    } else if (typeof value === "boolean" && value) {
      return true;
    }
  }
  return false;
};

export const extractDefaultValues = (
  parameters: PropertyAttribute[] | null | undefined
) => {
  const initialValues: FormValuesType = {};
  if (!parameters) return;

  parameters.forEach((param) => {
    if (!param.name) return;
    // if param type is Date range
    if (param.type === "DATE_RANGE") {
      if (!param.defaultValue) {
        initialValues[param.name] = {
          start: null,
          end: null,
        };
        return;
      }
      // check if if the string contains number so is a not dynamic date just transform in object
      const obj = JSON.parse(param.defaultValue);
      if (/\d/.test(param.defaultValue)) {
        initialValues[param.name] = obj;
      } else {
        // if it does not contain number then is a dynamic date so change the keys
        initialValues[param.name] = {
          dynamicStartingDate: obj.start,
          dynamicEndingDate: obj.end,
        };
      }
    } else if (param.defaultValue !== null) {
      initialValues[param.name] = param.defaultValue;
    }
  });
  return initialValues;
};

const mapDataWithHeaders = (
  columns: TableColumnType[],
  data: RowType,
  isInventoryLog: boolean,
  isForecastTable: boolean
) => {
  const accessorToHeaderMap: { [key: string]: string } = {};

  columns.forEach((col) => {
    if (col.accessor && col.Header) {
      if (isForecastTable && col.accessor.startsWith("M")) {
        const childrenArray = col.Header.props.children.map(
          (child: any) => child.props.children
        );

        accessorToHeaderMap[col.accessor] = `${childrenArray.join(" ")}`;
      } else {
        accessorToHeaderMap[col.accessor] = col.Header as string;
      }
    }
  });

  return data.map((row) => {
    const newRow: FormValuesType = {};

    Object.keys(row).forEach((key) => {
      const newKey = accessorToHeaderMap[key] || key;

      if (isInventoryLog) {
        if (newKey !== "warehouse") {
          newRow[newKey] = row[key] as any;
        }
      } else if (isForecastTable && key.startsWith("periodResults.M")) {
        const periodKeyParts = key.split(".");
        const periodKeyIndex = periodKeyParts[2];

        if (periodTypeOptionsName[periodKeyIndex]) {
          const keyName = `${periodTypeOptionsName[periodKeyIndex]} ${
            accessorToHeaderMap[periodKeyParts[1]]
          }`;
          newRow[keyName] = row[key];
        }
      } else {
        const matchingColumn = columns.find((col) => col.accessor === key);

        if (matchingColumn) {
          newRow[newKey] = row[key];
        }
      }
    });

    return newRow;
  });
};

export const downloadExcel = (
  columns: TableColumnType[],
  data: RowType,
  name: string | undefined | null,
  isInventoryLog: boolean,
  isForecastTable: boolean
) => {
  const mappedData = mapDataWithHeaders(
    columns,
    data,
    isInventoryLog,
    isForecastTable
  );
  let transformedData;
  let ws;

  if (isInventoryLog) {
    transformedData = mappedData.map((row) => ({
      ...row,

      Batch: row.Batch?.bestBeforeDate,

      price: `${row.price?.amount ? row.price.amount : ""} ${
        row.price?.currencyCode ? row.price.currencyCode : ""
      }`,
    }));
    ws = XLSX.utils.json_to_sheet(transformedData);
  } else {
    ws = XLSX.utils.json_to_sheet(mappedData);
  }

  const wb = XLSX.utils.book_new();

  if (ws["!ref"]) {
    ws["!autofilter"] = { ref: ws["!ref"] };
  }

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const dataBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });

  saveAs(
    dataBlob,
    `${name?.replace(" ", "_")}${new Intl.DateTimeFormat("en-UK").format(
      new Date()
    )}.xlsx`
  );
};

export const downloadCSV = (
  columns: TableColumnType[],
  data: RowType,
  name: string | undefined | null,
  isInventoryLog: boolean,
  isForecastTable: boolean
) => {
  const mappedData = mapDataWithHeaders(
    columns,
    data,
    isInventoryLog,
    isForecastTable
  );
  let transformedData;

  if (isInventoryLog) {
    transformedData = mappedData.map((row) => ({
      ...row,

      Batch: row.Batch?.bestBeforeDate,

      Price: `${row.price?.amount ? row.price.amount : ""} ${
        row.price?.currencyCode ? row.price.currencyCode : ""
      }`,
    }));
  }

  const csv = Papa.unparse({
    fields: isForecastTable
      ? Object.keys(mappedData[0])
      : columns.map((col) => col.Header),
    data: transformedData ? transformedData : mappedData,
  });

  const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  saveAs(
    csvBlob,
    `${name?.replace(" ", "_")}${new Intl.DateTimeFormat("en-UK").format(
      new Date()
    )}.csv`
  );
};

export const handleInputChange = (
  setFormValues: (value: FormValuesType) => void,
  navigate: NavigateFunction | undefined,
  key: string,
  section: string | null,
  value:
    | string
    | number
    | { value: string }
    | { value: string }[]
    | string[]
    | boolean
    | undefined
    | DateType,
  isMulti: boolean = false,
  isText: boolean = false,
  shouldJoinValues: boolean = false
) => {
  const searchParams = new URLSearchParams(window.location.search);

  if (!value) {
    setFormValues((prev: FormValuesType) => {
      if (section) {
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [key]: null,
          },
        };
      } else {
        return {
          ...prev,
          [key]: null,
        };
      }
    });
    if (navigate) {
      const finalKey = section ? `${section}.${key}` : key;

      searchParams.delete(finalKey);
      const newUrl = `${window.location.pathname}?${searchParams
        .toString()
        .replace(/%2C/g, ",")}`;
      navigate(newUrl);
    }

    return;
  }

  const getValues = (input: typeof value) => {
    if (Array.isArray(input)) {
      return input.map((item) =>
        typeof item === "object" && "value" in item ? item.value : item
      );
    } else if (typeof input === "object" && "value" in input) {
      return [input.value];
    } else {
      return [input];
    }
  };

  const values = getValues(value);
  let updateValue:
    | string
    | number
    | true
    | Date
    | (string | number | true | Date)[];

  if (key === "vendor" || key === "contact" || key === "sku") {
    updateValue = [];
    for (let i = 0; i < values.length; i++) {
      updateValue.push(values[i]);
    }
  } else if (key === "start" || key === "end") {
  } else {
    updateValue = isText
      ? values.join(",")
      : isMulti
      ? shouldJoinValues
        ? values.join(",")
        : values
      : values[0];
  }

  setFormValues((prev: FormValuesType) => {
    if (section) {
      return {
        ...prev,
        [section]: {
          ...prev[section],
          [key]: updateValue,
        },
      };
    } else {
      return {
        ...prev,
        [key]: updateValue,
      };
    }
  });

  const finalKey = section ? `${section}.${key}` : key;

  if (isMulti && Array.isArray(value) && value.length > 1) {
    searchParams.set(finalKey, values.join(","));
  } else if (isText) {
    searchParams.set(finalKey, values.join(",").trim().replace(/\s+/g, ","));
  } else {
    if (values.length < 1) {
      searchParams.delete(finalKey);
    } else {
      searchParams.set(finalKey, values[0].toLocaleString() || "");
    }
  }
  if (navigate) {
    const newUrl = `${window.location.pathname}?${searchParams
      .toString()
      .replace(/%2C/g, ",")}`;
    navigate(newUrl);
  }
};

export const customSorting = (rowA: any, rowB: any, columnId: string) => {
  if (columnId.startsWith("M")) {
    columnId = `periodResults.${columnId}.qty`;
  }
  const valueA = rowA.original[`${columnId}`];
  const valueB = rowB.original[`${columnId}`];

  if (valueA === valueB) {
    return;
  }

  return valueA - valueB;
};

export const arrayToQueryString = (
  arr: FormValuesType[] | undefined
): string => {
  if (!arr) return "";
  return arr.map((item) => `${item.name}=${item.value}`).join("&");
};

export const dateSelectOption = [
  { value: "", label: "Select an option" },
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "tomorrow", label: "Tomorrow" },
  { value: "start-of-week", label: "Start of week" },
  { value: "end-of-week", label: "End of week" },
  { value: "start-of-month", label: "Start of month" },
  { value: "end-of-month", label: "End of month" },
  { value: "start-of-year", label: "Start of year" },
  { value: "end-of-year", label: "End of year" },
  { value: "previous-start-of-month", label: "Start of prev month" },
  { value: "previous-end-of-month", label: "End of prev month" },
  { value: "previous-start-of-year", label: "Start of prev year" },
  { value: "previous-end-of-year", label: "End of prev year" },
];

export const formValuesDefaults: FormValuesType = {
  sales: {
    contact: [],
    contactExclude: false,
    docTag: [],
    docTagExclude: [],
    contactTag: [],
    contactTagExclude: [],
    salesRep: [],
    salesRepExclude: false,
  },
  items: {
    brand: [],
    brandExclude: false,
    category: [],
    categoryExclude: false,
    department: [],
    departmentExclude: false,
    appCategory: [],
    appCategoryExclude: false,
    tag: [],
    tagExclude: [],
    vendor: [],
    vendorExclude: false,
    sku: "",
    skuExclude: false,
  },
};

export const dateRangeShortcuts = {
  shortcuts: {
    today: "Today",
    yesterday: "Yesterday",
    WTD: {
      text: "This Week (WTD)",
      period: {
        start: dayjs().startOf("week"),
        end: dayjs(),
      },
    },
    prevWeek: {
      text: "Previous Week",
      period: {
        start: dayjs().startOf("week").subtract(1, "week"),
        end: dayjs().endOf("week").subtract(1, "week"),
      },
    },
    MTD: {
      text: "This Month (MTD)",
      period: {
        start: dayjs().startOf("month"),
        end: dayjs(),
      },
    },
    prevMonth: {
      text: "Previous Month",
      period: {
        start: dayjs().startOf("month").subtract(1, "month"),
        end: dayjs().endOf("month").subtract(1, "month"),
      },
    },
    YTD: {
      text: "This Year (YTD)",
      period: {
        start: dayjs().startOf("year"),
        end: dayjs(),
      },
    },
    prevYear: {
      text: "Previous Year",
      period: {
        start: dayjs().startOf("year").subtract(1, "year"),
        end: dayjs().endOf("year").subtract(1, "year"),
      },
    },
    prevWTD: {
      text: "Previous WTD",
      period: {
        start: dayjs().startOf("week").subtract(1, "week"),
        end: dayjs().subtract(1, "week"),
      },
    },
    prevMTD: {
      text: "Previous MTD",
      period: {
        start: dayjs().startOf("month").subtract(1, "month"),
        end: dayjs().subtract(1, "month"),
      },
    },
    prevYTD: {
      text: "Previous YTD",
      period: {
        start: dayjs().startOf("year").subtract(1, "year"),
        end: dayjs().subtract(1, "year"),
      },
    },
  },
};

export const dateRanges = {
  today: [dayjs(), dayjs()],
  yesterday: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
  WTD: [dayjs().startOf("week"), dayjs()],
  prevWeek: [
    dayjs().startOf("week").subtract(1, "week"),
    dayjs().endOf("week").subtract(1, "week"),
  ],
  MTD: [dayjs().startOf("month"), dayjs()],
  prevMonth: [
    dayjs().startOf("month").subtract(1, "month"),
    dayjs().endOf("month").subtract(1, "month"),
  ],
  YTD: [dayjs().startOf("year"), dayjs()],
  prevYear: [
    dayjs().startOf("year").subtract(1, "year"),
    dayjs().endOf("year").subtract(1, "year"),
  ],
  prevWTD: [
    dayjs().startOf("week").subtract(1, "week"),
    dayjs().subtract(1, "week"),
  ],
  prevMTD: [
    dayjs().startOf("month").subtract(1, "month"),
    dayjs().subtract(1, "month"),
  ],
  prevYTD: [
    dayjs().startOf("year").subtract(1, "year"),
    dayjs().subtract(1, "year"),
  ],
};

export const dayOfWeekArray = [
  { value: "0", label: "Sunday" },
  { value: "1", label: "Monday" },
  { value: "2", label: "Tuesday" },
  { value: "3", label: "Wednesday" },
  { value: "4", label: "Thursday" },
  { value: "5", label: "Friday" },
  { value: "6", label: "Saturday" },
];

export const getDatePeriod = (
  periodType: string,
  setFormValues: (value: FormValuesType) => void,
  formValues: FormValuesType
) => {
  const today = dayjs();
  switch (periodType) {
    case "":
      setFormValues({
        ...formValues,
        date: {
          ...formValues.date,
          start: "",
          end: "",
        },
      });
      break;

    case "today":
      setFormValues({
        ...formValues,
        date: {
          dynamicEndingDate: "",
          dynamicStartingDate: "",
          start: today.format("YYYY-MM-DD"),
          end: today.format("YYYY-MM-DD"),
        },
      });
      break;
    case "yesterday":
      const yesterday = today.subtract(1, "day");
      setFormValues({
        ...formValues,
        date: {
          dynamicEndingDate: "",
          dynamicStartingDate: "",
          start: yesterday.format("YYYY-MM-DD"),
          end: yesterday.format("YYYY-MM-DD"),
        },
      });
      break;
    case "WTD":
      setFormValues({
        ...formValues,
        date: {
          dynamicEndingDate: "",
          dynamicStartingDate: "",
          start: today.startOf("week").format("YYYY-MM-DD"),
          end: today.format("YYYY-MM-DD"),
        },
      });
      break;
    case "prevWeek":
      const lastWeekStart = today.startOf("week").subtract(1, "week");
      const lastWeekEnd = today.endOf("week").subtract(1, "week");
      setFormValues({
        ...formValues,
        date: {
          dynamicEndingDate: "",
          dynamicStartingDate: "",
          start: lastWeekStart.format("YYYY-MM-DD"),
          end: lastWeekEnd.format("YYYY-MM-DD"),
        },
      });
      break;
    case "MTD":
      setFormValues({
        ...formValues,
        date: {
          dynamicEndingDate: "",
          dynamicStartingDate: "",
          start: today.startOf("month").format("YYYY-MM-DD"),
          end: today.format("YYYY-MM-DD"),
        },
      });
      break;
    case "prevMonth":
      const lastMonthStart = today.startOf("month").subtract(1, "month");
      const lastMonthEnd = today.endOf("month").subtract(1, "month");
      setFormValues({
        ...formValues,
        date: {
          ...formValues.date,

          start: lastMonthStart.format("YYYY-MM-DD"),
          end: lastMonthEnd.format("YYYY-MM-DD"),
        },
      });
      break;
    case "YTD":
      setFormValues({
        ...formValues,
        date: {
          dynamicEndingDate: "",
          dynamicStartingDate: "",
          start: today.startOf("year").format("YYYY-MM-DD"),
          end: today.format("YYYY-MM-DD"),
        },
      });
      break;
    case "prevYear":
      const lastYearStart = today.startOf("year").subtract(1, "year");
      const lastYearEnd = today.endOf("year").subtract(1, "year");
      setFormValues({
        ...formValues,
        date: {
          dynamicEndingDate: "",
          dynamicStartingDate: "",
          start: lastYearStart.format("YYYY-MM-DD"),
          end: lastYearEnd.format("YYYY-MM-DD"),
        },
      });
      break;
    case "prevWTD":
      const prevWTDStart = today.startOf("week").subtract(1, "week");
      const prevWTDEnd = today.subtract(1, "week");
      setFormValues({
        ...formValues,
        date: {
          dynamicEndingDate: "",
          dynamicStartingDate: "",
          start: prevWTDStart.format("YYYY-MM-DD"),
          end: prevWTDEnd.format("YYYY-MM-DD"),
        },
      });
      break;
    case "prevMTD":
      const prevMTDStart = today.startOf("month").subtract(1, "month");
      const prevMTDEnd = today.subtract(1, "month");
      setFormValues({
        ...formValues,
        date: {
          dynamicEndingDate: "",
          dynamicStartingDate: "",
          start: prevMTDStart.format("YYYY-MM-DD"),
          end: prevMTDEnd.format("YYYY-MM-DD"),
        },
      });
      break;
    case "prevYTD":
      const prevYTDStart = today.startOf("year").subtract(1, "year");
      const prevYTDEnd = today.subtract(1, "year");
      setFormValues({
        ...formValues,
        date: {
          dynamicEndingDate: "",
          dynamicStartingDate: "",
          start: prevYTDStart.format("YYYY-MM-DD"),
          end: prevYTDEnd.format("YYYY-MM-DD"),
        },
      });
      break;
    default:
      return null;
  }
};

export const drillDownTypes: {
  [key: string]:
    | "INBOUND"
    | "OUTBOUND"
    | "AGING"
    | "UNINVOICED"
    | "TRN"
    | "POB2B"
    | "PODD"
    | "BLOCKED";
} = {
  onOrder: "INBOUND",
  reserved: "OUTBOUND",
  aging: "AGING",
  uninvoiced: "UNINVOICED",
  inTransit: "TRN",
  backToBack: "POB2B",
  podd: "PODD", //todo
  blocked: "BLOCKED",
};
export const inventoryTypeOptions: { [key: string]: string } = {
  onHand: "OH",
  available: "AV",
  onOrder: "IN",
  inTransit: "TRN",
};

export const periodTypeOptions: { [key: string]: string } = {
  openingStock: "O",
  closingStock: "C",
  daysOtOfStock: "OOS",
  inTransit: "TRN",
};

export const periodTypeOptionsName: { [key: string]: string } = {
  qty: "Quantity",
  promoQty: "Promo quantity",
  openingStock: "Opening stock",
  closingStock: "Closing stock",
  daysOtOfStock: "Days out Of Stock",
  inTransit: "In transport",
};

export const itemTypeOption: { [key: string]: string } = {
  STOCK_ITEM: "Stock Item",
  SERVICE_ITEM: "Service Item",
  KIT_ITEM: "Kit Item",
  BOM_ITEM: "Bom Item",
  GROUP_ITEM: "Group Item",
};
export const handleColumnRounded = (
  index: number,
  lastIndex: number
): string => {
  if (index === 0) return "rounded-s-md";
  if (index === lastIndex - 1) return "rounded-e-md";
  return "";
};
