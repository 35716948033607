import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { ScheduleReport } from "./ScheduleReport";
import { ReportScheduleInfo } from "../../api-client";
import { ScheduleTableType } from "../../utils/columns";

export const EditSchedule = ({
  schedule,
  getSchedules,
}: {
  schedule: ScheduleTableType
  getSchedules: () => void;
}) => {
  const [showEditSchedule, setShowEditSchedule] = useState<boolean>(false);

  return (
    <>
      {showEditSchedule && (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-[100]">
          <div className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-gray-700 z-[100]">
            <ScheduleReport
              schedule={schedule as ReportScheduleInfo}
              setShowScheduleModal={setShowEditSchedule}
              getSchedules={getSchedules}
            />
          </div>
        </div>
      )}
      <div className="flex justify-center">
        <MdEdit
          className="text-green-500 hover:text-green-700 cursor-pointer"
          size={20}
          onClick={() => setShowEditSchedule(true)}
        />
      </div>
    </>
  );
};
