import type {
  InputsComponentsProps,
  DefaultOptionsType,
  ValueLabelNumberType,
  ValueLabelStringType,
} from "../../utils/type";
import {
  debouncedLoadContactOptions,
  getValueInput,
  getContactOption,
  isAnythingSelected,
  handleInputChange,
  delayedState,
} from "../../utils/functions";
import { ControlProps } from "react-select";
import { FC, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { IoIosArrowDown } from "react-icons/io/index.js";
import { useNavigate, useLocation } from "react-router-dom";
import CustomSelect from "../reportInputsHelper/CustomSelect";
import { useInputValuesStore } from "../../utils/store/inputValuesStore";
import { CustomContainer } from "../reportInputsHelper/CustomContainer";
import { CustomSelectedValue } from "../reportInputsHelper/CustomSelectedValue";

export const SalesInput: FC<InputsComponentsProps> = ({
  setFormValues,
  formValues,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const [showSales, setShowSales] = useState<boolean>(false);

  const valuesName = searchParams.get("sales.contact")?.split(",");
  const [defaultOptionsArray, setDefaultOptionsArray] = useState<
    DefaultOptionsType[]
  >([]);
  const [loadingContact, setLoadingContact] = useState<boolean>(false);

  const [overflowDelayed, setOverflowDelayed] = useState<boolean>(false);
  const { inputValues } = useInputValuesStore();

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    delayedState(showSales, setOverflowDelayed, timeoutId, setTimeoutId);
  }, [showSales]);

  const getContacts = async () => {
    setLoadingContact(true);

    const defaultOptions = await getContactOption(valuesName, "contact");
    setDefaultOptionsArray(defaultOptions);
    setLoadingContact(false);
    if (isAnythingSelected(formValues.sales)) {
      setShowSales(true);
    }
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <div className="p-4 px-1 xs:px-4">
      <button
        onClick={() => setShowSales(!showSales)}
        className="flex gap-2 items-center w-full"
      >
        <h2 className="text-lg text-gray-400 dark:text-gray-300">
          Filter by Sales
        </h2>
        <div
          className={`${
            showSales ? "rotate-0" : "-rotate-90"
          } transition-all duration-200 justify-self-end text-[#94a3b8] dark:text-[#cbd5e1]`}
        >
          <IoIosArrowDown />
        </div>
      </button>
      <div
        className={`transition-all duration-300 ${
          !overflowDelayed && "overflow-hidden"
        } ${
          showSales
            ? "max-h-[250px] md:max-h-[120px] xl:max-h-[80px]"
            : "max-h-0"
        }`}
      >
        <div className="flex flex-wrap gap-5 justify-center sm:justify-start mt-5">
          {!loadingContact && (
            <CustomSelect
              id="contact"
              isExcluded={formValues?.sales?.contactExclude}
              label={"Contact"}
              loadOptions={(value: string) =>
                debouncedLoadContactOptions(value)
              }
              isMulti={true}
              value={
                Array.isArray(formValues.sales?.contact)
                  ? formValues.sales?.contact.map((contact: string) => ({
                      value: contact,
                      label: contact,
                    }))
                  : formValues.sales?.contact
                  ? {
                      value: formValues.sales?.contact,
                      label: formValues.sales?.contact,
                    }
                  : undefined
              }
              defaultValue={defaultOptionsArray
                ?.filter((option) => option?.fieldName === "contact")
                .map((option) => ({
                  value: option?.value,
                  label: option?.label,
                }))}
              components={{
                Control: ({ children, ...rest }: ControlProps) => (
                  <CustomContainer
                    rest={rest}
                    children={children}
                    setFormValues={setFormValues}
                    formValues={formValues}
                    section={"sales"}
                    keyToUpdate={"contact"}
                  />
                ),
              }}
              onChange={(e: ValueLabelStringType) => {
                handleInputChange(
                  setFormValues,
                  navigate,
                  "contact",
                  "sales",
                  e,
                  true,
                  undefined,
                  true
                );
              }}
            />
          )}

          <CustomSelect
            label="Documents Tag"
            id="documentTags"
            isMulti={true}
            value={getValueInput(
              "sales.docTag",
              "docTags",
              formValues,
              inputValues,
              "tagId"
            )}
            components={{
              MultiValueLabel: ({ data }: { data: ValueLabelNumberType }) => (
                <CustomSelectedValue
                  data={data}
                  handleInputChange={handleInputChange}
                  setFormValues={setFormValues}
                  tag={formValues.sales.docTag}
                  formValues={formValues}
                  itemType={"sales"}
                  tagType={"docTag"}
                />
              ),
            }}
            options={
              inputValues
                ? inputValues.docTags.map((tag) => {
                    return { label: tag.tagName, value: tag.tagId };
                  })
                : []
            }
            onChange={(e: ValueLabelStringType[]) => {
              const selectedValues = e.map((a) => a.value);
              const updatedTagExclude: string[] = [];
              const updatedTag: string[] = [];
              for (const value of selectedValues) {
                if (!formValues?.sales?.docTagExclude.includes(value)) {
                  updatedTag.push(value);
                } else if (!formValues?.sales?.docTag.includes(value)) {
                  updatedTagExclude.push(value);
                }
              }
              handleInputChange(
                setFormValues,
                navigate,
                "docTag",
                "sales",
                updatedTag,
                true
              );
              handleInputChange(
                setFormValues,
                navigate,
                "docTagExclude",
                "sales",
                updatedTagExclude,
                true
              );
            }}
          />
          <CustomSelect
            label="Contacts Tag"
            id="contactTags"
            isMulti={true}
            value={getValueInput(
              "sales.contactTag",
              "contactTags",
              formValues,
              inputValues,
              "id"
            )}
            components={{
              MultiValueLabel: ({ data }: { data: ValueLabelNumberType }) => (
                <CustomSelectedValue
                  data={data}
                  handleInputChange={handleInputChange}
                  setFormValues={setFormValues}
                  tag={formValues.sales.contactTag}
                  formValues={formValues}
                  itemType={"sales"}
                  tagType={"contactTag"}
                />
              ),
            }}
            options={
              inputValues
                ? inputValues.contactTags.map((tag) => {
                    return { label: tag.name, value: tag.id };
                  })
                : []
            }
            onChange={(e: ValueLabelStringType[]) => {
              const selectedValues = e.map((a) => a.value);
              const updatedTagExclude: string[] = [];
              const updatedTag: string[] = [];
              for (const value of selectedValues) {
                if (!formValues?.sales?.contactTagExclude.includes(value)) {
                  updatedTag.push(value);
                } else if (!formValues?.sales?.contactTag.includes(value)) {
                  updatedTagExclude.push(value);
                }
              }
              handleInputChange(
                setFormValues,
                navigate,
                "contactTag",
                "sales",
                updatedTag,
                true
              );
              handleInputChange(
                setFormValues,
                navigate,
                "contactTagExclude",
                "sales",
                updatedTagExclude,
                true
              );
            }}
          />
          <CustomSelect
            isExcluded={formValues?.sales?.salesRepExclude}
            label="Sales Rep"
            id="salesRep"
            isMulti={true}
            value={getValueInput(
              "sales.salesRep",
              "salesReps",
              formValues,
              inputValues,
              "id"
            )}
            onChange={(e: ValueLabelStringType[]) => {
              handleInputChange(
                setFormValues,
                navigate,
                "salesRep",
                "sales",
                e.map((a) => a.value),
                true
              );
            }}
            components={{
              Control: ({ children, ...rest }: ControlProps) => (
                <CustomContainer
                  rest={rest}
                  children={children}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  section={"sales"}
                  keyToUpdate={"salesRep"}
                />
              ),
            }}
            options={
              inputValues
                ? inputValues.salesReps.map((salesRep) => {
                    return {
                      label: salesRep.name,
                      value: salesRep.id,
                    };
                  })
                : []
            }
          />
        </div>
      </div>
    </div>
  );
};
