import type {
  InputsComponentsProps,
  DefaultOptionsType,
  ValueLabelStringType,
  ValueLabelNumberType,
} from "../../utils/type";
import {
  debouncedLoadContactOptions,
  getValueInput,
  getContactOption,
  debouncedLoadItemOptions,
  isAnythingSelected,
  handleInputChange,
  delayedState,
} from "../../utils/functions";
import { ControlProps } from "react-select";
import { FC, useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io/index.js";
import { useNavigate, useLocation } from "react-router-dom";
import {CustomSelect} from "../reportInputsHelper/CustomSelect";
import { useInputValuesStore } from "../../utils/store/inputValuesStore";
import { CustomContainer } from "../reportInputsHelper/CustomContainer";
import { CustomSelectedValue } from "../reportInputsHelper/CustomSelectedValue";

export const ItemsInput: FC<InputsComponentsProps> = ({
  setFormValues,
  formValues,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const [showItems, setShowItems] = useState<boolean>(false);
  const valuesName = searchParams.get("items.vendor")?.split(",");
  const [defaultOptionsArray, setDefaultOptionsArray] = useState<
    DefaultOptionsType[]
  >([]);
  const [loadingContact, setLoadingContact] = useState<boolean>(false);

  const [overflowDelayed, setOverflowDelayed] = useState<boolean>(false);

  const { inputValues } = useInputValuesStore();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    delayedState(showItems, setOverflowDelayed, timeoutId, setTimeoutId);
  }, [showItems]);

  const getContacts = async () => {
    setLoadingContact(true);

    const defaultOptions = await getContactOption(valuesName, "vendor");
    setDefaultOptionsArray(defaultOptions);
    setLoadingContact(false);

    if (isAnythingSelected(formValues.items)) {
      setShowItems(true);
    }
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <div className="p-4 px-1 xs:px-4">
      <button
        onClick={() => setShowItems(!showItems)}
        className="flex gap-2 items-center w-full"
      >
        <h2 className="text-lg text-gray-400 dark:text-gray-300">
          Filter by Items
        </h2>
        <div
          className={`${
            showItems ? "rotate-0" : "-rotate-90"
          } transition-all duration-200 justify-self-end text-[#94a3b8] dark:text-[#cbd5e1]`}
        >
          <IoIosArrowDown />
        </div>
      </button>
      <div
        className={`transition-all duration-500 ${
          !overflowDelayed && "overflow-hidden"
        } ${
          showItems
            ? "max-h-[420px] md:max-h-[250px] xl:max-h-[150px]"
            : "max-h-0"
        }`}
      >
        <div className="flex flex-wrap gap-5 mt-5 justify-center sm:justify-start">
          <CustomSelect
            id="sku"
            isExcluded={formValues?.items?.skuExclude}
            label={"Sku"}
            loadOptions={(value: string) => debouncedLoadItemOptions(value)}
            isMulti={true}
            value={
              Array.isArray(formValues.items?.sku)
                ? formValues.items?.sku.map((sku: string) => ({
                    value: sku,
                    label: sku,
                  }))
                : formValues.items?.sku
                ? { value: formValues.items.sku, label: formValues.items.sku }
                : undefined
            }
            defaultValue={
              Array.isArray(formValues.items?.sku)
                ? formValues.items?.sku.map((sku: string) => ({
                    value: sku,
                    label: sku,
                  }))
                : formValues.items?.sku
                ? { value: formValues.items.sku, label: formValues.items.sku }
                : undefined
            }
            components={{
              Control: ({ children, ...rest }: ControlProps) => (
                <CustomContainer
                  rest={rest}
                  children={children}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  section={"items"}
                  keyToUpdate={"sku"}
                />
              ),
            }}
            onChange={(e: string) => {
              handleInputChange(
                setFormValues,
                navigate,
                "sku",
                "items",
                e,
                true,
                undefined,
                true
              );
            }}
          />
          <CustomSelect
            label="Brand"
            id="itemBrands"
            isExcluded={formValues?.items?.brandExclude}
            isMulti={true}
            value={getValueInput(
              "items.brand",
              "itemBrands",
              formValues,
              inputValues,
              "id"
            )}
            onChange={(e: ValueLabelStringType[]) => {
              handleInputChange(
                setFormValues,
                navigate,
                "brand",
                "items",
                e.map((a) => a.value),
                true
              );
            }}
            components={{
              Control: ({ children, ...rest }: ControlProps) => (
                <CustomContainer
                  rest={rest}
                  children={children}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  section={"items"}
                  keyToUpdate={"brand"}
                />
              ),
            }}
            options={
              inputValues
                ? inputValues.itemBrands.map((itemBrand) => {
                    return {
                      label: itemBrand.name,
                      value: itemBrand.id,
                    };
                  })
                : []
            }
          />
          <CustomSelect
            label="Categories"
            isExcluded={formValues?.items?.categoryExclude}
            id="itemCategories"
            isMulti={true}
            value={getValueInput(
              "items.category",
              "itemCategories",
              formValues,
              inputValues,
              "id"
            )}
            onChange={(e: ValueLabelStringType[]) => {
              handleInputChange(
                setFormValues,
                navigate,
                "category",
                "items",
                e.map((a) => a.value),
                true
              );
            }}
            components={{
              Control: ({ children, ...rest }: ControlProps) => (
                <CustomContainer
                  rest={rest}
                  children={children}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  section={"items"}
                  keyToUpdate={"category"}
                />
              ),
            }}
            options={
              inputValues
                ? inputValues.itemCategories.map((category) => {
                    return {
                      label: category.name,
                      value: category.id,
                    };
                  })
                : []
            }
          />
          <CustomSelect
            label="Department"
            id="department"
            isExcluded={formValues?.items?.departmentExclude}
            isMulti={true}
            value={getValueInput(
              "items.department",
              "itemDepartments",
              formValues,
              inputValues,
              "id"
            )}
            onChange={(e: ValueLabelStringType[]) => {
              handleInputChange(
                setFormValues,
                navigate,
                "department",
                "items",
                e.map((a) => a.value),
                true
              );
            }}
            components={{
              Control: ({ children, ...rest }: ControlProps) => (
                <CustomContainer
                  rest={rest}
                  children={children}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  section={"items"}
                  keyToUpdate={"department"}
                />
              ),
            }}
            options={
              inputValues
                ? inputValues.itemDepartments.map((department) => {
                    return {
                      label: department.name,
                      value: department.id,
                    };
                  })
                : []
            }
          />
          <CustomSelect
            label="App Categories"
            id="itemAppCategories"
            isExcluded={formValues?.items?.appCategoryExclude}
            isMulti={true}
            value={getValueInput(
              "items.appCategory",
              "itemAppCategories",
              formValues,
              inputValues,
              "id"
            )}
            onChange={(e: ValueLabelStringType[]) => {
              handleInputChange(
                setFormValues,
                navigate,
                "appCategory",
                "items",
                e.map((a) => a.value),
                true
              );
            }}
            components={{
              Control: ({ children, ...rest }: ControlProps) => (
                <CustomContainer
                  rest={rest}
                  children={children}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  section={"items"}
                  keyToUpdate={"appCategory"}
                />
              ),
            }}
            options={
              inputValues
                ? inputValues.itemAppCategories.map((category) => {
                    return {
                      label: category.name,
                      value: category.id,
                    };
                  })
                : []
            }
          />
          <CustomSelect
            label="Items Tag"
            id="itemTags2"
            isMulti={true}
            value={getValueInput(
              "items.tag",
              "itemTags",
              formValues,
              inputValues,
              "id"
            )}
            onChange={(e: ValueLabelStringType[]) => {
              const selectedValues = e.map((a) => a.value);

              const updatedTagExclude: string[] = [];
              const updatedTag: string[] = [];
              for (const value of selectedValues) {
                if (!formValues?.items?.tagExclude?.includes(value)) {
                  updatedTag.push(value);
                } else if (!formValues?.items?.tag?.includes(value)) {
                  updatedTagExclude.push(value);
                }
              }
              handleInputChange(
                setFormValues,
                navigate,
                "tag",
                "items",
                updatedTag,
                true
              );
              handleInputChange(
                setFormValues,
                navigate,
                "tagExclude",
                "items",
                updatedTagExclude,
                true
              );
            }}
            components={{
              MultiValueLabel: ({ data }: { data: ValueLabelNumberType }) => (
                <CustomSelectedValue
                  data={data}
                  handleInputChange={handleInputChange}
                  setFormValues={setFormValues}
                  tag={formValues.items.tag}
                  formValues={formValues}
                  itemType={"items"}
                  tagType={"tag"}
                />
              ),
            }}
            options={
              inputValues
                ? inputValues.itemTags.map((itemTag) => {
                    return {
                      label: itemTag.name,
                      value: itemTag.id,
                    };
                  })
                : []
            }
          />
          {!loadingContact && (
            <CustomSelect
              id="contact"
              label={"Vendor"}
              isExcluded={formValues?.items?.vendorExclude}
              isClearable={false}
              isMulti={true}
              value={
                Array.isArray(formValues.items?.vendor)
                  ? formValues.items?.vendor.map((contact: string) => ({
                      value: contact,
                      label: contact,
                    }))
                  : formValues.items?.vendor
                  ? {
                      value: formValues.items?.vendor,
                      label: formValues.items?.vendor,
                    }
                  : undefined
              }
              defaultValue={defaultOptionsArray
                ?.filter((option) => option?.fieldName === "vendor")
                .map((option) => ({
                  value: option?.value,
                  label: option?.label,
                }))}
              loadOptions={(value: string) =>
                debouncedLoadContactOptions(value)
              }
              onChange={(e: ValueLabelStringType) => {
                handleInputChange(
                  setFormValues,
                  navigate,
                  "vendor",
                  "items",
                  e,
                  true,
                  undefined,
                  true
                );
              }}
              components={{
                Control: ({ children, ...rest }: ControlProps) => (
                  <CustomContainer
                    rest={rest}
                    children={children}
                    setFormValues={setFormValues}
                    formValues={formValues}
                    section={"items"}
                    keyToUpdate={"vendor"}
                  />
                ),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
