import { create } from "zustand";
import { InputValuesType } from "../type";

export interface InputValuesInterface {
  inputValues: InputValuesType | null;
  setInputValues: (value: InputValuesType | null) => void;
}

export const useInputValuesStore = create<InputValuesInterface>((set) => ({
  inputValues: null,
  setInputValues: (value: InputValuesType | null) =>
    set({ inputValues: value }),
}));
