import Select, {
  StylesConfig,
  CSSObjectWithLabel,
  GroupBase,
} from "react-select";
import { FC } from "react";
import AsyncSelect from "react-select/async";
import { CustomSelectType } from "../../utils/type";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";

export const CustomSelect: FC<CustomSelectType> = ({
  id,
  help,
  label,
  error,
  options,
  onChange,
  value = null,
  small = false,
  isMulti = false,
  required = false,
  components = null,
  isExcluded = false,
  loadOptions = null,
  isClearable = false,
  defaultValue = null,
  isSearchable = true,
  isTransparent = false,
}) => {
  const { darkMode } = useDarkModeStore();

  const selectStyles: StylesConfig<string, boolean, GroupBase<string>> = {
    container: (base) => ({
      ...base,
      width: "100%",
    }),
    control: (base) => ({
      ...base,
      boxShadow: "none",
      borderColor: "#cccccc",
      minHeight: "38px",
      backgroundColor: "transparent",
    }),
    valueContainer: (base) => ({
      ...base,
      paddingTop: "6px",
    }),
    input: (base: CSSObjectWithLabel) => ({
      ...base,
      ":focus": {
        border: "10px solid black",
      },
      outline: "0px",
      padding: "0px",
      margin: "0px",
      color: darkMode ? "#f1f5f9" : "black",
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: darkMode ? "#020617" : "#d4d4d8",
      borderRadius: "5px",
      color: darkMode ? "#f1f5f9" : "black",
    }),
    multiValueLabel: (base) => ({
      ...base,
      padding: "1px 2px",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: darkMode ? "#020617" : "#d4d4d8",
      color: darkMode ? "#f1f5f9" : "black",
    }),

    singleValue: (base) => ({
      ...base,
      textAlign: "left",
      color: darkMode ? "#f1f5f9" : "black",
    }),
    placeholder: (base) => ({
      ...base,
      textAlign: "left",
      color: darkMode ? "#cbd5e1" : "#475569",
    }),
    multiValueRemove: (base) => ({
      ...base,
      paddingRight: "2px",
      paddingLeft: "1px",
      backgroundColor: darkMode ? "#020617" : "#d4d4d8",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      color: darkMode ? "#f4f4f5" : "#374151",
      ":hover": {
        color: "red",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: "15",
      fontSize: "14px",
      textAlign: "left",
      color: darkMode ? "#f1f5f9" : "black",
      border: darkMode ? "0.5px solid white" : "",
      backgroundColor: darkMode ? "#121e2d" : "white",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused
        ? darkMode
          ? "#1e40af"
          : "#bfdbfe"
        : base.backgroundColor,

      ":hover": {
        backgroundColor: darkMode ? "#1e40af" : "#bfdbfe",
      },
    }),
    clearIndicator: (base) => ({
      ...base,
      paddingRight: "1px",
      paddingLeft: "0px",

      ":hover": {
        backgroundColor: "transparent",
        color: "red",
      },
      "dark:": {
        backgroundColor: "yellow",
        color: "black",
      },
    }),
  };

  return (
    <div className={`relative ${small ? "w-[80px]" : "w-[240px]"}`}>
      <div>
        {help && (
          <div className="absolute right-0 -top-3.5">
            <span data-tooltip-id="my-tooltip" data-tooltip-content={help}>
              <HiOutlineQuestionMarkCircle color="gray" size={14} />
            </span>
            <ReactTooltip id="my-tooltip" className="text-xs" />
          </div>
        )}
        {loadOptions ? (
          <AsyncSelect
            id={id}
            isMulti={isMulti}
            isSearchable={isSearchable}
            placeholder="Search..."
            isClearable={isClearable}
            required={required}
            menuPlacement={"auto"}
            className={`my-react-select-container transition-colors duration-500 shadow rounded ${
              isTransparent ? "bg-transparent" : " bg-white dark:bg-bgDarkColor"
            }`}
            classNamePrefix="my-react-select"
            onChange={onChange}
            styles={selectStyles}
            loadOptions={loadOptions}
            components={components}
            defaultValue={defaultValue}
            value={value}
            cacheOptions
          />
        ) : (
          <Select
            id={id}
            isMulti={isMulti}
            isSearchable={isSearchable}
            isClearable={isClearable}
            required={required}
            menuPlacement={"auto"}
            className={`my-react-select-container transition-colors duration-500 shadow rounded ${
              isTransparent ? "bg-transparent" : " bg-white dark:bg-bgDarkColor"
            }`}
            classNamePrefix="my-react-select"
            onChange={onChange}
            styles={selectStyles}
            options={options}
            components={components}
            defaultValue={defaultValue}
            value={value}
          />
        )}
        <label
          htmlFor={id}
          className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white ${
            isTransparent ? "dark:bg-[#2f343c]" : "dark:bg-bgDarkColor"
          } dark:text-blue-200 px-1 peer-focus:text-blue-600 left-1 transition-colors duration-500`}
        >
          {isExcluded && "Exclude"} {label}
        </label>
      </div>
      {error && (
        <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
          {error}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
