import dayjs from "dayjs";
import { DarkModeToggle } from "./Darkmode";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useUserInfoStore } from "../../utils/store/userStore";

dayjs.extend(weekOfYear);

export const Topbar = () => {
  const { userInfo } = useUserInfoStore();
  /*   const [inputValue, setInputValue] = useState("");
  const [currentTime, setCurrentTime] = useState(dayjs().format("HH:mm"));

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleInputFocus = () => {
    setInputValue("");
  }; 
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs().format("HH:mm"));
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);
*/
  return (
    <div
      className={`flex justify-between items-center right-3 top-2 w-full mb-4 pl-1`}
    >
      <div className="flex items-center gap-3">
        {/*    <div className="mx-auto max-w-md hidden xs:block">
        <form
          action=""
          className="relative mx-auto w-max bg-white dark:bg-black rounded-full "
        >
          <input
            type="search"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputFocus}
            className="dark:text-[#9da9bb] peer cursor-pointer relative z-10 h-8 w-8 rounded-full border shadow bg-transparent pl-[20px] outline-none focus:w-[120px] transition-width duration-500 transition-slowest ease xs:focus:w-[150px] sm:focus:w-[170px] focus:cursor-text focus:border-mainColor focus:pl-12 focus:pr-2"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="absolute inset-y-0 my-auto h-8 w-11 border-r border-transparent rounded-full dark:stroke-[#9da9bb] stroke-slate-500 pl-2 pr-4 peer-focus:border-mainColor"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </form>
      </div> 
      <div className="h-12 hidden md:flex p-1 bg-white dark:bg-black dark:text-gray-300 border-[1px] rounded-md shadow flex-col justify-between">
        <p className="text-sm">{dayjs().format("DD/MM/YYYY")}</p>
        <div className="flex justify-between  text-xs items-center">
          <p>W/{dayjs().week()}</p>
          <p>{currentTime}</p>
        </div>
      </div>*/}
        {userInfo ? (
          <div className="overflow-hidden">
            <p className="text-sm font-bold dark:text-gray-200">
              {userInfo?.name}
            </p>
            <p className="text-xs text-gray-600 dark:text-gray-400">
              {userInfo?.organization?.name}
            </p>
          </div>
        ) : (
          <div role="status" className="space-y-2.5 animate-pulse">
            <div className="h-12 w-16 bg-gray-200 rounded-md dark:bg-gray-700" />
          </div>
        )}
        {userInfo ? (
          <img
            alt="logo"
            className="h-12 rounded-lg border-[1px] shadow-md dark:shadow-slate-600"
            src={userInfo?.organization?.logo || ""}
          />
        ) : (
          <div role="status" className="space-y-2.5 animate-pulse">
            <div className="h-12 w-20 bg-gray-200 rounded-md dark:bg-gray-700" />
          </div>
        )}
        <DarkModeToggle />
      </div>
      <img alt="logo" className="h-12" src={"/logo-tradepeg.png"} />
    </div>
  );
};
