import type {
  DatepickerType,
  DateValueType,
} from "react-tailwindcss-datepicker";
import { useState, useEffect } from "react";
import { CardStat } from "../insights/CardStats";
import { LineChart } from "../insights/LineChart";
import { CardItems } from "../insights/CardItems";
import { handleError } from "../../utils/functions";
import Datepicker from "react-tailwindcss-datepicker";
import type { DashboardState } from "../../utils/type";
import { BarItemChart } from "../insights/BarItemChart";
import { useUserInfoStore } from "../../utils/store/userStore";
import { BarVerticalChart } from "../insights/BarVerticalChart";
import { ReportsService, SalesOverviewSegmentDate } from "../../api-client";

type DatesType = string | Date | null | undefined;

export const Insights = () => {
  const [dateRange, setDateRange] = useState<
    "dtd" | "wtd" | "ytd" | "mtd" | "qtd" | null
  >("dtd");
  const [dateValue, setDateValue] = useState<DateValueType>({
    startDate: "",
    endDate: "",
  });
  const { userInfo } = useUserInfoStore();

  const handleDate = (e: any) => {
    setDateRange(e.target.value);
    setDashboardState((prev) => ({
      ...prev,
      customDateStart: "",
      customDateEnd: "",
    }));
    setDateValue({
      startDate: null,
      endDate: null,
    });
  };

  const handleCustomDate: DatepickerType["onChange"] = (newValue) => {
    if (!newValue?.startDate || !newValue.endDate) {
      setDateValue({
        startDate: dashboardState.customDateStart!,
        endDate: dashboardState.customDateEnd!,
      });
      setDateRange(null);
      return;
    }
    setDateValue(newValue);

    let startDate = newValue?.startDate ? newValue.startDate.toString() : "";
    let endDate = newValue?.endDate ? newValue.endDate.toString() : "";

    setDashboardState((prev) => ({
      ...prev,
      customDateStart: startDate,
      customDateEnd: endDate,
    }));
    setDateRange(null);
  };

  const [dashboardState, setDashboardState] = useState<DashboardState>({
    customDate: false,
    loadingError: false,
    itemsPerformance: null,
    performanceByDate: null,
    channelsPerformance: null,
    salesPerformanceByDate: null,
    grossPerformanceByDate: null,
    soldItemPerformanceByDate: null,
    orderCountPerformanceByDate: null,
    marketplacesPerformance: null,
    customersPerformance: null,
    brandPerformance: null,
    categoryPerformance: null,
    departmentPerformance: null,
    salesByDate: null,
    dateStart: "",
    dateEnd: "",
    dateStartFormatted: "",
    dateEndFormatted: "",
    customDateStart: "",
    dateRange: "",
    customDateEnd: "",
  });

  const formatDateUi = (date: DatesType) => {
    if (!date) return "";

    let dateObj: Date;

    if (typeof date === "string") {
      dateObj = new Date(date);
      if (isNaN(dateObj.getTime())) return "";
    } else {
      dateObj = date;
    }

    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  };

  const getItems = async (dateStart: DatesType, dateEnd: DatesType) => {
    try {
      const data = await ReportsService.getReportsSalesOverviewPerformanceItems(
        {
          query: {
            excludeServices: true,
            start: dateStart as unknown as Date,
            end: dateEnd as unknown as Date,
          },
        }
      );

      setDashboardState((prevState) => ({
        ...prevState,
        itemsPerformance: data.data?.results,
      }));
    } catch (error) {}
  };

  const getDaily = async (dateStart: DatesType, dateEnd: DatesType) => {
    try {
      const data = await ReportsService.getReportsSalesOverviewPerformanceDaily(
        {
          query: {
            start: dateStart as unknown as Date,
            end: dateEnd as unknown as Date,
            excludeServices: true,
          },
        }
      );

      var _performanceByDate: SalesOverviewSegmentDate[] | null | undefined;
      _performanceByDate = data.data?.results;
      if (!_performanceByDate) return;
      const sales = _performanceByDate.map(({ dateName, salesValueGross }) => ({
        dateName,
        salesValueGross,
      }));
      const gross = _performanceByDate.map(({ dateName, grossProfit }) => ({
        dateName,
        grossProfit,
      }));
      const itemsSold = _performanceByDate.map(({ dateName, itemsSold }) => ({
        dateName,
        itemsSold,
      }));
      const orders = _performanceByDate.map(({ dateName, orders }) => ({
        dateName,
        orders,
      }));

      // Update the state with the new data
      setDashboardState((prevState) => ({
        ...prevState,
        salesPerformanceByDate: sales,
        grossPerformanceByDate: gross,
        soldItemPerformanceByDate: itemsSold,
        orderCountPerformanceByDate: orders,
        performanceByDate: _performanceByDate,
      }));
    } catch (error: any) {
      handleError(error.message);
    }
  };

  const getCategories = async (dateStart: DatesType, dateEnd: DatesType) => {
    try {
      const response =
        await ReportsService.getReportsSalesOverviewPerformanceSection({
          path: { section: "category" },
          query: {
            start: dateStart as unknown as Date,
            end: dateEnd as unknown as Date,
            excludeServices: true,
          },
        });

      const categories = response.data?.results;
      setDashboardState((prevState) => ({
        ...prevState,
        categoryPerformance: categories,
      }));
    } catch (error: any) {
      handleError(error.message);
    }
  };

  const getBrands = async (dateStart: DatesType, dateEnd: DatesType) => {
    try {
      const response =
        await ReportsService.getReportsSalesOverviewPerformanceSection({
          path: { section: "brand" },
          query: {
            start: dateStart as unknown as Date,
            end: dateEnd as unknown as Date,
            excludeServices: true,
          },
        });

      const brands = response.data?.results;
      setDashboardState((prevState) => ({
        ...prevState,
        brandPerformance: brands,
      }));
    } catch (error: any) {
      handleError(error.message);
    }
  };

  const getDepartment = async (dateStart: DatesType, dateEnd: DatesType) => {
    try {
      const response =
        await ReportsService.getReportsSalesOverviewPerformanceSection({
          path: { section: "department" },
          query: {
            start: dateStart as unknown as Date,
            end: dateEnd as unknown as Date,
            excludeServices: true,
          },
        });

      const departments = response.data?.results;
      setDashboardState((prevState) => ({
        ...prevState,
        departmentPerformance: departments,
      }));
    } catch (error: any) {
      handleError(error.message);
    }
  };

  const getChannels = async (dateStart: DatesType, dateEnd: DatesType) => {
    try {
      const response =
        await ReportsService.getReportsSalesOverviewPerformanceSection({
          path: { section: "channel" },
          query: {
            start: dateStart as unknown as Date,
            end: dateEnd as unknown as Date,
            excludeServices: true,
          },
        });

      const channels = response.data?.results;
      setDashboardState((prevState) => ({
        ...prevState,
        channelsPerformance: channels,
      }));
    } catch (error: any) {
      handleError(error.message);
    }
  };

  const getCustomers = async (dateStart: DatesType, dateEnd: DatesType) => {
    try {
      const response =
        await ReportsService.getReportsSalesOverviewPerformanceSection({
          path: { section: "customer" },
          query: {
            start: dateStart as unknown as Date,
            end: dateEnd as unknown as Date,
            excludeServices: true,
          },
        });

      const customers = response.data?.results;
      setDashboardState((prevState) => ({
        ...prevState,
        customersPerformance: customers,
      }));
    } catch (error: any) {
      handleError(error.message);
    }
  };

  const fetchDataSummary: () => Promise<void> = async () => {
    setDashboardState((prevState) => ({
      ...prevState,
      customDate: false,
      loadingError: false,
      salesByDate: null,
      orderCountPerformanceByDate: null,
      salesPerformanceByDate: null,
      grossPerformanceByDate: null,
      soldItemPerformanceByDate: null,
      itemsPerformance: null,
      brandPerformance: null,
      categoryPerformance: null,
      departmentPerformance: null,
    }));

    try {
      let dateStart: string | Date | null | undefined = null;
      let dateEnd: string | Date | null | undefined = null;
      let resSalesByDate: any;
      if (dateRange) {
        resSalesByDate =
          await ReportsService.getReportsSalesOverviewSummaryRange({
            path: { range: dateRange },
          });
        dateStart = resSalesByDate?.data?.dateRanges?.range1?.start;
        dateEnd = resSalesByDate?.data?.dateRanges?.range1?.end;
      } else {
        resSalesByDate = await ReportsService.getReportsSalesOverviewSummary({
          query: {
            start: dashboardState.customDateStart as unknown as Date,
            end: dashboardState.customDateEnd as unknown as Date,
            excludeServices: true,
          },
        });
        dateStart = dashboardState.dateStart;
        dateEnd = dashboardState.dateEnd;
      }
      dateStart = dateStart ? new Date(dateStart).toISOString() : undefined;
      dateEnd = dateEnd ? new Date(dateEnd).toISOString() : undefined;

      setDashboardState((prevState) => ({
        ...prevState,
        salesByDate: resSalesByDate.data,
        dateStart,
        dateEnd,
        dateStartFormatted: formatDateUi(dateStart),
        dateEndFormatted: formatDateUi(dateEnd),
      }));

      await Promise.all([
        getBrands(dateStart, dateEnd),
        getCategories(dateStart, dateEnd),
        getDepartment(dateStart, dateEnd),
        getCustomers(dateStart, dateEnd),
        getChannels(dateStart, dateEnd),
        getDaily(dateStart, dateEnd),
        getItems(dateStart, dateEnd),
      ]);
    } catch (error: any) {
      setDashboardState((prevState) => ({
        ...prevState,
        loadingError: true,
      }));

      handleError(error.message);
    }
  };
  useEffect(() => {
    fetchDataSummary();
  }, [dateRange, dateValue]);

  return (
    <div className="w-full ">
      {dashboardState.customDate && (
        <div className="fixed w-[100vw] h-[100vh] bg-black bg-opacity-50 top-0 left-0 z-[11]">
          <div className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 bg-white rounded-sm px-1 md:px-4 pt-4"></div>
        </div>
      )}
      <nav className="flex flex-col lg:flex-row justify-between md:pr-4 md:pb-2 mt-0 pt-2">
        <h1 className="text-[40px] text-gray-600 dark:text-gray-200 -mt-5">
          Insights
        </h1>
        <div className="flex self-center md:self-start bg-white shadow px-2 pb-1 pt-4 my-4 lg:mt-0 flex-col md:flex-row gap-2 items-start dark:bg-transparent transition-colors duration-500 rounded-md">
          <div className="relative w-[220px] mb-2 lg:mb-0 shadow-md hover:shadow-md rounded">
            <Datepicker
              showShortcuts={true}
              startWeekOn="mon"
              useRange={false}
              separator={"to"}
              displayFormat={"DD/MM/YY"}
              placeholder="dd/mm/yy ~ dd/mm/yy"
              showFooter={true}
              inputClassName="text-sm w-[220px] h-[38px] pl-1 transition-colors duration-500 rounded placeholder-[#9da9bb] dark:text-white w-full border-gray-300 border-[1px] rounded-[4px] bg-white dark:bg-[#0b1727]"
              configs={{
                footer: {
                  cancel: "Cancel",
                  apply: "Confirm",
                },
              }}
              value={dateValue}
              onChange={handleCustomDate}
            />
            <label
              htmlFor="countries"
              className="absolute text-gray-700 dark:text-gray-200 transition-colors duration-500 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-[#0b1727] px-1.5 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1.5"
            >
              Custom date range
            </label>
          </div>
          <div>
            <div className="relative">
              <select
                value={dateRange as string}
                onChange={handleDate}
                disabled={false}
                id="countries"
                className="w-[220px] h-[38px] bg-white dark:bg-[#0b1727] border border-gray-300 text-gray-900 transition-colors duration-500 text-sm rounded shadow-md hover:shadow-md block px-2.5 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option disabled value="">
                  Select a date
                </option>
                <option value="dtd">Day to date</option>
                <option value="wtd">Week to date</option>
                <option value="mtd">Month to date</option>
                <option value="qtd">Quarter to date</option>
                <option value="ytd">Year to date</option>
              </select>
              <label
                htmlFor="countries"
                className="absolute text-gray-700 dark:text-gray-200 transition-colors duration-500 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] bg-white dark:bg-[#0b1727] px-1.5 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1.5"
              >
                Date range
              </label>
            </div>
            {dateRange ? (
              <div className="flex items-center justify-between w-[140px] m-auto mt-1 dark:text-gray-400 text-gray-500 text-[13px]">
                <p className="mb-0">
                  {dashboardState.dateStartFormatted as string}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 text-gray-600 dark:text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
                <p className="mb-0">
                  {dashboardState.dateEndFormatted as string}
                </p>
              </div>
            ) : (
              <p className="h-[24px]"></p>
            )}
          </div>
        </div>
      </nav>
      <div className="relative">
        <div className="mx-auto w-full">
          <div>
            <div className="flex flex-wrap">
              <div className="w-6/12 xl:w-3/12 px-1 md:pr-3">
                <CardStat
                  statTitle={"Sales Value"}
                  result={
                    dashboardState.salesByDate
                      ? dashboardState.salesByDate?.results?.salesValueGross
                      : null
                  }
                  previous={
                    dashboardState.salesByDate
                      ? dashboardState.salesByDate?.previous?.salesValueGross
                      : null
                  }
                  currencySymbol={userInfo?.organization?.currencySymbol}
                  dateRange={dateRange}
                />
              </div>
              <div className="w-6/12 xl:w-3/12 px-1 md:px-3">
                <CardStat
                  statTitle={"Gross Profit"}
                  result={
                    dashboardState.salesByDate
                      ? dashboardState.salesByDate.results?.grossProfit
                      : null
                  }
                  previous={
                    dashboardState.salesByDate
                      ? dashboardState.salesByDate?.previous?.grossProfit
                      : null
                  }
                  currencySymbol={userInfo?.organization?.currencySymbol}
                  dateRange={dateRange}
                />
              </div>
              <div className="w-6/12 xl:w-3/12 px-1 md:pr-3 xl:px-3">
                <CardStat
                  statTitle={"Orders Count"}
                  result={
                    dashboardState.salesByDate
                      ? dashboardState.salesByDate?.results?.orders
                      : null
                  }
                  previous={
                    dashboardState.salesByDate
                      ? dashboardState.salesByDate?.previous?.orders
                      : null
                  }
                  currencySymbol={userInfo?.organization?.currencySymbol}
                  dateRange={dateRange}
                />
              </div>
              <div className="w-6/12 xl:w-3/12 px-1 md:px-3">
                <CardStat
                  statTitle={"Items Sold"}
                  result={
                    dashboardState.salesByDate
                      ? dashboardState.salesByDate?.results?.itemsSold
                      : null
                  }
                  previous={
                    dashboardState.salesByDate
                      ? dashboardState.salesByDate?.previous?.itemsSold
                      : null
                  }
                  currencySymbol={userInfo?.organization?.currencySymbol}
                  dateRange={dateRange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto w-full">
        <div className="flex flex-wrap mt-6">
          <div className="w-full xl:w-8/12 px-1 xl:pr-3">
            <LineChart
              data1={dashboardState.salesPerformanceByDate}
              data2={dashboardState.grossPerformanceByDate}
            />
          </div>
          <div className="w-full xl:w-4/12 px-1 xl:px-3 mt-3 xl:mt-0">
            <BarItemChart
              data1={dashboardState.orderCountPerformanceByDate}
              data2={dashboardState.soldItemPerformanceByDate}
            />
          </div>
        </div>
        <div className="mx-auto w-full px-1 xl:pr-3 mt-6">
          <CardItems
            tableData={dashboardState.itemsPerformance}
            currencySymbol={userInfo?.organization?.currencySymbol}
          />
        </div>
        <div className="flex flex-wrap xl:flex-nowrap mt-6">
          <div className="w-full xl:w-1/2 xl:mb-0 px-1 xl:pr-4">
            <BarVerticalChart
              data={dashboardState.customersPerformance}
              title={"Top Customers"}
            />
          </div>
          <div className="w-full xl:w-1/2 xl:mb-0 px-1 xl:px-4">
            <BarVerticalChart
              data={dashboardState.channelsPerformance}
              title={"Top Channels"}
            />
          </div>
        </div>
        <div className="flex flex-wrap xl:flex-nowrap mt-3">
          <div className="w-full xl:w-4/12 xl:mb-0 px-1 xl:pr-4">
            <BarVerticalChart
              data={dashboardState.brandPerformance}
              title={"Top Brands"}
            />
          </div>
          <div className="w-full xl:w-4/12 xl:mb-0 px-1 xl:px-4">
            <BarVerticalChart
              data={dashboardState.categoryPerformance}
              title={"Top Categories"}
            />
          </div>
          <div className="w-full xl:w-4/12 px-1 xl:px-4">
            <BarVerticalChart
              data={dashboardState.departmentPerformance}
              title={"Top Departments"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
