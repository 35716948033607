import { useEffect, useRef, FC } from "react";
import { Chart, registerables } from "chart.js";
import { SalesOverviewSegmentDate } from "../../api-client";

Chart.register(...registerables);

interface PerformanceChartProps {
  data1: SalesOverviewSegmentDate[] | null | undefined
  data2: SalesOverviewSegmentDate[] | null | undefined
}

export const BarItemChart: FC<PerformanceChartProps> = ({ data1, data2 }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        const config = {
          type: "bar",
          data: {
            labels: data1 ? data1.map((key) => key.dateName) : null,
            datasets: [
              {
                label: "Item Sold",
                tension: 0.4,
                backgroundColor: "#f27b35",
                borderColor: "#f27b35",
                data: data2 ? data2.map((key) => key.itemsSold) : null,
              },
              {
                label: "Order Count",
                tension: 0.4,
                backgroundColor: "#30c48d",
                borderColor: "#30c48d",
                data: data1 ? data1.map((key) => key.orders) : null,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            color: "#94a3b8",
            scales: {
              y: {
                color: "#94a3b8",

                ticks: {
                  color: "#94a3b8",
                },
              },
              x: {
                ticks: {
                  color: "#94a3b8",
                },
              },
            },
            plugins: {
              legend: {
                position: "top",
                labels: {
                  usePointStyle: true,
                },
              },
              title: {
                display: true,
                text: "Order Insights",
                align: "start",
                color: "#9ca3af",
                font: {
                  size: 20,
                  family: "'Open Sans', sans-serif",
                },
              },
              hover: {
                mode: "nearest",
                intersect: true,
              },
            },
          },
        };

        // Destroy the previous chart instance if it exists
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }

        // Create the new chart instance and store the reference
        //@ts-ignore
        chartInstanceRef.current = new Chart(ctx, config);
      }
    }

    // Cleanup function to destroy chart instance on component unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [data1, data2]);

  return (
    <div className="relative flex flex-col min-w-0 break-words shadow-md dark:shadow-slate-600 rounded-md bg-white dark:bg-[#334155] transition-colors duration-500">
      <div className="p-5 flex-auto">
        <div className="relative min-h-[350px] ">
          {data1 && data2 ? (
            <canvas ref={chartRef} id="sales-chart" />
          ) : (
            <div role="status" className="space-y-2.5 animate-pulse ">
              <div className="h-[350px] bg-gray-200 rounded-sm dark:bg-gray-500 w-full" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
