import type {
  DefaultOptionsType,
  TableColumnType,
  RowType,
  FormValuesType,
  ParamsType,
  ReportServerProps,
} from "../../utils/type";
import {
  getQueryParams,
  addParameter,
  updateDynamicState,
  handleError,
  extractDefaultValues,
  formValuesDefaults,
  customSorting,
} from "../../utils/functions";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, FC } from "react";
import { PrintLabel } from "../quickView/Print";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "../reportInputsHelper/Input";
import { forecastColumns } from "../../utils/columns";
import { ItemsInput } from "../reportInputs/ItemsInput";
import { SalesInput } from "../reportInputs/SalesInput";
import { ReportDataTable } from "../tables/ReportTable";
import { useLocation, useParams } from "react-router-dom";
import { arrayToQueryString } from "../../utils/functions";
import { ScheduleReport } from "../schedule/ScheduleReport";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { useShowPrintStore } from "../../utils/store/showPrintStore";
import { DateFormatterCell } from "../tableHelpers/DateFormatterCell";
import { ReportsService, ReportTypeConfiguration } from "../../api-client";

export const Reports: FC<ReportServerProps> = ({ reports }) => {
  const location = useLocation();
  const { reportType } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [rows, setRows] = useState<RowType | null>(null);
  const [period, setPeriod] = useState<any>();
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [columns, setColumns] = useState<TableColumnType[] | null>(null);
  const [isForecast, setIsForecast] = useState<boolean>(false);
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const [scheduleParams, setScheduleParams] = useState<ParamsType[]>([]);
  const [parametersString, setParametersString] = useState<string>("");
  const [singleReport, setSingleReport] =
    useState<ReportTypeConfiguration | null>(null);
  const [defaultOptionsArray, setDefaultOptionsArray] = useState<
    DefaultOptionsType[]
  >([]);
  const [errors, setErrors] = useState<FormValuesType>({});
  const { darkMode } = useDarkModeStore();
  const navigate = useNavigate();
  const { showPrint } = useShowPrintStore();

  const [formValues, setFormValues] =
    useState<FormValuesType>(formValuesDefaults);

  const handleColumns = (
    periods: { name: string; start: string; end: string }[],
    mappedColumns: TableColumnType[]
  ) => {
    const periodColumns = periods.map((period) => {
      return {
        Header: (
          <div>
            <p className="mb-0 ">{period.name}</p>
            <div className="">
              <DateFormatterCell date={period.start} />
              <DateFormatterCell date={period.end} />
            </div>
          </div>
        ),
        width: 90,
        accessor: period.name,
        sortType: customSorting,
        type: "TEXT",
      };
    });

    const updatedColumns = [
      ...mappedColumns,
      ...periodColumns.reverse(),
      ...forecastColumns,
    ];

    setColumns(updatedColumns);
  };

  const fetchData = async () => {
    setLoadingTable(true);
    setErrors({});
    setRows(null);
    setColumns(null);

    try {
      let validationErrors: FormValuesType = {};

      singleReport?.parameters?.forEach((input) => {
        if (!input.name) return;
        const value = formValues[input.name];

        if (input.required) {
          if (input.type === "DATE_RANGE") {
            if (
              (!value?.start && !value?.dynamicStartingDate) ||
              (!value?.end && !value?.dynamicEndingDate)
            ) {
              validationErrors[input.name] = `${input.description} is required`;
            }
          } else if (!value) {
            validationErrors[input.name] = `${input.description} is required`;
          }
        }
      });

      if (Object.keys(validationErrors).length) {
        setErrors(validationErrors);
        setLoadingTable(false);
        return;
      }

      const parameters: ParamsType[] = [];

      for (const key in formValues) {
        const value = formValues[key];
        if (typeof value === "object" && !Array.isArray(value)) {
          for (const innerKey in value) {
            addParameter(parameters, `${key}.${innerKey}`, value[innerKey]);
          }
        } else {
          addParameter(parameters, key, value);
        }
      }
      const response = await ReportsService.postReportsRunReport({
        body: {
          type: singleReport?.type!,
          parameters,
        },
      });

      if (response.error) {
        handleError(response.error);
        return;
      }

      setScheduleParams(parameters);
      const { data } = response;

      let paramString = "";
      if (data.report?.parameters) {
        if (data.renderType === "FORECAST") {
          const filteredParameters = data.report.parameters.filter(
            (param) => param.name !== "startDate" && param.name !== "endDate"
          );

          paramString += arrayToQueryString(filteredParameters);
        } else {
          paramString += arrayToQueryString(data.report.parameters);
        }
      }
      setParametersString(paramString);

      setRows(data.records);
      if (!data.schema) {
        setColumns([]);
        setLoadingTable(false);
        return;
      }
      const mappedColumns = data.schema.map((col) => ({
        Header: col.headerName,
        accessor: col.field,
        summary: col.summary,
        type: col.type,
        exportOnly: col.exportOnly,
      }));

      if (data.renderType === "FORECAST") {
        setPeriod(data.helpers?.periods);
        handleColumns(
          data.helpers?.periods as {
            name: string;
            start: string;
            end: string;
          }[],
          mappedColumns as any
        );
        setIsForecast(true);
      } else {
        setColumns(mappedColumns as any);
        setIsForecast(false);
      }
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
    setLoadingTable(false);
  };

  useEffect(() => {
    const foundReport = reports?.find((report) => report.type === reportType);

    if (!foundReport) return;
    setSingleReport(foundReport);
    setErrors({});
    setFormValues(formValuesDefaults);
    setColumns(null);
    setRows(null);

    const defaultValues = extractDefaultValues(foundReport.parameters);
    setFormValues((prevState) => ({ ...prevState, ...defaultValues }));
    const values = getQueryParams(searchParams);

    if (
      foundReport?.parameters?.some((params) => params.name === "expiresWithin")
    ) {
      setFormValues((prev) => ({
        ...prev,
        expiresWithin: "30",
      }));
    }
    setFormValues((prevState) => updateDynamicState(prevState, values));
  }, [reportType, reports]);

  /*  
 useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" && !loadingTable) {
        fetchData();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []); 
  */

  return (
    <div
      className={`${
        singleReport ? "shadow-md rounded-md dark:shadow-slate-600" : ""
      }`}
    >
      {showScheduleModal && (
        <div
          className={`fixed inset-0 w-full h-full bg-black bg-opacity-50 z-[11]`}
          onClick={() => setShowScheduleModal(false)}
        >
          <div
            className={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-h-[70vh] w-[550px] bg-white rounded-md overflow-y-auto`}
            onClick={(e) => e.stopPropagation()}
          >
            <ScheduleReport
              setShowScheduleModal={setShowScheduleModal}
              parameters={scheduleParams}
              reportType={singleReport?.type}
              reportName={singleReport?.name}
            />
          </div>
        </div>
      )}
      {showPrint && (
        <div
          className={`fixed inset-0 w-full h-full bg-black bg-opacity-50 z-[100]`}
          onClick={() => setShowScheduleModal(false)}
        >
          <div
            className={`absolute top-[20%] left-[50%] translate-x-[-50%] w-[90%] max-h-[50vh] dark:bg-bgDarkColor lg:w-[900px] bg-white rounded-md overflow-y-auto`}
            onClick={(e) => e.stopPropagation()}
          >
            <PrintLabel />
          </div>
        </div>
      )}
      {singleReport ? (
        <div className="rounded-md transition-colors duration-500 bg-white dark:bg-bgDarkColor pb-1">
          <div className="bg-mainColor dark:bg-mainColorDarkMode shadow-md transition-colors duration-500 rounded-t-md py-2 border-mainColor dark:border-mainColorDarkMode">
            <h1 className="text-[25px] pl-3  text-gray-100">
              {singleReport && singleReport.name}
            </h1>
          </div>
          <>
            {singleReport?.filter?.filterItems && (
              <div className="border-b-[0.7px] border-[#bac4df]">
                <ItemsInput
                  formValues={formValues}
                  setFormValues={setFormValues}
                  defaultOptionsArray={defaultOptionsArray}
                />
              </div>
            )}
            {singleReport?.filter?.filterSales && (
              <div className="border-b-[0.7px] border-[#bac4df]">
                <SalesInput
                  setFormValues={setFormValues}
                  formValues={formValues}
                  defaultOptionsArray={defaultOptionsArray}
                />
              </div>
            )}
          </>
          <div className="p-4 px-1 xs:px-4 flex flex-wrap gap-5 mt-3 justify-center sm:justify-start">
            {singleReport &&
              singleReport.parameters?.map((input, i: number) => {
                return (
                  <div key={input.name}>
                    <Input
                      input={input}
                      errors={errors}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      navigate={navigate}
                    />
                  </div>
                );
              })}
          </div>
          <div className="flex justify-end p-4 pt-2 rounded-b-md">
            <button
              onClick={fetchData}
              disabled={loadingTable}
              className="bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 shadow-md dark:shadow-slate-600 text-white rounded-md cursor-pointer disabled:bg-gray-400 px-4 p-1.5"
            >
              Run
            </button>
          </div>
          {loadingTable ? (
            <div className="w-full h-[300px] flex justify-center items-center">
              <ReactLoading
                type="spinningBubbles"
                height={"200px"}
                width={"200px"}
                color="#4273B8"
              />
            </div>
          ) : rows && columns ? (
            rows.length > 0 && columns.length > 0 ? (
              <ReportDataTable
                data={rows}
                columns={columns}
                setShowScheduleModal={setShowScheduleModal}
                name={singleReport?.name}
                isForecast={isForecast}
                period={period}
                parametersString={parametersString}
              />
            ) : (
              <div className="w-full h-[100px] flex justify-center items-center">
                <p className="text-center text-gray-500 py-8 text-xl">
                  No data available
                </p>
              </div>
            )
          ) : null}
        </div>
      ) : (
        <div className="w-full h-[50vh] flex justify-center border-[1px] rounded-md border-mainColor dark:border-mainColorDarkMode transition-colors duration-500 bg-white dark:bg-bgDarkColor">
          <ReactLoading
            type="spinningBubbles"
            height={"200px"}
            width={"200px"}
            color="#4273B8"
          />
        </div>
      )}
    </div>
  );
};
