import { useState } from "react";
import { ItemInventory3dSummary } from "../../api-client";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";

export const BinForm = ({
  bin,
  handleClose,
}: {
  bin: ItemInventory3dSummary;
  handleClose: () => void;
}) => {
  const [qcDate, setQcDate] = useState<string>("");
  const defaultProductionDate = bin.batchDetails?.productionDate || null;
  const defaultBestBeforeDate = bin.batchDetails?.bestBeforeDate || null;

  const [productionDate, setProductionDate] = useState<DateValueType | null>({
    startDate: defaultProductionDate,
    endDate: defaultProductionDate,
  });

  const [bestBeforeDate, setBestBeforeDate] = useState<DateValueType | null>({
    startDate: defaultBestBeforeDate,
    endDate: defaultBestBeforeDate,
  });

  const [qc, setQc] = useState<boolean>(false);

  return (
    <div className="fixed inset-0 z-[100]">
      <div className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-gray-700 bg-white rounded-md z-[100]">
        <div className="border border-mainColor dark:bg-bgDarkColor rounded-md shadow-2xl">
          <div className="bg-mainColor dark:bg-mainColorDarkMode p-2 text-white">
            <h2 className="p-1 text-2xl">Batch details</h2>
          </div>
          <div className="p-5">
            <div className="relative h-[70px]">
              <label
                htmlFor="qcDate"
                className="absolute text-sm text-[#427dab] dark:text-blue-200 dark:bg-bgDarkColor -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white px-1 peer-focus:text-blue-600 left-1"
              >
                Production date
              </label>
              <Datepicker
                displayFormat="DD/MM/YY"
                placeholder="dd/mm/yy"
                useRange={false}
                asSingle={true}
                value={productionDate}
                showShortcuts={true}
                toggleClassName="absolute right-0 h-full text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed pr-1"
                inputClassName="w-full border-[#cccccc] hover:border-[#b3b3b3] rounded focus:ring-0 font-normal border-[1px] text-gray-700 dark:text-gray-200 py-[9.5px] pl-2 text-sm placeholder:text-gray-700 dark:placeholder:text-gray-200 dark:bg-bgDarkColor"
                onChange={(e) => setProductionDate(e)}
              />
            </div>
            <div className="relative h-[70px]">
              <label htmlFor="qcDate" className="label-class">
                Best Before Date
              </label>
              <Datepicker
                displayFormat="DD/MM/YY"
                placeholder="dd/mm/yy"
                useRange={false}
                asSingle={true}
                value={bestBeforeDate}
                showShortcuts={true}
                toggleClassName="absolute right-0 h-full text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed pr-1"
                inputClassName="w-full border-[#cccccc] hover:border-[#b3b3b3] rounded focus:ring-0 font-normal border-[1px] text-gray-700 dark:text-gray-200 py-[9.5px] pl-2 text-sm placeholder:text-gray-700 dark:placeholder:text-gray-200 dark:bg-bgDarkColor"
                onChange={(e) => setBestBeforeDate(e)}
              />
            </div>
            <div className="flex gap-4 items-center justify-between mb-5">
              <div className="relative">
                <label htmlFor="qcDate" className="label-class">
                  QC Date
                </label>
                <input
                  value={qcDate}
                  onChange={(e) => setQcDate(e.target.value)}
                  id="qcDate"
                  type="text"
                  name="qcDate"
                  className="input-class"
                />
              </div>
              <div className="flex items-center border-[1px] border-[#cccccc] hover:border-[#b3b3b3] rounded h-10">
                <label
                  htmlFor="qc"
                  className="w-full py-2 ms-2 text-sm font-medium text-[#427dab] dark:text-blue-200"
                >
                  QC
                </label>
                <input
                  defaultChecked={qc}
                  onChange={(e) => setQc(e.target.checked)}
                  id="qc"
                  type="checkbox"
                  name="qc"
                  className="input-checkbox-class"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <button
                onClick={handleClose}
                className="p-2 rounded bg-gray-500 hover:bg-gray-600 text-white shadow font-semibold w-[100px]"
              >
                Cancel
              </button>
              <button
                /*   onClick={updateDashboard} */
                className="p-2 rounded bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 text-white shadow font-semibold w-[100px]"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
