import { FC } from "react";
import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md";
import { ReportsService } from "../../api-client";
import { ModifyDashboardProps } from "../../utils/type";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { handleSuccess, handleError, swalStyle } from "../../utils/functions";

export const DeleteDashboard: FC<ModifyDashboardProps> = ({
  dashboard,
  getDashboards,
}) => {
  const { darkMode } = useDarkModeStore();

  const handleDeleteDashboard = async () => {
    if (!dashboard) return;

    try {
      const deleteResponse = await ReportsService.deleteReportsKpiDashboardId({
        path: { id: dashboard.id! },
      });

      if (deleteResponse.error) {
        handleError(deleteResponse.error);
        return;
      }

      handleSuccess("Dashboard has been deleted", false);
      getDashboards();
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const handleConfirmDelete = () => {
    if (!dashboard) return;

    Swal.fire({
      title: `Are you sure you want to delete ${dashboard.title}?`,
      position: "top",
      ...swalStyle,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.close();
        handleDeleteDashboard();
      }
    });
  };

  return (
    <button
      onClick={handleConfirmDelete}
      className="text-[20px] text-[#dd6c6c] flex justify-center w-full"
    >
      <MdDelete size={20} />
    </button>
  );
};
