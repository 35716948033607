
import {
  getUrl,
  updateDynamicState,
  getQueryParams,
  getValueInput,
  handleInputChange,
} from "../../utils/functions";
import ReactLoading from "react-loading";
import { ItemsInput } from "./ItemsInput";
import { ApiUser } from "../../api-client";
import { useState, useEffect } from "react";
import { FaRegClipboard } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import CustomSelect from "../reportInputsHelper/CustomSelect";
import { useInputValuesStore } from "../../utils/store/inputValuesStore";
import type { FormValuesType, ValueLabelStringType } from "../../utils/type";
export const Items = ({
  listAPIUser,
}: {
  listAPIUser: ApiUser[] | undefined;
}) => {
  const [link, setLink] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { inputValues } = useInputValuesStore();

  const searchParams = new URLSearchParams(location.search);
  const [formValues, setFormValues] = useState<FormValuesType>({
    value: "VALUE_BILLED",
    items: {
      sku: "",
      skuExclude: false,
      brand: [],
      brandExclude: false,
      category: [],
      categoryExclude: false,
      department: [],
      departmentExclude: false,
      appCategory: [],
      appCategoryExclude: false,
      tag: [],
      tagExclude: [],
      vendor: [],
      vendorExclude: false,
    },
    warehouse: [],
  });

  const getLink = async () => {
    setLink("");
    setLoading(true);

    const params = getUrl(formValues);

    setTimeout(() => {
      setLoading(false);
      setLink(
        `${process.env.REACT_APP_API_URL}reports/report-server/sales?${params}`
      );
    }, 500);
  };

  useEffect(() => {
    const values = getQueryParams(searchParams);
    setFormValues((prevState) => updateDynamicState(prevState, values));
  }, []);

  return (
    <div className=" mb-5 rounded-md transition-colors duration-500 bg-white dark:bg-bgDarkColor shadow-md dark:shadow-slate-600">
      <div className="bg-mainColor dark:bg-mainColorDarkMode transition-colors duration-500 shadow-md rounded-t-md py-2 border-mainColor dark:border-mainColorDarkMode text-white">
        <h1 className="text-[25px] pl-3">Inventory Query</h1>
      </div>
      <div className="pb-2 border-b-[0.7px] border-[#bac4df]">
        <ItemsInput setFormValues={setFormValues} formValues={formValues} />
      </div>
      <div className="flex flex-wrap gap-5 mt-3 justify-center sm:justify-start p-4">
        <CustomSelect
          label="Value"
          id="value"
          textError={"Value is required"}
          defaultValue={{ value: "VALUE_BILLED", label: "Value Billed" }}
          options={[
            { value: "VALUE_BILLED", label: "Value Billed" },
            { value: "VALUE_INVOICED", label: "Value Invoiced" },
            { value: "VALUE_CREDITED", label: "Value Credited" },
            { value: "QTY_BILLED", label: "Quantity Billed" },
            { value: "QTY_INVOICED", label: "Quantity Invoiced" },
            { value: "QTY_CREDITED", label: "Quantity Credited" },
          ]}
          onChange={(e: { value: string }) => {
            handleInputChange(
              setFormValues,
              navigate,
              "value",
              "",
              e?.value ?? ""
            );
          }}
        />
        <CustomSelect
          label="Warehouse"
          id="warehouseCode"
          isMulti={true}
          options={
            inputValues
              ? inputValues.warehouses.map((warehouse) => {
                  return {
                    label: warehouse.name,
                    value: warehouse.code,
                  };
                })
              : []
          }
          value={getValueInput(
            "warehouse",
            "warehouses",
            formValues,
            inputValues,
            "code"
          )}
          onChange={(e: ValueLabelStringType[]) => {
            handleInputChange(
              setFormValues,
              navigate,
              "warehouse",
              "",
              e.map((a) => a.value),
              true
            );
          }}
        />
        <CustomSelect
          label="API User"
          id="apiUser"
          onChange={(e: ValueLabelStringType) => {
            handleInputChange(setFormValues, navigate, "apiKey", "", e!.value);
          }}
          options={
            listAPIUser
              ? listAPIUser.map((user) => {
                  return {
                    label: user.name,
                    value: user.apiKey,
                  };
                })
              : []
          }
        />
      </div>
      <div className="flex justify-end">
        <div className="flex gap-4 p-4 pt-0">
          <button
            onClick={getLink}
            className="bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 hover:text-white shadow-md dark:shadow-slate-600 text-white rounded-md cursor-pointer disabled:bg-gray-400 w-[100px] h-[36px] px-3 py-1.5"
          >
            {loading ? (
              <div className="flex justify-center">
                <ReactLoading
                  type="spinningBubbles"
                  height={24}
                  width={24}
                  color="white"
                />
              </div>
            ) : (
              <p>Get Link</p>
            )}
          </button>
          <button
            disabled={link === ""}
            className="flex gap-2 items-center bg-blue-700 hover:bg-blue-800 shadow-md dark:shadow-slate-600 text-white rounded-md font-semibold disabled:bg-gray-400 px-3 py-1.5"
            onClick={() => navigator.clipboard.writeText(link)}
          >
            Copy
            <FaRegClipboard />
          </button>
        </div>
      </div>
    </div>
  );
};
