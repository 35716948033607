import { useTable, useFlexLayout } from "react-table";
import { SalesReportDrillDownQty } from "../../../api-client";
import { orderDrilldownColumns } from "../../../utils/columns";
import { useUserInfoStore } from "../../../utils/store/userStore";
import { DateFormatterCell } from "../../tableHelpers/DateFormatterCell";

export const OrderDrilldownTable = ({
  drilldown,
}: {
  drilldown: SalesReportDrillDownQty[];
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns: orderDrilldownColumns, data: drilldown },
      useFlexLayout
    );
  const { userInfo } = useUserInfoStore();

  return (
    <div className="p-2">
      {drilldown && (
        <table
          {...getTableProps()}
          className="min-w-full divide-y border divide-gray-200 dark:divide-gray-500 dark:border-gray-500"
        >
          <thead className="bg-gray-50 dark:bg-gray-700">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`${
                      columnIndex !== headerGroup.headers.length - 1 &&
                      "border-r"
                    } shadow relative p-2 text-left text-xs font-medium text-gray-500 border-b border-gray-300 flex dark:text-gray-300 dark:border-gray-500`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200 dark:divide-gray-500 dark:bg-gray-800"
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  className="bg-white dark:bg-bgDarkColor divide-gray-200 dark:divide-gray-500"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, cellIndex) => {
                    const columnType =
                      orderDrilldownColumns[cellIndex].accessor;
                    const cellClass = `text-left text-xs p-1 text-gray-500 border-gray-300 dark:text-gray-100 dark:border-gray-500 truncate ${
                      cellIndex !== row.cells.length - 1 && "border-r"
                    }`;

                    if (columnType === "date") {
                      return (
                        <td {...cell.getCellProps()} className={cellClass}>
                          <DateFormatterCell date={cell.value} />
                        </td>
                      );
                    } else if (columnType === "document") {
                      return (
                        <td {...cell.getCellProps()} className={cellClass}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-600 cursor-pointer"
                            href={`${userInfo?.organization?.appUrl}/go/${row.original.documentType}/${row.original.documentId}`}
                          >
                            {cell.render("Cell")}
                          </a>
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()} className={cellClass}>
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
