import { FC } from "react";
import { useState } from "react";
import type { DynamicSelectionProps } from "../../utils/type";
import { dateSelectOption } from "../../utils/functions";

export const DynamicSelection: FC<DynamicSelectionProps> = ({
  setFormValues,
  formValues,
  title,
  updateUrlParams,
  setValue,
}) => {
  const [values, setValues] = useState<{
    dynamicStartingDate: string | undefined;
    dynamicEndingDate: string | undefined;
  }>({
    dynamicStartingDate: undefined,
    dynamicEndingDate: undefined,
  });

  return (
    <div className="p-4 rounded-sm bg-white dark:border dark:border-gray-600 dark:bg-bgDarkColor transition-colors duration-500 z-[100]">
      <p className="font-medium text-xl dark:text-gray-200">
        Select dynamic date
      </p>
      <div className="flex gap-5 mt-6 mb-1">
        <div className="relative">
          <label
            htmlFor="dynamicStartingDate"
            className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] z-[9] origin-[0] bg-white dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 top-2.5 left-1`}
          >
            Starting date
          </label>
          <select
            id="dynamicStartingDate"
            onChange={(e) => {
              setValues({
                ...values,
                dynamicStartingDate: e.target.value,
              });
            }}
            value={values?.dynamicStartingDate}
            className="border-[1px] border-[#cccccc] dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200 rounded-md p-2"
          >
            {dateSelectOption.map((element) => (
              <option key={element.value} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        </div>
        <div className="relative">
          <label
            htmlFor="dynamicEndingDate"
            className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] z-[9] origin-[0] bg-white dark:bg-bgDarkColor transition-colors duration-500 px-1 peer-focus:text-blue-600 top-2.5 left-1`}
          >
            Ending date
          </label>
          <select
            id="dynamicEndingDate"
            onChange={(e) => {
              setValues({
                ...values,
                dynamicEndingDate: e.target.value,
              });
            }}
            value={values?.dynamicEndingDate}
            className="border-[1px] border-[#cccccc] dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200 rounded-md p-2"
          >
            {dateSelectOption.map((element) => (
              <option key={element.value} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        </div>
        <button
          disabled={!values?.dynamicStartingDate || !values?.dynamicEndingDate}
          className="bg-blue-600 hover:bg-blue-700 disabled:bg-gray-400 p-1.5 rounded-md text-white w-[60px] font-medium shadow-md dark:shadow-slate-600"
          onClick={() => {
            updateUrlParams(
              values?.dynamicStartingDate,
              values?.dynamicEndingDate
            );
            setFormValues({
              ...formValues,
              [title]: {
                dynamicEndingDate: values?.dynamicEndingDate,
                dynamicStartingDate: values?.dynamicStartingDate,
                start: "",
                end: "",
              },
            });
            setValue({ startDate: null, endDate: null });
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
};
