import { FC } from "react";
import "tailwindcss/tailwind.css";
import { useTable, useFlexLayout, Column } from "react-table";
import type { DashboardTableProps } from "../../utils/type";
import { dashboardColumns } from "../../utils/columns";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { EditDashboard } from "../dashboards/EditDashboard";
import { DeleteDashboard } from "../dashboards/DeleteDashboard";
import { KpiDashboard } from "../../api-client";

export const DashboardTable: FC<DashboardTableProps> = ({
  data,
  selectedDashboard,
  handleSelectDashboard,
  getDashboards,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<KpiDashboard>(
      { columns: dashboardColumns as readonly Column<KpiDashboard>[], data },
      useFlexLayout
    );

  return (
    <table
      {...getTableProps()}
      className="min-w-full border-b divide-gray-200 dark:divide-gray-500 dark:border-gray-500"
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}></tr>
        ))}
      </thead>
      <tbody
        {...getTableBodyProps()}
        className="bg-white divide-y divide-gray-200 dark:divide-gray-500 dark:bg-gray-800 transition-colors duration-500"
      >
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={`hover:bg-blue-50 dark:hover:bg-gray-900 transition-colors duration-500 ${
                row.original.id === selectedDashboard
                  ? "bg-blue-50 dark:bg-gray-900"
                  : ""
              }`}
            >
              {row.cells.map((cell, cellIndex) => {
                if (cell.column.id === "title" && cell.value) {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`text-left text-sm p-2 text-gray-500 border-gray-300 dark:text-gray-300 dark:border-gray-500 ${
                        cellIndex !== row.cells.length - 1 && "border-r"
                      }`}
                    >
                      <button
                        onClick={() => handleSelectDashboard(row.original.id)}
                        className={`${
                          row.original.id === selectedDashboard
                            ? "text-blue-700 dark:text-blue-200"
                            : "hover:border-b border-blue-600"
                        }`}
                      >
                        {cell.render("Cell")}
                      </button>
                    </td>
                  );
                } else if (cell.column.id === "edit") {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`text-left text-sm p-2 text-gray-500 border-gray-300 dark:text-gray-300 dark:border-gray-500 ${
                        cellIndex !== row.cells.length - 1 && "border-r"
                      }`}
                    >
                      <span
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Edit dashboard"
                      >
                        <EditDashboard
                          dashboard={row.original}
                          getDashboards={getDashboards}
                        />
                      </span>
                    </td>
                  );
                } else if (cell.column.id === "delete") {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`text-left text-sm p-2 text-gray-500 border-gray-300 dark:text-gray-300 dark:border-gray-500 ${
                        cellIndex !== row.cells.length - 1 && "border-r"
                      }`}
                    >
                      <span
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Delete"
                      >
                        <DeleteDashboard
                          dashboard={row.original}
                          getDashboards={getDashboards}
                        />
                      </span>
                    </td>
                  );
                } else
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`text-left text-sm p-2 text-gray-500 border-gray-300 dark:text-gray-300 dark:border-gray-500 ${
                        cellIndex !== row.cells.length - 1 && "border-r"
                      }`}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
              })}
            </tr>
          );
        })}
      </tbody>
      <ReactTooltip id="my-tooltip" />
    </table>
  );
};
