import {
  getUrl,
  getQueryParams,
  updateDynamicState,
  getValueInput,
  handleInputChange,
} from "../../utils/functions";
import ReactLoading from "react-loading";
import { SalesInput } from "./SalesInput";
import { ItemsInput } from "./ItemsInput";
import { useState, useEffect } from "react";
import { ApiUser } from "../../api-client";
import { FaRegClipboard } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useLocation } from "react-router-dom";
import { DateRange } from "../reportInputsHelper/DateRange";
import CustomSelect from "../reportInputsHelper/CustomSelect";
import { useInputValuesStore } from "../../utils/store/inputValuesStore";
import type { FormValuesType, ValueLabelStringType } from "../../utils/type";

export const Sales = ({
  listAPIUser,
}: {
  listAPIUser: ApiUser[] | undefined;
}) => {
  const [link, setLink] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const { inputValues } = useInputValuesStore();

  const [formValues, setFormValues] = useState<FormValuesType>({
    value: "VALUE_BILLED",
    source: "SALES",
    useGrossValues: false,
    useLcy: false,
    date: {
      dynamicStartingDate: "",
      dynamicEndingDate: "",
      start: "",
      end: "",
    },
    sales: {
      contact: [],
      contactExclude: false,
      docTag: [],
      docTagExclude: [],
      contactTag: [],
      contactTagExclude: [],
      salesRep: [],
      salesRepExclude: false,
    },
    items: {
      brand: [],
      brandExclude: false,
      category: [],
      categoryExclude: false,
      department: [],
      departmentExclude: false,
      appCategory: [],
      appCategoryExclude: false,
      tag: [],
      tagExclude: [],
      vendor: [],
      vendorExclude: false,
      sku: "",
      skuExclude: false,
    },
    warehouse: [],
    apiKey: "",
  });

  const getLink = async () => {
    setLink("");
    setLoading(true);

    const params = getUrl(formValues);

    setTimeout(() => {
      setLoading(false);
      setLink(
        `${process.env.REACT_APP_API_URL}/reports/report-server/sales?${params}`
      );
    }, 500);
  };

  useEffect(() => {
    const values = getQueryParams(searchParams);
    setFormValues((prevState) => updateDynamicState(prevState, values));
  }, []);

  const valueOption = [
    { value: "VALUE_BILLED", label: "Value Billed" },
    { value: "VALUE_INVOICED", label: "Value Invoiced" },
    { value: "VALUE_CREDITED", label: "Value Credited" },
    { value: "QTY_BILLED", label: "Quantity Billed" },
    { value: "QTY_INVOICED", label: "Quantity Invoiced" },
    { value: "QTY_CREDITED", label: "Quantity Credited" },
  ];

  return (
    <div className="mb-5 rounded-md bg-white dark:bg-bgDarkColor transition-colors duration-500 shadow-md dark:shadow-slate-600">
      <div className="bg-mainColor dark:bg-mainColorDarkMode shadow-md rounded-t-md py-2 border-mainColor dark:border-mainColorDarkMode transition-colors duration-500 text-white">
        <h1 className="text-[25px] pl-3">Sales Query</h1>
      </div>
      <div className="mt-3">
        <div className="p-4 pb-6 pt-0 border-b-[0.7px] border-[#bac4df]">
          <h2 className="mb-5 text-lg text-gray-400 dark:text-gray-300">
            Plan
          </h2>
          <div className="flex flex-wrap gap-5 mt-3 justify-center sm:justify-start">
            <CustomSelect
              label="Warehouse"
              id="warehouseCode"
              isMulti={true}
              value={getValueInput(
                "warehouse",
                "warehouses",
                formValues,
                inputValues,
                "code"
              )}
              options={
                inputValues
                  ? inputValues.warehouses.map((warehouse) => {
                      return {
                        label: warehouse.name,
                        value: warehouse.code,
                      };
                    })
                  : []
              }
              onChange={(e: ValueLabelStringType) => {
                handleInputChange(
                  setFormValues,
                  navigate,
                  "warehouse",
                  null,
                  e,
                  true
                );
              }}
            />
            <DateRange
              title={"Date Range"}
              name={"date"}
              setFormValues={setFormValues}
              formValues={formValues}
            />
            <CustomSelect
              label="Value"
              id="value"
              textError={"Value is required"}
              value={valueOption.find(
                (option) => option.value === formValues.value
              )}
              defaultValue={{ value: "VALUE_BILLED", label: "Value Billed" }}
              options={valueOption}
              onChange={(e: ValueLabelStringType) => {
                handleInputChange(
                  setFormValues,
                  navigate,
                  "value",
                  "",
                  e?.value ?? ""
                );
              }}
            />{" "}
            <CustomSelect
              label="Source"
              id="source"
              value={[
                { value: "SALES", label: "Sales" },
                { value: "INVOICES", label: "Invoices" },
              ].find((option) => option.value === formValues.source)}
              defaultValue={{ value: "SALES", label: "Sales" }}
              options={[
                { value: "SALES", label: "Sales" },
                { value: "INVOICES", label: "Invoices" },
              ]}
              textError={"Source is required"}
              onChange={(e: ValueLabelStringType) => {
                handleInputChange(
                  setFormValues,
                  navigate,
                  "source",
                  null,
                  e,
                  false
                );
              }}
            />
            <div className="flex gap-16 mt-2 justify-center">
              <div className="relative w-[80px] pl-4">
                <label
                  htmlFor="useGrossValues"
                  className={`absolute text-sm text-[#427dab] dark:text-blue-200 bg-white dark:bg-bgDarkColor transition-colors duration-500 -translate-y-[19px] translate-x-[5px] -top-0.5 z-[9] origin-[0] px-1 peer-focus:text-blue-600 -left-2`}
                >
                  Gross Value
                </label>
                <input
                  type="checkbox"
                  id="useGrossValues"
                  checked={formValues.useGrossValues}
                  onChange={(e) =>
                    handleInputChange(
                      setFormValues,
                      navigate,
                      "useGrossValues",
                      null,
                      e.target.checked
                    )
                  }
                  className="h-[26px] w-[26px] float-left shadow-md  cursor-pointer"
                />
              </div>
              <div className="relative w-[30px]">
                <label
                  htmlFor="useLcy"
                  className={`absolute text-sm text-[#427dab] dark:text-blue-200 bg-white dark:bg-bgDarkColor transition-colors duration-500 -translate-y-[19px] translate-x-[5px] -top-0.5 z-[9] origin-[0] px-1 peer-focus:text-blue-600 -left-2`}
                >
                  LCY
                </label>
                <input
                  type="checkbox"
                  id="useLcy"
                  checked={formValues.useLcy}
                  onChange={(e) =>
                    handleInputChange(
                      setFormValues,
                      navigate,
                      "useLcy",
                      null,
                      e.target.checked
                    )
                  }
                  className="h-[26px] w-[26px] float-left shadow-md dark:shadow-slate-600-md cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pb-2 border-b-[0.7px] border-[#bac4df]">
          <SalesInput setFormValues={setFormValues} formValues={formValues} />
        </div>
        <div className="pb-2 border-b-[0.7px] border-[#bac4df]">
          <ItemsInput setFormValues={setFormValues} formValues={formValues} />
        </div>
        <div className="flex flex-wrap gap-5 mt-3 justify-center sm:justify-start p-4">
          <CustomSelect
            label="API User"
            id="apiUser"
            onChange={(e: ValueLabelStringType) => {
              handleInputChange(
                setFormValues,
                navigate,
                "apiKey",
                "",
                e!.value
              );
            }}
            options={
              listAPIUser
                ? listAPIUser.map((user) => {
                    return {
                      label: user.name,
                      value: user.apiKey,
                    };
                  })
                : []
            }
          />
        </div>
      </div>
      <div className="flex justify-end">
        <div className="flex gap-4 p-4 md:pt-0">
          <button
            onClick={getLink}
            className="bg-blue-600 hover:bg-blue-700 hover:text-white shadow-md dark:shadow-slate-600-md text-white rounded-md cursor-pointer disabled:bg-gray-400 w-[100px] h-[36px] px-3 py-1.5"
          >
            {loading ? (
              <div className="flex justify-center">
                <ReactLoading
                  type="spinningBubbles"
                  height={24}
                  width={24}
                  color="white"
                />
              </div>
            ) : (
              <p> Get Link</p>
            )}
          </button>
          <button
            disabled={link === ""}
            className="flex gap-2 items-center bg-blue-700 hover:bg-blue-800 shadow-md dark:shadow-slate-600-md text-white rounded-md font-semibold disabled:bg-gray-400 px-3 py-1.5"
            onClick={() => navigator.clipboard.writeText(link)}
          >
            Copy
            <FaRegClipboard />
          </button>
        </div>
      </div>
    </div>
  );
};
