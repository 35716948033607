import {
  DefaultOptionsType,
  ReportInputProps,
  ValueLabelStringType,
} from "../../utils/type";
import {
  dateSelectOption,
  getLabelByValue,
  debouncedLoadContactOptions,
  handleInputChange,
} from "../../utils/functions";
import { useState, FC } from "react";
import { DateRange } from "./DateRange";
import { FaTimes } from "react-icons/fa";
import CustomSelect from "./CustomSelect";
import { ColorSelector } from "./ColorSelector";
import Datepicker from "react-tailwindcss-datepicker";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { DateValueType } from "react-tailwindcss-datepicker";
import { useInputValuesStore } from "../../utils/store/inputValuesStore";

export const Input: FC<ReportInputProps> = ({
  input,
  errors,
  formValues,
  setFormValues,
  navigate,
  queues,
}) => {
  const [defaultOptionsArray, setDefaultOptionsArray] = useState<
    DefaultOptionsType[]
  >([]);
  const { inputValues } = useInputValuesStore();

  const [value, setValue] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });

  let options: {
    value?: string | null;
    label?: string | null;
  }[] = [];

  let selectValue:
    | {
        value: string | undefined | null;
        label: string | undefined | null;
      }
    | undefined | null;

  if (input.type === "WAREHOUSE") {
    options =
      inputValues?.warehouses?.map((input) => ({
        value: input.id,
        label: input.name,
      })) || [];

    selectValue = input.multiple
      ? (typeof formValues[input.name!] === "string"
          ? [formValues[input.name!]]
          : formValues[input.name!]
        )?.map((value: string) => ({
          value: value,
          label: inputValues?.warehouses?.find(
            (warehouse) => warehouse.id === value
          )?.name,
        })) || []
      : formValues[input.name!]
      ? {
          value: formValues[input.name!],
          label: inputValues?.warehouses?.find(
            (warehouse) => warehouse.id === formValues[input.name!]
          )?.name,
        }
      : undefined;
  } else if (input.type === "SALES_REP") {
    options =
      inputValues?.salesReps?.map((rep) => ({
        value: rep.id.toString(),
        label: rep.name,
      })) || [];
    selectValue = formValues[input.name!]
      ? {
          value: formValues[input.name!],
          label: input.options && input.options[formValues[input.name!]],
        }
      : undefined;
  } else if (input.type === "DOC_QUEUE") {
    options =
      queues?.map((queue) => ({
        value: queue.id?.toString(),
        label: queue.name,
      })) || [];

    selectValue = formValues[input.name!]
      ? {
          value: formValues[input.name!],
          label: queues?.find((queue) => queue.id == formValues[input.name!])
            ?.name,
        }
      : undefined;
  } else if (input.type === "LIST") {
    options = input.options
      ? Object.keys(input.options).map((key) => ({
          value: key,
          label: input.options![key],
        }))
      : [];
    selectValue = formValues[input.name!]
      ? {
          value: formValues[input.name!],
          label: input.options && input.options[formValues[input.name!]],
        }
      : undefined;
  } else if (input.type === "DOC_TAG") {
    options =
      inputValues?.docTags?.map((docTag) => ({
        value: docTag.tagId.toString(),
        label: docTag.tagName,
      })) || [];
    selectValue = formValues[input.name!]
      ? {
          value: formValues[input.name!],
          label: input.options && input.options[formValues[input.name!]],
        }
      : undefined;
  }

  return (
    <div key={input.name!} className="">
      {input && input.type === "DIVIDER" && <hr />}
      {(input.type === "WAREHOUSE" ||
        input.type === "LIST" ||
        input.type === "SALES_REP" ||
        input.type === "DOC_QUEUE" ||
        input.type === "DOC_TAG") && (
        <CustomSelect
          label={input.description}
          id={input.name!}
          isMulti={input.multiple}
          isSearchable={input.multiple}
          help={input.help}
          small={input.small}
          error={errors[input.name!]}
          required={input.required}
          isClearable={!input.required}
          defaultValue={input.defaultValue}
          value={selectValue}
          options={options}
          onChange={(e: ValueLabelStringType[] | ValueLabelStringType) => {
            if (input.multiple && Array.isArray(e)) {
              handleInputChange(
                setFormValues,
                navigate,
                input.name!,
                "",
                e.map((a) => a.value),
                input.multiple
              );
            } else if (e && typeof e === "object" && "value" in e) {
              handleInputChange(
                setFormValues,
                navigate,
                input.name!,
                "",
                e.value,
                input.multiple
              );
            } else if (e === null) {
              handleInputChange(
                setFormValues,
                navigate,
                input.name!,
                "",
                "",
                input.multiple
              );
            }
          }}
        />
      )}
      {input.type === "DATE" && (
        <div
          key={input.name!}
          className={`relative flex rounded shadow dark:shadow-slate-600 ${
            input.small ? "w-[80px]" : "w-[240px]"
          }`}
        >
          {input.help && (
            <div className="absolute right-0 -top-3.5">
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content={input.help}
              >
                <HiOutlineQuestionMarkCircle color="gray" size={14} />
              </span>
            </div>
          )}
          <label
            htmlFor={input.name!}
            className="absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:bg-bgDarkColor transition-colors duration-500 dark:text-blue-200 px-1 left-1"
          >
            {input.description}
          </label>
          <select
            onChange={(e) => {
              handleInputChange(
                setFormValues,
                navigate,
                input.name!,
                "",
                e.target.value
              );
            }}
            value={""}
            className="w-[130px] h-[38px] border-[1px] border-r-0 border-[#cccccc] dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200 p-1 rounded-l-[4px]"
          >
            <option value={""}>Dynamic date</option>
            {dateSelectOption.map((option, optionIndex) => (
              <option key={optionIndex} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {formValues[input.name!] !== "" &&
          (/\d/.test(formValues[input.name!]) || !formValues[input.name!]) ? (
            <Datepicker
              displayFormat={"DD/MM/YY"}
              placeholder="dd/mm/yy"
              useRange={false}
              asSingle={true}
              value={value}
              showShortcuts={true}
              toggleClassName="absolute right-0 h-full text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed pr-1"
              inputClassName="w-full border-[#cccccc] h-[38px] rounded-r-md focus:ring-0 font-normal border-[1px] text-gray-700 dark:text-gray-200 py-[9.5px] pl-2 text-sm placeholder:text-gray-700 dark:placeholder:text-gray-200 dark:bg-bgDarkColor transition-colors duration-500"
              onChange={(e) => {
                handleInputChange(
                  setFormValues,
                  navigate,
                  input.name!,
                  "",
                  e?.startDate
                );
                setValue(e);
              }}
            />
          ) : (
            <div className="relative w-full">
              <input
                type="text"
                id={input.name!}
                className="border-[1px] border-[#cccccc] h-[41px] dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200 rounded-r-[4px] p-2 w-full"
                required={input.required}
                value={getLabelByValue(formValues[input.name!])}
                disabled
              />
              <button
                onClick={() =>
                  handleInputChange(
                    setFormValues,
                    navigate,
                    input.name!,
                    "",
                    null
                  )
                }
                className="absolute right-0 top-0 h-[38px] pr-1 flex items-center justify-center cursor-pointer text-[#ccc] dark:text-gray-200 hover:text-red-500"
              >
                <FaTimes />
              </button>
            </div>
          )}
          {errors[input.name!] && (
            <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#575a48]] text-xs rounded-b-md p-1.5 pt-0.5">
              {errors[input.name!]}
            </div>
          )}
        </div>
      )}
      {input.type === "DATE_RANGE" && (
        <div>
          <DateRange
            title={input.description}
            name={input.name!}
            help={input.help}
            setFormValues={setFormValues}
            formValues={formValues}
          />
          {errors[input.name!] && (
            <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
              {errors[input.name!]}
            </div>
          )}
        </div>
      )}
      {input.type === "TEXT" && (
        <div className={`relative ${input.small ? "w-[80px]" : "w-[240px]"} `}>
          {input.help && (
            <div className="absolute right-0 -top-3.5">
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content={input.help}
              >
                <HiOutlineQuestionMarkCircle color="gray" size={14} />
              </span>
            </div>
          )}
          <label
            htmlFor={input.name!}
            className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:bg-bgDarkColor transition-colors duration-500 dark:text-blue-200 px-1 peer-focus:text-blue-600 focus:ring-3 left-1`}
          >
            {input.description}
          </label>
          <input
            type="text"
            id={input.name!}
            className="border-[1px] border-[#cccccc] h-[38px] hover:border-[#b3b3b3] shadow-md dark:shadow-slate-600 rounded-[4px] p-2 dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200 w-full"
            required={input.required}
            defaultValue={
              formValues[input.name!]
                ? Array.isArray(formValues[input.name!])
                  ? formValues[input.name!].join(" ").replaceAll(",", " ")
                  : formValues[input.name!].replaceAll(",", " ")
                : undefined
            }
            onChange={(e) => {
              handleInputChange(
                setFormValues,
                navigate,
                input.name!,
                "",
                e.target.value,
                true,
                true
              );
            }}
          />{" "}
          {errors[input.name!] && (
            <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
              {errors[input.name!]}
            </div>
          )}
        </div>
      )}
      {input.type === "NUMBER" && (
        <div
          className={`relative rounded shadow-md ${
            input.small ? "w-[80px]" : "w-[240px]"
          }`}
        >
          {input.help && (
            <div className="absolute right-0 -top-3.5">
              <span
                data-tooltip-id="my-tooltip"
                data-tooltip-content={input.help}
              >
                <HiOutlineQuestionMarkCircle color="gray" size={14} />
              </span>
            </div>
          )}
          <label
            htmlFor={input.name!}
            className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:bg-bgDarkColor transition-colors duration-500 dark:text-blue-200  px-1 peer-focus:text-blue-600 left-1`}
          >
            {input.description}
          </label>
          <input
            type="text"
            id={input.name!}
            className="border-[1px] border-[#cccccc] hover:border-[#b3b3b3] h-[38px] rounded-[4px] p-2  w-full dark:bg-bgDarkColor transition-colors duration-500 dark:text-gray-200"
            required={input.required}
            value={
              formValues[input.name!]
                ? Array.isArray(formValues[input.name!])
                  ? formValues[input.name!].join(" ").replaceAll(",", " ")
                  : formValues[input.name!].replaceAll(",", " ")
                : ""
            }
            onChange={(e) => {
              const rawValue = e.target.value;
              const value = rawValue.replaceAll(/[^0-9]/g, "");

              handleInputChange(
                setFormValues,
                navigate,
                input.name!,
                "",
                value,
                true,
                true
              );
            }}
          />
          {errors[input.name!] && (
            <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
              {errors[input.name!]}
            </div>
          )}
        </div>
      )}
      {input.type === "CONTACT" && (
        <CustomSelect
          id={input.name!}
          isExcluded={formValues?.items?.skuExclude}
          label={"Sku"}
          isClearable={!input.required}
          help={input.help}
          isMulti={input.multiple}
          defaultValue={defaultOptionsArray
            ?.filter((option) => option?.fieldName === input?.name)
            .map((option) => ({
              value: option?.value,
              label: option?.label,
            }))}
          error={errors[input.name!]}
          loadOptions={(value: string) => debouncedLoadContactOptions(value)}
          onChange={(e: string) => {
            handleInputChange(
              setFormValues,
              navigate,
              input.name!,
              "",
              e,
              input.multiple,
              undefined,
              true
            );
          }}
        />
      )}
      {input.type === "CHECKBOX" && (
        <div className="relative w-[106px] ml-1 mt-2.5 -pr-10 flex flex-col">
          <div className="flex items-center gap-1 absolute text-sm ml-2 -translate-y-[19px] translate-x-[5px] -left-5 -top-0.5 z-[9] origin-[0]">
            <label
              htmlFor={input.name!}
              className={`text-[#427dab] bg-white dark:bg-bgDarkColor transition-colors duration-500 dark:text-blue-200 px-1 peer-focus:text-blue-600`}
            >
              {input.description}
            </label>
            {input.help && (
              <div>
                <span
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={input.help}
                >
                  <HiOutlineQuestionMarkCircle color="gray" size={14} />
                </span>
              </div>
            )}
          </div>
          <input
            type="checkbox"
            id={input.name!}
            defaultChecked={formValues[input.name!] === "true"}
            className="h-[26px] w-[26px] float-left shadow-md dark:shadow-slate-600 dark:accent-blue-800"
            required={input.required}
            onChange={(e) => {
              handleInputChange(
                setFormValues,
                navigate,
                input.name!,
                "",
                e.target.checked,
                input.multiple
              );
            }}
          />
          {errors[input.name!] && (
            <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
              {errors[input.name!]}
            </div>
          )}
        </div>
      )}
      {input.type === "COLOR" && (
        <ColorSelector
          input={input}
          formValues={formValues}
          setFormValues={setFormValues}
          errors={errors}
        />
      )}{" "}
      <ReactTooltip id="my-tooltip" className="text-xs" />
    </div>
  );
};
