import { FC } from "react";
import { ReportScheduleInfo } from "../../api-client";
import { scheduleColumns } from "../../utils/columns";
import { ScheduleTableType } from "../../utils/columns";
import { EditSchedule } from "../schedule/EditSchedule";
import { DeleteSchedule } from "../schedule/DeleteSchedule";
import { DateFormatterCell } from "../tableHelpers/DateFormatterCell";
import { useTable, useResizeColumns, useFlexLayout } from "react-table";

export const ScheduleTable: FC<{
  data: ReportScheduleInfo[]
  getSchedules: () => void;
}> = ({ data, getSchedules }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns: scheduleColumns , data: data as ScheduleTableType[] },
      useResizeColumns,
      useFlexLayout
    );

  return (
    <div className="overflow-y-auto">
      <table
        {...getTableProps()}
        className="min-w-full divide-y  dark:divide-gray-500 dark:border-gray-500"
      >
        <thead className="bg-gray-50 dark:bg-gray-700 transition-colors duration-500">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="shadow-md p-2 text-left text-sm font-medium text-gray-500 tracking-wider border-r border-gray-300 dark:text-gray-300 dark:border-gray-500"
                >
                  {column.render("Header")}
                  <div
                    {...(column as any).getResizerProps()}
                    className="absolute right-0 top-0 bottom-0 z-20 cursor-col-resize w-[1px] hover:bg-black dark:hover:bg-gray-200 hover:w-[2px]"
                  />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-gray-200 dark:divide-gray-500 dark:bg-gray-900 transition-colors duration-500"
        >
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  let cellContent;

                  if (cell.column.id === "nextOccurringDateUtc" && cell.value) {
                    cellContent = <DateFormatterCell date={cell.value} />;
                  } else if (cell.column.id === "edit") {
                    cellContent = (
                      <EditSchedule
                        schedule={row.original}
                        getSchedules={getSchedules}
                      />
                    );
                  } else if (cell.column.id === "delete") {
                    cellContent = (
                      <DeleteSchedule
                        schedule={row.original}
                        getSchedules={getSchedules}
                      />
                    );
                  } else {
                    cellContent = cell.render("Cell");
                  }

                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`p-2  whitespace-nowrap border-r border-b border-gray-300 text-sm dark:text-gray-300 dark:border-gray-500 ${
                        (cell.column.id === "edit" ||
                          cell.column.id === "delete") &&
                        "text-left flex items-center justify-center pt-1"
                      }`}
                      key={cell.column.id}
                    >
                      {cellContent}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
