import { create } from "zustand";

interface DarkMode {
  darkMode: boolean;
  setDarkMode: (value: boolean) => void;
}

export const useDarkModeStore = create<DarkMode>((set) => ({
  darkMode: false,
  setDarkMode:(value: boolean) => set({ darkMode: value })
}));
