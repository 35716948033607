import { Chart, registerables } from "chart.js";
import { useEffect, useState, useRef, FC } from "react";
import { BySection } from "../../api-client";

Chart.register(...registerables);

interface BarVerticalChartProps {
  data: BySection[] | null | undefined;
  title: string;
}

export const BarVerticalChart: FC<BarVerticalChartProps> = ({
  data,
  title,
}) => {
  const makeId = (length: number): string => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };
  const [canvasId] = useState(() => makeId(10));
  const chartInstanceRef = useRef<Chart | null>(null);

  useEffect(() => {
    const ctx = document.getElementById(canvasId) as HTMLCanvasElement;

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const config = {
      type: "bar",
      data: {
        labels: data ? data.map((key) => key.name) : null,
        datasets: [
          {
            label: "Sales Value",
            tension: 0.4,
            backgroundColor: "#6e6ed7",
            borderColor: "#6e6ed7",
            data: data ? data.map((key) => key.salesValueGross) : null,
          },
          {
            label: "Items Sold",
            tension: 0.4,
            backgroundColor: "#f27b35",
            borderColor: "#f27b35",
            data: data ? data.map((key) => key.itemsSold) : null,
          },
          {
            label: "Order Count",
            tension: 0.4,
            backgroundColor: "#30c48d",
            borderColor: "#30c48d",
            data: data ? data.map((key) => key.orders) : null,
            hidden: true,
          },
        ],
      },
      options: {
        responsive: true,
        indexAxis: "y",
        maintainAspectRatio: false,
        color: "#94a3b8",
        scales: {
          y: {
            color: "#94a3b8",

            ticks: {
              color: "#94a3b8",
            },
          },
          x: {
            ticks: {
              color: "#94a3b8",
            },
          },
        },
        plugins: {
          legend: {
            position: "top",
            labels: {
              usePointStyle: true,
            },
          },
          title: {
            display: true,
            text: title,
            align: "start",
            color: "#9ca3af",
            font: {
              size: 20,
              family: "'Open Sans', sans-serif",
            },
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
        },
      },
    }; // @ts-ignore

    chartInstanceRef.current = new Chart(ctx.getContext("2d")!, config);

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [data, title, canvasId]);

  return (
    <div className="relative flex flex-col min-w-0 break-words lg:w-[100%] mb-6 shadow-md dark:shadow-slate-600 rounded-md bg-white dark:bg-[#334155] transition-colors duration-500">
      <div className="p-5 flex-auto">
        <div className="relative min-h-[350px] ">
          {data ? (
            <canvas id={canvasId} />
          ) : (
            <div role="status" className="space-y-2.5 animate-pulse">
              <div className="h-[350px] bg-gray-200 rounded-sm dark:bg-gray-500 w-full" />
            </div>
          )}
        </div>
        <canvas id={canvasId} className={`hidden w-0 h-0`} />
      </div>
    </div>
  );
};
