import { SketchPicker } from "react-color";
import { IoIosColorPalette } from "react-icons/io";
import { ColorSelectorProps } from "../../utils/type";
import { useState, useEffect, useRef, FC } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { handleInputChange } from "../../utils/functions";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";

export const ColorSelector: FC<ColorSelectorProps> = ({
  input,
  formValues,
  setFormValues,
  errors,
}) => {
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const colorPickerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(e.target as Node)
      ) {
        setShowPicker(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleClosePicker = () => {
    handleInputChange(
      setFormValues,
      undefined,
      input.name!,
      "",
      undefined,
      true,
      true
    );
    setShowPicker(false);
  };

  return (
    <div
      ref={colorPickerRef}
      className={`relative shadow-md dark:shadow-slate-600 w-[106px]`}
    >
      {input.help && (
        <div className="absolute right-0 -top-3.5">
          <span data-tooltip-id="my-tooltip" data-tooltip-content={input.help}>
            <HiOutlineQuestionMarkCircle color="gray" size={14} />
          </span>
          <ReactTooltip id="my-tooltip" className="text-xs" />
        </div>
      )}
      <label
        htmlFor={input.name!}
        className={`absolute text-sm text-[#427dab] -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white dark:bg-bgDarkColor dark:text-blue-200 transition-colors duration-500 px-1 peer-focus:text-blue-600 left-1`}
      >
        {input.description}
      </label>
      <button
        onClick={() => setShowPicker(true)}
        className="left-2 pl-1 border-[1px] border-[#cccccc] h-[41px] rounded-[4px] p-2 dark:bg-bgDarkColor dark:text-gray-200 w-full flex gap-1 items-center"
      >
        <IoIosColorPalette size={20} />
        <p className="mb-0">{formValues[input.name!]}</p>
      </button>
      {showPicker && (
        <div className="absolute z-10 bg-white dark:bg-bgDarkColor transition-colors duration-500 shadow-md dark:shadow-slate-600 border dark:border-gray-500 rounded">
          <SketchPicker
            disableAlpha
            color={formValues[input.name!] ? formValues[input.name!] : undefined}
            className="shadow-none dark:bg-bgDarkColor "
            onChange={(e) => {
              handleInputChange(
                setFormValues,
                undefined,
                input.name!,
                "",
                e.hex,
                input.multiple
              );
            }}
          />{" "}
          <div className="flex justify-between pt-0 p-3 gap-2">
            <button
              className="p-2 rounded bg-red-600 hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-700 text-gray-100 shadow-md dark:shadow-slate-600 font-semibold w-[70px] text-sm"
              onClick={handleClosePicker}
            >
              Cancel
            </button>
            <button
              disabled={!formValues[input.name!]}
              onClick={() => setShowPicker(false)}
              className="p-2 rounded disabled:bg-gray-400 dark:disabled:bg-gray-400 bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 text-gray-100 shadow-md dark:shadow-slate-600 font-semibold w-[70px] text-sm"
            >
              Select
            </button>
          </div>
        </div>
      )}
      {errors[input.name!] && (
        <div className="error-help-block text-red-500 text-sm">
          {errors[input.name!]}
        </div>
      )}
    </div>
  );
};
