import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import {
  InventoryService,
  ItemInventoryDrilldownListResults,
} from "../../../api-client";
import {
  drillDownTypes,
  handleColumnRounded,
  handleError,
} from "../../../utils/functions";
import { useState, FC } from "react";
import ReactLoading from "react-loading";
import { DrillDownTable } from "./DrilldownTable";
import {
  CellContentSummary,
  getCellClassName,
} from "../../tableHelpers/CellContentSummary";
import { SummaryTableProps } from "../../../utils/type";
import { useTable, useFlexLayout, useResizeColumns } from "react-table";

export const SummaryTable: FC<SummaryTableProps> = ({
  data,
  columns,
  sku,
  getInventoryLog,
}) => {
  const [drillDownWarehouseId, setDrillDownWarehouseID] = useState<string>("");
  const [drillDownType, setDrillDownType] = useState<string>("");
  const [drillDown, setDrillDown] = useState<
    ItemInventoryDrilldownListResults | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useResizeColumns,
      useFlexLayout,
      useResizeColumns
    );

  const getDrillDown = async (
    warehouseId: string = drillDownWarehouseId,
    type: string = drillDownType,
    offset: number = 0
  ): Promise<void> => {
    setLoading(true);
    setDrillDown(undefined);
    try {
      const response =
        await InventoryService.getInventoryItemItemskuInventoryDrilldownWarehouseid(
          {
            path: {
              itemSku: sku!,
              warehouseId: warehouseId,
            },
            query: {
              type: drillDownTypes[type],
              limit: 7,
              offset: offset,
            },
          }
        );

      if (response.error) {
        const { error, errors } = response.error;
        if (error || errors) {
          handleError(response.error);
          return;
        }
      }

      setDrillDown(response.data);
      setDrillDownWarehouseID(warehouseId);
      setDrillDownType(type);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  return (
    <div className="max-h-[500px] h-fit flex flex-col gap-2 overflow-y-auto">
      <div className="max-h-[260px] w-full max-w-[720px]">
        <table {...getTableProps()} className="shadow overflow-auto">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, columnIndex) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`${handleColumnRounded(
                      columnIndex,
                      columns.length
                    )} text-left p-2 py-1.5 text-xs font-medium text-gray-500 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 relative`}
                  >
                    {column.render("Header")}
                    <div
                      {...column.getResizerProps()}
                      className="absolute right-0 top-0 bottom-0 z-20 cursor-col-resize w-[1px] hover:bg-black dark:hover:bg-gray-200 hover:w-[2px]"
                      style={{ touchAction: "none" }}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white dark:bg-gray-900 dark:text-gray-100"
          >
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              const firstNullWarehouseIndex = rows.findIndex(
                (row) => row.original.warehouse === null
              );
              const isLastRow = rowIndex === rows.length - 1;

              return (
                <tr
                  {...row.getRowProps()}
                  className={
                    isLastRow
                      ? "bg-blue-100 dark:bg-black"
                      : "bg-white dark:bg-bgDarkColor"
                  }
                >
                  {row.cells.map((cell, cellIndex) => {
                    const isLastColumn = cellIndex === row.cells.length - 1;
                    const isFirstColumn = cellIndex === 0;

                    return (
                      <td
                        {...cell.getCellProps()}
                        className={getCellClassName(
                          rowIndex,
                          firstNullWarehouseIndex,
                          isLastRow,
                          isLastColumn,
                          isFirstColumn
                        )}
                      >
                        <CellContentSummary
                          cell={cell}
                          row={row}
                          getDrillDown={getDrillDown}
                          getInventoryLog={getInventoryLog}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!loading ? (
        drillDown && (
          <div className="border border-gray-300 dark:border-gray-500">
            {drillDown.results && <DrillDownTable data={drillDown.results} />}
            {drillDown?.results && drillDown?.results.length > 0 ? (
              <div className="flex items-center justify-center gap-3 text-sm my-1">
                <button
                  onClick={() =>
                    getDrillDown(
                      undefined,
                      undefined,
                      (drillDown.offset || 0) - 5
                    )
                  }
                  className="disabled:opacity-30"
                  disabled={drillDown.offset === 0}
                >
                  <MdOutlineKeyboardDoubleArrowLeft size={20} />
                </button>
                <span>{(drillDown.offset || 0) / 5 + 1}</span>{" "}
                <button
                  disabled={
                    (drillDown.totalCount || 0) <= (drillDown.offset || 0) + 5
                  }
                  className="disabled:opacity-30"
                  onClick={() =>
                    getDrillDown(
                      undefined,
                      undefined,
                      5 + (drillDown.offset || 0)
                    )
                  }
                >
                  <MdOutlineKeyboardDoubleArrowRight size={20} />
                </button>
              </div>
            ) : (
              <div className="w-full flex justify-center my-2">
                <p className="text-gray-600 dark:text-gray-300 text-sm">
                  No data available
                </p>
              </div>
            )}
          </div>
        )
      ) : (
        <div className="h-[240px] flex justify-center items-center">
          <ReactLoading
            type={"spinningBubbles"}
            color="#4273B8"
            height={150}
            width={150}
          />
        </div>
      )}
    </div>
  );
};
