import {
  TiArrowUnsorted,
  TiArrowSortedDown,
  TiArrowSortedUp,
} from "react-icons/ti";
import {
  asInventory,
  asMoney,
  downloadExcel,
  downloadCSV,
} from "../../utils/functions";
import "react-resizable/css/styles.css";
import { useVirtual } from "react-virtual";
import { useSticky } from "react-table-sticky";
import "@blueprintjs/core/lib/css/blueprint.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useMemo, useRef, FC, useCallback } from "react";
import type { DataTableProps, RowType } from "../../utils/type";
import { ReportTableCell } from "../tableHelpers/ReportTableCell";
import { useTable, useResizeColumns, useSortBy } from "react-table";
import { FaFileExcel, FaFileCsv, FaRegCalendarAlt } from "react-icons/fa";

export const ReportDataTable: FC<DataTableProps> = ({
  data,
  columns,
  setShowScheduleModal,
  name,
  isForecast,
  period,
  parametersString,
}) => {
  const scrollDivRef = useRef<HTMLDivElement | null>(null);

  const filteredColumns = useMemo(() => {
    return columns
      .map((col, index) => ({
        ...col,
        sticky: index === 0 ? "left" : col.sticky === "right" ? "right" : "",
      }))
      .filter((col) => col.accessor && !col.exportOnly);
  }, [columns]);

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: filteredColumns,
      data,
      defaultColumn: { width: 80 },
    },
    useResizeColumns,
    useSortBy,
    useSticky
  );
  const estimatedSize = isForecast ? 102 : 32.5;

  const rowVirtualizer = useVirtual({
    parentRef: scrollDivRef,
    size: rows.length,
    overscan: 30,
    estimateSize: useCallback(() => estimatedSize, []),
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const calculateLastRow = (
    rows: RowType,
    accessor: string,
    summaryType: string,
    columnType: string
  ) => {
    const values = rows.map((row) => row.original[accessor]);
    const numericValues = values.map((value: string) => Number(value) || 0);

    switch (summaryType.toUpperCase()) {
      case "COUNT":
        return rows.length + " Rows";
      case "SUM":
        var value = numericValues.reduce(
          (sum: number, value: number) => sum + value,
          0
        );
        if (columnType === "INVENTORY") {
          return asInventory(value);
        } else if (columnType === "MONEY") {
          return asMoney(value);
        }
        return value.toFixed(2);
      case "AVERAGE":
        const average =
          numericValues.reduce((sum: number, value: number) => sum + value, 0) /
          values.length;
        return isNaN(average) ? "N/A" : average.toFixed(2);
      default:
        return "";
    }
  };

  return (
    <div>
      <div className="-mt-[60px] ml-1 mr-1 border border-gray-300 rounded mb-2">
        <div className="p-2.5 flex items-center justify-between">
          <div className="flex flex-wrap gap-2 sm:gap-5 items-center text-sm">
            <button
              onClick={() =>
                downloadExcel(columns, data, name, false, isForecast)
              }
              className="flex shadow-md gap-2 items-center bg-green-500 hover:bg-green-600 dark:bg-green-700 dark:hover:bg-green-800 text-white font-semibold p-2 py-1.5 rounded"
            >
              <FaFileExcel size={18} />
              Export to Excel
            </button>
            <button
              onClick={() =>
                downloadCSV(columns, data, name, false, isForecast)
              }
              className="flex shadow-md gap-2 items-center bg-indigo-500 hover:bg-indigo-600 dark:bg-indigo-700 dark:hover:bg-indigo-800 text-white font-semibold p-2 py-1.5 rounded"
            >
              <FaFileCsv size={18} /> Export to CSV
            </button>
            <button
              onClick={() => setShowScheduleModal(true)}
              className="flex shadow-md gap-2 items-center bg-blue-500 hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 text-white font-semibold p-2 py-1.5 rounded"
            >
              <FaRegCalendarAlt size={18} /> Schedule report
            </button>
          </div>
        </div>
        <div ref={scrollDivRef} className="overflow-y-auto max-h-[800px]">
          <table
            {...getTableProps()}
            className="dark:bg-black transition-colors duration-500 w-full border-separate border-spacing-0 pr-0.5"
          >
            <thead className="bg-gray-100 dark:bg-gray-700 transition-colors duration-500 border-t border-gray-200 dark:border-gray-500">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        ...column.getHeaderProps().style,
                        width: column.width,
                      }}
                      className={`text-left p-2 text-xs font-medium text-gray-500 dark:text-gray-300 bg-gray-100 dark:bg-gray-700 transition-colors duration-500 border-gray-300 border ${
                        column.boxShadowLeft
                          ? "shadow-tableLeft dark:shadow-darkTableLeft"
                          : "shadow-tableRight dark:shadow-darkTableRight"
                      } ${index === 0 ? "border-l" : "border-l-0"}`}
                    >
                      <div className="flex gap-1 items-center">
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TiArrowSortedUp />
                            ) : (
                              <TiArrowSortedDown />
                            )
                          ) : (
                            <TiArrowUnsorted />
                          )}
                        </span>
                      </div>
                      <div
                        {...column.getResizerProps()}
                        className="absolute right-0 top-0 bottom-0 z-20 cursor-col-resize w-[1px] hover:bg-black hover:w-[2px]"
                        style={{ touchAction: "none" }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white dark:bg-gray-900 transition-colors duration-500 body">
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              )}
              {rowVirtualizer.virtualItems.map((virtualRow) => {
                const row = rows[virtualRow.index];
                prepareRow(row);

                return (
                  <ReportTableCell
                    key={virtualRow.index}
                    row={row}
                    length={rows.length}
                    virtualRow={virtualRow}
                    columns={columns}
                    isForecast={isForecast}
                    period={period}
                    parametersString={parametersString}
                  />
                );
              })}
              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              )}
              <tr className="sticky bottom-0 z-10 bg-white dark:bg-black transition-colors duration-500">
                {columns.map((column, columnIndex) => (
                  <td
                    key={columnIndex}
                    className={`p-1.5 font-medium text-xs whitespace-nowrap bg-white dark:bg-black border-t-0 border-b border-gray-300 transition-colors duration-500 dark:text-gray-100 ${
                      !isForecast ? "border-r " : ""
                    } ${
                      columnIndex === 0
                        ? "sticky left-0 shadow-[1px_-1px_5px_-1px_rgba(100,100,100,1)] dark:shadow-[0px_-1px_5px_-1px_rgba(255,255,255,0.8)] border-r border-l"
                        : "left-0 shadow-[1px_-1px_5px_-1px_rgba(100,100,100,1)] dark:shadow-[0px_-1px_5px_-1px_rgba(255,255,255,0.8)]"
                    }
                    `}
                  >
                    {column.summary
                      ? calculateLastRow(
                          rows,
                          column.accessor,
                          column.summary,
                          column.type
                        )
                      : ""}

                    <div className="absolute left-0 top-0 w-full h-[0.2px] bg-gray-400"></div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ReactTooltip id="name" className="text-xs px-2 z-[100]" />
      <ReactTooltip id="percentage" className="text-xs px-2 z-[100]" />
    </div>
  );
};
