import { FC } from "react";
import { useState } from "react";
import ReactLoading from "react-loading";
import { Input } from "../reportInputsHelper/Input";
import { WidgetFormProps } from "../../utils/type";
import { handleSuccess, handleError } from "../../utils/functions";
import { PropertyAttribute, ReportsService } from "../../api-client";

export const AddWidgetForm: FC<WidgetFormProps> = ({
  widget,
  closeModal,
  queues,
  widgetId,
  formValues,
  setFormValues,
  dashboardId,
  getDashboardWidget,
}) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const handleConfirmButton = async () => {
    setLoading(true);
    let validationErrors: { [key: string]: string } = {};
    widget.parameters?.forEach((input) => {
      const value = formValues[input.name!];
      if (input.required) {
        if (input.type === "DATE_RANGE") {
          if (
            (!value?.start && !value?.dynamicStartingDate) ||
            (!value?.end && !value?.dynamicEndingDate)
          ) {
            validationErrors[input.name!] = `${input.description} is required`;
          }
        } else if (!value) {
          validationErrors[input.name!] = `${input.description} is required`;
        }
      }
    });

    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      setLoading(false);

      return;
    }

    try {
      const parameters = Object.entries(formValues).map(([name, value]) => ({
        name,
        value: value !== null ? value.toString() : null,
      }));

      const editResponse =
        await ReportsService.postReportsKpiDashboardDashboardidWidgetWidgetid({
          path: { dashboardId: dashboardId!, widgetId: widgetId! },
          body: { type: widget.type!, parameters },
        });

      if (editResponse.error) {
        handleError(editResponse.error);
        return;
      }

      handleSuccess(`Widget ${widgetId ? "updated" : "created"}`, false);
      if (dashboardId) {
        getDashboardWidget(dashboardId);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
    closeModal();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 z-[100]">
      <div className="fixed top-[50%] left-[50%] w-[350px] md:w-[550px] -translate-x-1/2 -translate-y-1/2 text-gray-700 bg-white rounded-md z-[100]">
        <div className="border border-mainColor dark:bg-bgDarkColor transition-colors duration-500 rounded-md shadow-2xl dark:shadow-slate-600">
          <div className="bg-mainColor dark:bg-mainColorDarkMode transition-colors duration-500 p-2 text-gray-100">
            <h2 className="p-1 text-2xl">{widget.name}</h2>
          </div>
          <div className="p-4 flex flex-wrap gap-7 mt-3 justify-center sm:justify-start">
            {widget.parameters?.map((params) => (
              <div key={params.name}>
                <Input
                  errors={errors}
                  input={params as PropertyAttribute}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  navigate={undefined}
                  queues={queues}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-between p-4 pt-2 gap-5">
            <button
              className="p-2 rounded bg-red-600 hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-700 text-gray-100 shadow-md dark:shadow-slate-600 font-semibold w-[100px]"
              onClick={closeModal}
            >
              Close
            </button>
            <button
              onClick={handleConfirmButton}
              disabled={loading}
              className="p-2 rounded bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 text-gray-100 disabled:bg-gray-500 shadow-md dark:shadow-slate-600 font-semibold w-[100px]"
            >
              {loading ? (
                <div className="flex justify-center">
                  <ReactLoading
                    type="spinningBubbles"
                    height={"22px"}
                    width={"22px"}
                    color="white"
                  />
                </div>
              ) : widgetId ? (
                "Edit"
              ) : (
                "Add"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
