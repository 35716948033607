import {
  ConfigurationAttribute,
  DocumentQueue,
  DocumentsService,
  KpiDashboard,
  KpiWidgetConfigDetail,
  ReportsService,
} from "../../api-client";
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import ReactLoading from "react-loading";
import { MdDelete } from "react-icons/md";
import { LuSettings } from "react-icons/lu";
import { useEffect, useState } from "react";
import type { FormValuesType } from "../../utils/type";
import { DashboardTable } from "../tables/DashboardTable";
import { DashboardForm } from "../dashboards/DashboardForm";
import { AddWidgetForm } from "../dashboards/AddWidgetForm";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { handleError, handleSuccess } from "../../utils/functions";

export const Dashboards = () => {
  const [dashboards, setDashboards] = useState<
    KpiDashboard[] | null | undefined
  >();
  const [showAddDashboard, setShowAddDashboard] = useState<boolean>(false);
  const { darkMode } = useDarkModeStore();
  const [selectedDashboard, setSelectedDashboard] = useState<
    string | undefined
  >();
  const [offset, setOffset] = useState<number>(0);
  const [listWidgetTypes, setListWidgetTypes] = useState<
    ConfigurationAttribute[] | null
  >();
  const [dashboardWidget, setDashboardWidget] = useState<
    KpiWidgetConfigDetail[] | undefined | null
  >([]);
  const [widgetFormInputs, setWidgetFormInputs] = useState<
    ConfigurationAttribute | null | undefined
  >();
  const [widgetFormParams, setWidgetFormParams] = useState<FormValuesType>({});
  const [queues, setQueues] = useState<DocumentQueue[] | null | undefined>();
  const [widgetId, setWidgetId] = useState<number>();

  const getDashboards = async () => {
    try {
      const dashboardsResponse = await ReportsService.getReportsKpiDashboard();

      if (dashboardsResponse.error) {
        handleError(dashboardsResponse.error);
        return;
      }

      const { results } = dashboardsResponse.data;

      setDashboards(results);
      if (results && results.length > 0) {
        setSelectedDashboard(results[0].id);
        getDashboardWidget(results[0].id);
      }
    } catch (error: any) {
      handleError(error);
    }
  };

  const getDashboardWidget = async (dashboardId: string | undefined) => {
    if (!dashboardId) {
      return;
    }
    try {
      const dashboardsWidgetResponse =
        await ReportsService.getReportsKpiDashboardDashboardidWidgets({
          path: { dashboardId: dashboardId },
        });

      if (dashboardsWidgetResponse.error) {
        handleError(dashboardsWidgetResponse.error);
        return;
      }

      setDashboardWidget(dashboardsWidgetResponse.data.results);
    } catch (error: any) {
      handleError(error);
    }
  };

  const getListWidgetType = async () => {
    try {
      const typeResponse =
        await ReportsService.getReportsKpiDashboardListWidgetTypes();

      if (typeResponse.error) {
        handleError(typeResponse.error);
        return;
      }

      setListWidgetTypes(typeResponse.data.results);
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const getQueues = async () => {
    try {
      const response = await DocumentsService.getDocumentsQueuesDocumenttype({
        path: {
          documentType: "all",
        },
      });

      if (response.error) {
        handleError(response.error);
        return;
      }

      setQueues(response.data?.results);
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const handleSelectDashboard = (dashboardId: string | undefined) => {
    getDashboardWidget(dashboardId);
    setSelectedDashboard(dashboardId);
  };

  const handleAddWidgetSelection = async (widgetType: string) => {
    const widgetParam = listWidgetTypes?.find(
      (widget) => widget.type === widgetType
    );

    if (widgetParam) {
      setWidgetFormInputs(widgetParam);
    }
  };

  const handleEditWidget = (widget: KpiWidgetConfigDetail) => {
    setWidgetId(widget.id);
    const params: { [key: string]: string } = {};
    widget.parameters?.forEach((parameter) => {
      params[parameter.name] = parameter.value?.toString() || "";
    });
    setWidgetFormParams(params);
    handleAddWidgetSelection(widget.type);
  };

  const handleDeleteWidget = async (widgetId: number | undefined) => {
    try {
      if (!selectedDashboard || !widgetId) {
        return;
      }
      const deleteResponse =
        await ReportsService.deleteReportsKpiDashboardDashboardidWidgetWidgetid(
          { path: { dashboardId: selectedDashboard, widgetId: widgetId } }
        );

      if (deleteResponse.error) {
        handleError(deleteResponse.error);
        return;
      }

      handleSuccess(`Widget deleted`, false);
      if (selectedDashboard) {
        getDashboardWidget(selectedDashboard);
      }
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const handleCloseForm = () => {
    setWidgetId(undefined);
    setWidgetFormParams({});
    setWidgetFormInputs(undefined);
  };

  useEffect(() => {
    getDashboards();
    getListWidgetType();
    getQueues();
  }, []);

  return (
    <div className="">
      {widgetFormInputs && (
        <AddWidgetForm
          queues={queues}
          widgetId={widgetId}
          dashboardId={selectedDashboard}
          widget={widgetFormInputs}
          formValues={widgetFormParams}
          setFormValues={setWidgetFormParams}
          closeModal={handleCloseForm}
          getDashboardWidget={getDashboardWidget}
        />
      )}
      {showAddDashboard && dashboards && (
        <DashboardForm
          dashboard={null}
          closeModal={() => setShowAddDashboard(false)}
          getDashboards={getDashboards}
        />
      )}
      <div className="w-full transition-colors duration-500 rounded-md shadow-md dark:shadow-slate-600">
        <div className="bg-mainColor dark:bg-mainColorDarkMode transition-colors duration-500 rounded-t-md p-2 flex flex-col xs2:flex-row items-center justify-between">
          <h1 className="text-[25px] pl-3 text-gray-100">Dashboards</h1>
          <div>
            <button
              onClick={() => setShowAddDashboard(true)}
              className="flex gap-1 items-center bg-blue-400 hover:bg-blue-500 dark:bg-blue-600 dark:hover:bg-blue-700 text-white rounded p-1.5"
            >
              <FaPlus />
              Add Dashboard
            </button>
          </div>
        </div>
        <div>
          {dashboards ? (
            dashboards.length > 0 ? (
              <div>
                <DashboardTable
                  data={dashboards.slice(offset, offset + 5)}
                  selectedDashboard={selectedDashboard}
                  handleSelectDashboard={handleSelectDashboard}
                  getDashboards={getDashboards}
                />
                <div className="flex items-center justify-center py-1.5 bg-white rounded-b-md gap-1 dark:text-gray-200 dark:bg-gray-800 transition-colors duration-500">
                  <button
                    onClick={() => setOffset(offset - 5)}
                    className="disabled:opacity-30"
                    disabled={offset === 0}
                  >
                    <MdOutlineKeyboardDoubleArrowLeft size={20} />
                  </button>
                  <span>{offset / 5 + 1}</span>
                  <button
                    disabled={offset + 5 >= dashboards.length}
                    className="disabled:opacity-30"
                    onClick={() => setOffset(offset + 5)}
                  >
                    <MdOutlineKeyboardDoubleArrowRight size={20} />
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-full my-10 flex justify-center">
                <p className="text-2xl text-gray-600">
                  No dashboards have been created yet.
                </p>
              </div>
            )
          ) : (
            <div className="w-full h-[30vh] flex justify-center">
              <ReactLoading
                type="spinningBubbles"
                height={"200px"}
                width={"200px"}
                color="#4273B8"
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 w-full rounded-t-md shadow-md dark:shadow-slate-600 rounded-md">
        <div className="bg-mainColor dark:bg-mainColorDarkMode transition-colors duration-500 rounded-t-md p-2 flex flex-col xs2:flex-row items-center justify-between">
          <h1 className="text-[25px] pl-3 text-gray-100">
            {
              dashboards?.find(
                (dashboard) => dashboard.id === selectedDashboard
              )?.title
            }{" "}
            Dashboard
          </h1>
          <div>
            <select
              value="add-widget"
              onChange={(e) => handleAddWidgetSelection(e.target.value)}
              className="w-[120px] cursor-pointer flex gap-1 items-center bg-blue-400 hover:bg-blue-500 dark:bg-blue-600 dark:hover:bg-blue-700 text-white rounded p-1.5"
            >
              <option value="add-widget">Add Widget</option>
              {listWidgetTypes?.map((type) => (
                <option key={type.type} value={type.type!}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {dashboardWidget && dashboardWidget.length ? (
          <div className="flex flex-wrap gap-3 bg-white rounded-b-md dark:bg-bgDarkColor transition-colors duration-500 p-4">
            {dashboardWidget.map((widget) => (
              <div
                key={widget.id}
                className="p-2 border rounded-lg shadow-md dark:shadow-slate-600 w-[250px]"
              >
                <div className="flex gap-1 items-center justify-between border-b pb-1">
                  <p className="text-lg font-semibold mb-0 text-gray-700 dark:text-gray-300">
                    {widget.title}
                  </p>
                  <div className="flex items-center justify-end">
                    <button
                      className="mr-3 text-gray-600 hover:text-gray-800 dark:text-gray-300 focus:outline-none"
                      onClick={() => handleEditWidget(widget)}
                    >
                      <LuSettings size={20} />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-700 dark:text-red-400 focus:outline-none"
                      onClick={() => handleDeleteWidget(widget.id)}
                    >
                      <MdDelete size={20} />
                    </button>
                  </div>
                </div>
                <p className="text-lg font-semibold mb-0 text-gray-700 dark:text-gray-300 pt-1">
                  {widget.type}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-[100px] flex justify-center items-center bg-white rounded-b-md dark:bg-bgDarkColor transition-colors duration-500">
            <p className="text-gray-700 dark:text-gray-300 text-xl text-center">
              No widgets available in the selected dashboard.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
