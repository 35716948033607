import {
  ItemInventory3dSummary,
  ItemInventoryDrilldown,
  ItemInventoryLog,
  ItemInventoryWithWarehouse,
  ItemQuickViewResults,
  ReportScheduleInfo,
  SalesReportDrillDownQty,
} from "../api-client";
import { plural } from "./pluralize";
import { Column, Row } from "react-table";
import { customSorting } from "./functions";
import { PiGaugeBold } from "react-icons/pi";
import { as2decimals, as3decimals } from "./functions";
import { PrintItemType, TableColumnType, TurnoverTableType } from "./type";
import { DateFormatterCell } from "../components/tableHelpers/DateFormatterCell";

export const inventoryColumns: Column<ItemInventoryWithWarehouse>[] = [
  {
    Header: "Warehouse",
    accessor: (row) => row.warehouse?.name || "Total",
    width: 80,
  },
  {
    Header: "Stock",
    accessor: "onHand",
    width: 80,
  },
  {
    Header: "Available",
    accessor: "available",
    width: 70,
  },
  {
    Header: "Uninvoiced",
    accessor: "uninvoiced",
    width: 75,
  },
  {
    Header: "Reserved",
    accessor: "reserved",
    width: 70,
  },
  {
    Header: "On Order",
    accessor: "onOrder",
    width: 71,
  },
  {
    Header: "BTB PO",
    accessor: "backToBack",
    width: 60,
  },
  {
    Header: "Blocked",
    accessor: "blocked",
    width: 60,
  },
  {
    Header: "TRN",
    accessor: "inTransit",
    width: 35,
  },
  {
    Header: "Cost",
    accessor: (row) => {
      const formattedCost =
        row.cost !== null
          ? `${as2decimals(row.cost?.amount)} ${
              row.cost?.currencyCode !== null ? row.cost?.currencyCode : ""
            }`
          : "";
      const formattedCostLcy =
        row.cost?.amountLcy && row.cost?.amountLcy > 0
          ? `(${as2decimals(row.cost?.amountLcy)} GBP)`
          : "";

      const formattedLandedCost =
        row.landedCost !== null && row.landedCost?.amount !== row.cost?.amount
          ? `Landed: ${as2decimals(row.landedCost?.amount)} ${
              row.landedCost?.currencyCode
            }`
          : "";
      const formattedLandedCostLcy =
        row.landedCost?.amountLcy &&
        row.landedCost?.amountLcy > 0 &&
        row.landedCost.amountLcy !== row.cost?.amountLcy
          ? `(${as2decimals(row.landedCost?.amountLcy)} GBP)`
          : "";

      const formattedWac =
        row.wacValue !== null && row.wacValue !== row.cost?.amount
          ? `WAC: ${as2decimals(row.wacValue)} GBP`
          : "";

      return `${formattedCost} ${formattedCostLcy} | ${formattedLandedCost} ${formattedLandedCostLcy} | ${formattedWac}`;
    },
    width: 110,
  },
];

export type ScheduleTableType = ReportScheduleInfo & {
  edit: string;
  delete: string;
};

export const scheduleColumns: Column<ScheduleTableType>[] = [
  {
    Header: "ID",
    accessor: "id",
    width: 40,
  },
  {
    Header: "User",
    accessor: "user",
  },
  {
    Header: "Active",
    accessor: (row) => (row.isActive ? "Yes" : "No"),
    width: 60,
  },
  {
    Header: "Name",
    accessor: "name",
    width: 100,
  },
  {
    Header: "Frequency",
    accessor: "cronExpression",
    width: 200,
  },
  {
    Header: "Next Occ.",
    accessor: "nextOccurringDateUtc",
    width: 90,
  },
  {
    Header: "Email",
    accessor: "emailAddress",
  },

  {
    Header: "Columns removed",
    accessor: "removeColumns",
  },
  {
    Header: "Edit",
    accessor: "edit",
    width: 50,
  },
  {
    Header: "Delete",
    accessor: "delete",
    width: 65,
  },
];

export const drillDownColumns: Column<ItemInventoryDrilldown>[] = [
  {
    Header: "Document",
    accessor: "document",
    width: 100,
  },

  {
    Header: "Details",
    accessor: "details",
  },
  {
    Header: "Date",
    accessor: "date",
    width: 80,
  },
  {
    Header: "Quantity",
    id: "combinedQuantityInfo",
    width: 140,
    Cell: ({ row }: { row: Row<ItemInventoryDrilldown> }) => {
      const qty = row.original.qty;
      const qtyUom = row.original.qtyUom;
      var uomName = row.original.uomName;
      const baseQtyDiff = qty !== qtyUom;

      if (uomName && qtyUom && (qtyUom > 1 || qtyUom < 1)) {
        uomName = plural(uomName);
      }

      return (
        <div className="flex items-center gap-1 justify-between">
          {qtyUom ? (
            <p>
              {qtyUom} {uomName}
            </p>
          ) : (
            ""
          )}
          {baseQtyDiff ? <p className="text-[10px]">({qty})</p> : ""}
        </div>
      );
    },
  },
];

export const dashboardColumns: Column<any>[] = [
  {
    Header: "Dashboard Title",
    accessor: "title",
    width: 60,
  },
  {
    Header: "",
    accessor: "edit",
    width: 3,
  },
  {
    Header: "",
    accessor: "accessToken",
    width: 3,
    Cell: ({ row }) => {
      return (
        <a
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Go to Dashboard"
          className="flex justify-center"
          target="_blank"
          rel="noreferrer"
          href={`https://apps.dashboard.tradepeg.com/?token=${row.original.accessToken}`}
        >
          <PiGaugeBold size={22} color={"green"} />
        </a>
      );
    },
  },
  {
    Header: "",
    accessor: "delete",
    width: 3,
  },
];

export const inventoryLogColumns: Column<ItemInventoryLog>[] = [
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ row }) => <DateFormatterCell date={row.original.date} />,
    width: 70,
  },
  {
    Header: "Price",
    accessor: (row) => {
      const amount = row.price?.amount ?? "";
      const currencyCode = row.price?.currencyCode ?? "";
      return `${amount} ${currencyCode}`;
    },
  },
  { Header: "Weight", accessor: "weight", width: 50 },
  {
    Header: "Quantity",
    accessor: "qty",
    width: 180,
  },
  {
    Header: "Balance",
    accessor: "balanceUom",
    Cell: ({ row }) => (
      <div className="flex justify-between">
        {Number(row.original.balanceUom) !== 0 && (
          <p>{as3decimals(Number(row.original.balanceUom))} Cases</p>
        )}
        {row.original.balance && row.original.balance > 0 && (
          <p className="text-[10px]">({row.original.balance})</p>
        )}
      </div>
    ),
    width: 180,
  },
  {
    Header: "Batch",
    accessor: "batchInfo",
    width: 90,
  },
  { Header: "Bin", accessor: "bin", width: 90 },
  { Header: "Type", accessor: "document", width: 80 },
  { Header: "Doc", accessor: "rootDocument", width: 85 },
  { Header: "Partner", accessor: "documentDetails" },
];

export const inventoryHistoryColumns: Column<ItemQuickViewResults>[] = [
  {
    Header: "RCT",
    accessor: "document",
    Cell: ({ row }) => {
      return (
        <div className="flex justify-between">
          <p className="">{row.original.document}</p>
        </div>
      );
    },
  },
  {
    Header: "Supplier",
    accessor: "details",
  },
  {
    Header: "Quantity",
    accessor: "qty",
    Cell: ({ row }) => (
      <div className="flex gap-1">
        <p className="">{row.original.qty}</p>{" "}
        <p className="">
          {row.original.uomName}
          {row.original.qty && row.original.qty > 0 && "s"}
        </p>
      </div>
    ),
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: ({ row }) => (
      <p>
        {row.original.price?.amount} {row.original.price?.currencyCode}
      </p>
    ),
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ row }) => <DateFormatterCell date={row.original.date} />,
  },
];

export const printLabelColumn: Column<PrintItemType>[] = [
  {
    Header: "Line",
    accessor: (row) => row.id,
    width: 30,
  },

  {
    Header: "Part Name",
    accessor: (row) => row.item.title,
    width: 200,
  },
  /*   {
    Header: "Brand",
    accessor: (row) => row.item.,

    accessor: "brand",
    width: 70,
  },
  {
    Header: "EAN",
    accessor: "ean",
    width: 120,
  }, */ {
    Header: "SKU",
    accessor: (row) => row.item.sku,

    width: 120,
  },
];

export const forecastColumns: TableColumnType[] = [
  {
    Header: "Forecast (PP)",
    accessor: "forecastResults.perPeriodConsumed",
    width: 65,
    type: "TEXT",
    sticky: "right",
    boxShadowLeft: true,
    sortType: customSorting,
  },
  {
    Header: "Inventory",
    accessor: "forecastResults.currenctInventory",
    type: "TEXT",
    width: 90,
    sticky: "right",
    sortType: customSorting,
  },
  {
    Header: "Duration",
    accessor: "forecastResults.inventoryDuration",
    width: 65,
    type: "TEXT",
    sticky: "right",
    sortType: customSorting,
  },
  {
    Header: "Lead Time",
    accessor: "forecastResults.leadTimeRequired",
    type: "TEXT",
    width: 60,
    sticky: "right",
    sortType: customSorting,
  },
  {
    Header: "Required",
    accessor: "forecastResults.inventoryRequired",
    width: 70,
    type: "TEXT",
    sticky: "right",
    sortType: customSorting,
  },
];

export const orderDrilldownColumns: Column<SalesReportDrillDownQty>[] = [
  {
    Header: "Warehouse",
    accessor: "warehouseName",
    width: 150,
  },

  {
    Header: "Document",
    accessor: "document",
    width: 90,
  },
  {
    Header: "Date",
    accessor: "date",
    width: 70,
  },
  {
    Header: "Customer",
    accessor: "contactName",
    width: 140,
  },
  {
    Header: "Quantity",
    accessor: "qty",
    width: 60,
  },
  {
    Header: "Unit",
    accessor: "uomName",
    width: 70,
  },
  {
    Header: "Price",
    accessor: "netPrice",
    width: 70,
  },
];

export const binColumns: Column<ItemInventory3dSummary>[] = [
  {
    Header: "Warehouse",
    accessor: (row) => row.warehouse?.name,
    width: 120,
  },
  {
    Header: "On-hand",
    accessor: "onHand",
    width: 70,
  },
  {
    Header: "Bin",
    accessor: "binLocation",
    width: 70,
  },
];

export const turnoverColumns: Column<TurnoverTableType>[] = [
  {
    Header: "Month",
    accessor: "month",
    width: 100,
  },
  {
    Header: "In",
    accessor: "in",
    width: 100,
  },
  {
    Header: "Out",
    accessor: "out",
    width: 70,
  },
  {
    Header: "Confirmed",
    accessor: "confirmed",
    width: 70,
  },
  {
    Header: "Invoiced",
    accessor: "invoiced",
    width: 70,
  },
  {
    Header: "Returns",
    accessor: "returns",
    width: 70,
  },
];
