import { BinForm } from "../BinForm";
import { FaPrint } from "react-icons/fa";
import { FC, useMemo, useState } from "react";
import { binColumns } from "../../../utils/columns";
import { useTable, useFlexLayout, Column } from "react-table";
import { ItemInventory3dSummary } from "../../../api-client";
import { DateFormatterCell } from "../../tableHelpers/DateFormatterCell";

export const BinTable: FC<{
  data: ItemInventory3dSummary[];
  itemSku: string | undefined;
}> = ({ data, itemSku }) => {
  const columns: Column<ItemInventory3dSummary>[] = useMemo(() => {
    const baseColumns: Column<ItemInventory3dSummary>[] = [...binColumns];

    const shouldAddColumns =
      data &&
      data.length > 0 &&
      data.some((item) => item.batchDetails !== null);

    if (shouldAddColumns) {
      baseColumns.push(
        {
          Header: "Batch",
          accessor: (row) => row.batchDetails?.name,
          width: 70,
        },
        {
          Header: "Best Before Date",
          accessor: (row) => row.batchDetails?.bestBeforeDate,
          width: 110,
        },
        {
          Header: "Production Date",
          accessor: (row) => row.batchDetails?.productionDate,
          width: 105,
        }
        /*         {
          Header: "Reserved",
          accessor: (row: BinType) => row.batchDetails?.productionDate,
          width: 105,
        } */
      );
    }

    return baseColumns;
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useFlexLayout);

  const [showBinForm, setShowBinForm] = useState<boolean>(false);
  const [bin, setBin] = useState<ItemInventory3dSummary>();
  const handleOpenForm = (bin: ItemInventory3dSummary): void => {
    setBin(bin);
    setShowBinForm(true);
  };
/*   const { setShowPrint, setPrintItemSku } = usePrintStore(); */

  return (
    <div>
      {showBinForm && bin && (
        <BinForm bin={bin} handleClose={() => setShowBinForm(false)} />
      )}
      <div className="w-full max-w-[720px] max-h-[200px] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full divide-y border-b-0 divide-gray-200 dark:divide-gray-500 dark:border-gray-500 table-auto"
        >
          <thead className="bg-gray-50 dark:bg-gray-700  ">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`${
                      columnIndex !== headerGroup.headers.length - 1 &&
                      "border-r"
                    } shadow relative p-2 text-left text-xs font-medium text-gray-500 border-b border-gray-300 flex dark:text-gray-300 dark:border-gray-500`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="bg-white divide-y divide-gray-200 dark:divide-gray-500 dark:bg-gray-900"
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  className={`divide-gray-200 dark:divide-gray-500 ${
                    row.original.quarantined
                      ? "bg-red-300 dark:bg-red-700"
                      : "bg-white dark:bg-bgDarkColor"
                  }`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, cellIndex) => {
                    const cellClass = `text-left text-xs p-1 text-gray-500 border-gray-300 dark:text-gray-100 dark:border-gray-500 truncate ${
                      cellIndex !== row.cells.length - 1 && "border-r"
                    }`;
                    const columnType = columns[cellIndex].Header;
                    if (
                      columnType === "Best Before Date" ||
                      columnType === "Production Date"
                    ) {
                      return (
                        <td {...cell.getCellProps()} className={cellClass}>
                          <DateFormatterCell date={cell.value} />
                        </td>
                      );
                    } else if (columnType === "Batch") {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`flex justify-between items-center ${cellClass}`}
                        >
                          <button
                            className="text-blue-500"
                            onClick={() => handleOpenForm(row.original)}
                          >
                            {cell.render("Cell")}
                          </button>
                          {row.original.batchDetails?.name && (
                            <button
                              onClick={() => {
                            /*     setShowPrint(true);
                                setPrintItemSku([itemSku] as string[]); */
                              }}
                            >
                              <FaPrint color="green" size={12} />
                            </button>
                          )}
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()} className={cellClass}>
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
