import { FC } from "react";
import { components } from "react-select";
import { useNavigate } from "react-router-dom";
import { handleInputChange } from "../../utils/functions";
import { TbFilterOff, TbFilterCheck } from "react-icons/tb";
import { CustomContainerProps } from "../../utils/type";

export const CustomContainer: FC<CustomContainerProps> = ({
  formValues,
  section,
  keyToUpdate,
  children,
  rest,
  setFormValues,
}) => {
  const excludeKey = keyToUpdate + "Exclude";
  const navigate = useNavigate();

  return (
    <components.Control {...rest}>
      <button
        className="pl-1 pt-[1px]"
        onMouseDown={(e) => {
          e.stopPropagation();
          const newUpdatedValue = !formValues[section][excludeKey];
          handleInputChange(
            setFormValues,
            navigate,
            excludeKey,
            section,
            newUpdatedValue
          );
        }}
      >
        {formValues[section] && formValues[section][excludeKey] ? (
          <TbFilterOff color="red" />
        ) : (
          <TbFilterCheck color="green" />
        )}
      </button>
      {children}
    </components.Control>
  );
};
