import { FC } from "react";
import { useTable, useFlexLayout } from "react-table";
import { drillDownColumns } from "../../../utils/columns";
import { ItemInventoryDrilldown } from "../../../api-client";
import { useUserInfoStore } from "../../../utils/store/userStore";
import { DateFormatterCell } from "../../tableHelpers/DateFormatterCell";

export const DrillDownTable: FC<{
  data: ItemInventoryDrilldown[];
}> = ({ data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: drillDownColumns, data }, useFlexLayout);
  const { userInfo } = useUserInfoStore();

  return (
    <div className="w-full max-w-[720px] overflow-x-auto">
      <table
        {...getTableProps()}
        className="w-full divide-y border-b divide-gray-200 dark:divide-gray-500 dark:border-gray-500 table-auto"
      >
        <thead className="bg-gray-50 dark:bg-gray-700  ">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  className={`${
                    columnIndex !== headerGroup.headers.length - 1 && "border-r"
                  } shadow relative p-2 text-left text-xs font-medium text-gray-500 border-b border-gray-300 flex dark:text-gray-300 dark:border-gray-500`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="bg-white divide-y divide-gray-200 dark:divide-gray-500 dark:bg-gray-900"
        >
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr
                className="bg-white dark:bg-bgDarkColor divide-gray-200 dark:divide-gray-500"
                {...row.getRowProps()}
              >
                {row.cells.map((cell, cellIndex) => {
                  const columnType = drillDownColumns[cellIndex].accessor;
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`text-left text-xs p-1 text-gray-500 border-gray-300 dark:text-gray-100 dark:border-gray-500 ${
                        cellIndex !== row.cells.length - 1 && "border-r"
                      }`}
                    >
                      {columnType === "date" ? (
                        <DateFormatterCell date={cell.value} />
                      ) : columnType === "document" ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:bg-blue-100 hover:text-blue-600 cursor-pointer"
                          href={`${userInfo?.organization?.appUrl}go/${row.original.documentType}/${row.original.documentId}`}
                        >
                          {cell.render("Cell")}
                        </a>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
