import { useEffect, KeyboardEvent } from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import { useDarkModeStore } from "../../utils/store/darkModeStore";

export const DarkModeToggle = () => {
  const { darkMode, setDarkMode } = useDarkModeStore();

  const toggle = () => {
    setDarkMode(!darkMode);

    if (darkMode) {
      localStorage.theme = "light";
      document.documentElement.classList.remove("dark");
      setDarkMode(false);
    } else {
      localStorage.theme = "dark";
      document.documentElement.classList.add("dark");
      setDarkMode(true);
    }
  };

  const handleClick = () => {
    toggle();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") toggle();
  };

  useEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  return (
    <div
      role="checkbox"
      aria-checked={darkMode ? "true" : "false"}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      className={`cursor-pointer w-11 h-5 transition-colors duration-500 ${
        darkMode ? "bg-black" : "bg-white justify-end"
      } rounded-full relative px-1.5 flex items-center border shadow-md dark:shadow-slate-600`}
    >
      <div
        className={`w-4 h-4 text-[12.5px] top-[2.5px] rounded-full absolute transform duration-200 ease-out left-0.5 ${
          darkMode ? "translate-x-6 text-[#9da9bb]" : "translate-x-0"
        }`}
      >
        {darkMode ? (
          <span>
            <FaMoon />
          </span>
        ) : (
          <span className="text-[14px] text-yellow-400">
            <FaSun />
          </span>
        )}
      </div>
    </div>
  );
};
