import { FC } from "react";
import type { StatCardProps } from "../../utils/type";
import { abbreviateNumber } from "../../utils/functions";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";

export const CardStat: FC<StatCardProps> = ({
  currencySymbol,
  dateRange,
  result,
  previous,
  statTitle,
}) => {
  let percentage: number | null = null;

  if (result && previous) {
    if (result > previous) {
      percentage = Math.round(((result - previous) / previous) * 100);
    } else {
      percentage = Math.round(((previous - result) / previous) * 100);
    }
  }

  const dateRangeText = () => {
    switch (dateRange) {
      case "dtd":
        return "Compared to yesterday";
      case "wtd":
        return "Compared to last week";
      case "mtd":
        return "Compared to last month";
      case "qtd":
        return "Compared to last quarter";
      case "ytd":
        return "Compared to last year";
      default:
        return "";
    }
  };

  return (
    <div className="relative flex flex-col justify-between min-w-0 overflow-hidden w-full sm:w-[300px] md:w-[100%] lg:w-full break-words bg-white dark:bg-[#334155] transition-colors duration-500 rounded mb-6 xl:mb-0 shadow-md dark:shadow-slate-600">
      <div className="flex flex-col items-center p-3.5 gap-3">
        <div className="self-start">
          {statTitle ? (
            <h5 className=" text-[13.5px] md:text-lg font-semibold text-gray-400">
              {statTitle}
            </h5>
          ) : (
            <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
              <div className="h-6 bg-gray-200 rounded-md dark:bg-gray-500 w-[100px]" />
            </div>
          )}
        </div>

        {result !== null ? (
          <div className="self-center justify-self-center max-w-fit">
            <span className="font-semibold text-[20px] md:text-[35px] xl:text-[50px] text-blueGray-700 dark:text-gray-200">
              {statTitle === "Sales Value" || statTitle === "Gross Profit"
                ? currencySymbol
                : ""}
              {abbreviateNumber(Math.round(result!)).toLocaleString()}
            </span>
          </div>
        ) : (
          <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
            <div className="h-14 bg-gray-200 rounded-md dark:bg-gray-500 w-[200px] mt-1.5" />
          </div>
        )}

        <div className="flex text-blueGray-400 md:mt-2 items-end h-[28px]">
          {result !== null ? (
            <div className="items-center md:text-xl flex md:mr-4">
              <span className="mr-1">
                {percentage !== null &&
                  (percentage > 0 ? (
                    <FaArrowUpLong color="#10B981" size={14} />
                  ) : (
                    <FaArrowDownLong color="#DC2626" size={14} />
                  ))}
              </span>
              {percentage !== null && (
                <span
                  className={percentage > 0 ? "text-green-500" : "text-red-600"}
                >
                  {percentage.toLocaleString()}%
                </span>
              )}
            </div>
          ) : (
            <div
              role="status"
              className="space-y-2.5 animate-pulse max-w-lg mt-2"
            >
              <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-500 w-[80px]" />
            </div>
          )}
        </div>

        {result !== null ? (
          <div className="-mt-2">
            <p className="text-xs text-center md:text-[15px] dark:text-gray-200">
              {dateRangeText()}
            </p>
          </div>
        ) : (
          <div role="status" className="space-y-2.5 animate-pulse max-w-lg">
            <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-500 w-[150px]" />
          </div>
        )}
      </div>
    </div>
  );
};
