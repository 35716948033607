import { useState, FC } from "react";
import type { DashboardFormProps } from "../../utils/type";
import { ErrorDetail, ReportsService } from "../../api-client";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { handleSuccess, handleError } from "../../utils/functions";

export const DashboardForm: FC<DashboardFormProps> = ({
  dashboard,
  closeModal,
  getDashboards,
}) => {
  const [title, setTitle] = useState<string>(
    dashboard?.title ? dashboard.title : ""
  );
  const [columns, setColumns] = useState<string>(
    dashboard?.columns ? dashboard.columns.toString() : ""
  );
  const [locked, setLocked] = useState<boolean>(
    dashboard?.locked ? dashboard.locked : false
  );
  const { darkMode } = useDarkModeStore();
  const [inputError, setInputError] = useState<ErrorDetail[]>([]);

  const updateDashboard = async () => {
    if (!dashboard) return;

    const integerColumns = Number(columns);

    try {
      const updateResponse = await ReportsService.putReportsKpiDashboardId({
        path: { id: dashboard.id! },
        body: { columns: integerColumns, title, locked },
      });

      if (updateResponse.error) {
        const { errorDetails } = updateResponse.error;
        if (errorDetails) {
          setInputError(errorDetails);
          return;
        }
        handleError(updateResponse.error);
        return;
      }

      handleSuccess("Dashboard is been updated", false);
      getDashboards();
      closeModal();
    } catch (error: any) {
      const { data } = error?.response;
      if (data.errorDetails) {
        setInputError(data.errorDetails);
        return;
      }
      handleError(data || "");

      return;
    }
  };

  const createDashboard = async () => {
    const integerColumns = Number(columns);

    try {
      const createResponse = await ReportsService.postReportsKpiDashboard({
        body: { columns: integerColumns, title, locked },
      });

      if (createResponse.error) {
        const { errorDetails } = createResponse.error;
        if (errorDetails) {
          setInputError(errorDetails);
          return;
        }
        handleError(createResponse.error);
        return;
      }

      handleSuccess("Dashboard is been created", false);
      getDashboards();
      closeModal();
    } catch (error: any) {
      const { data } = error?.response;
      if (data.errorDetails) {
        setInputError(data.errorDetails);
        return;
      }
      handleError(data || "");

      return;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 z-[100]">
      <div className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-gray-700 bg-white rounded-md z-[100]">
        <div className="transition-colors duration-500 rounded-md shadow-2xl dark:shadow-slate-600 ">
          <div className="bg-mainColor dark:bg-mainColorDarkMode transition-colors duration-500 p-2 text-white rounded-t-md">
            <h2 className="p-1 text-2xl">Dashboard details</h2>
          </div>
          <div className="p-5">
            <div className="relative h-[70px]">
              <label
                htmlFor="title"
                className={`absolute text-sm text-[#427dab] dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white px-1 peer-focus:text-blue-600 left-1`}
              >
                Dashboard Title
              </label>
              <input
                type="text"
                id="title"
                onChange={(e) => setTitle(e.target.value)}
                className={`border-[1px] border-[#cccccc] hover:border-[#b3b3b3] dark:bg-bgDarkColor dark:text-gray-200 transition-colors duration-500  w-full h-[41px] rounded-[4px] p-2`}
                value={title}
              />
              {inputError.find((error) => error.field === "title") && (
                <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
                  {
                    inputError.find((error) => error.field === "title")
                      ?.description
                  }
                </div>
              )}
            </div>
            <div className="flex gap-4 items-center justify-between mb-5 mt-3">
              <div className="relative">
                <label
                  htmlFor="columns"
                  className={`absolute text-sm text-[#427dab] dark:text-blue-200 dark:bg-bgDarkColor transition-colors duration-500 -translate-y-[19px] translate-x-[5px] top-2 z-[9] origin-[0] bg-white px-1 peer-focus:text-blue-600 left-1`}
                >
                  Columns
                </label>
                <input
                  type="text"
                  id="columns"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*$/.test(value) || value === "") {
                      setColumns(value);
                    }
                  }}
                  value={columns}
                  className={`border-[1px] border-[#cccccc] hover:border-[#b3b3b3] dark:bg-bgDarkColor dark:text-gray-200 transition-colors duration-500  w-[80px] h-[41px] rounded-[4px] p-2`}
                />
                {inputError.find((error) => error.field === "columns") && (
                  <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
                    {
                      inputError.find((error) => error.field === "columns")
                        ?.description
                    }
                  </div>
                )}
              </div>
              <div className="flex items-center border-[1px] border-[#cccccc] hover:border-[#b3b3b3] rounded h-[41px]">
                <input
                  defaultChecked={locked}
                  onChange={(e) => setLocked(e.target.checked)}
                  id="locked"
                  type="checkbox"
                  name="locked"
                  className="w-7 h-7 text-blue-600 bg-gray-100 border-[1px] border-[#cccccc] hover:border-[#b3b3b3] rounded focus:ring-blue-500 ml-2"
                />
                <label
                  htmlFor="locked"
                  className="w-full py-2 ms-2 text-sm font-medium text-[#427dab] dark:text-gray-300"
                >
                  Lock Changes
                </label>{" "}
                {inputError.find((error) => error.field === "locked") && (
                  <div className="text-red-500 bg-[#f0f4c3] dark:bg-[#282a22] text-sm rounded-b-md p-1.5 pt-0.5">
                    {
                      inputError.find((error) => error.field === "locked")
                        ?.description
                    }
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <button
                onClick={closeModal}
                className="p-2 rounded bg-gray-500 hover:bg-gray-600 text-white shadow-md dark:shadow-slate-600 font-semibold w-[100px]"
              >
                Cancel
              </button>
              {dashboard ? (
                <button
                  onClick={updateDashboard}
                  className="p-2 rounded bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 text-white shadow-md dark:shadow-slate-600 font-semibold w-[100px]"
                >
                  Update
                </button>
              ) : (
                <button
                  onClick={createDashboard}
                  className="p-2 rounded bg-blue-600 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-800 text-white shadow-md dark:shadow-slate-600 font-semibold w-[100px]"
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
