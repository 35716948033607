import {
  InventoryService,
  ItemInventoryLogListResults,
} from "../../api-client";
import {
  LogInventoryFilterType,
  InventorySummaryType,
  SkuPopoverProps,
} from "../../utils/type";
import axios from "axios";
import { Inventory } from "./Inventory";
import ReactLoading from "react-loading";
import { InventoryLog } from "./InventoryLog";
import { useState, useEffect, FC } from "react";
import { handleError } from "../../utils/functions";
import type { DateValueType } from "react-tailwindcss-datepicker";
import { useDarkModeStore } from "../../utils/store/darkModeStore";

export const SkuPopover: FC<SkuPopoverProps> = ({ sku }) => {
  const [inventorySummary, setInventorySummary] =
    useState<InventorySummaryType>({
      data: undefined,
      selectedUom: undefined,
      itemUom: undefined,
      basicInfo: undefined,
    });

  const [inventoryLog, setInventoryLog] =
    useState<ItemInventoryLogListResults | null>(null);
  const [warehouseSelected, setWarehouseSelected] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [logInventoryFilters, setLogInventoryFilters] = useState<
    LogInventoryFilterType[]
  >([]);
  const [dateValues, setDateValues] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });
  const [isDateFiltered, setIsDateFiltered] = useState<boolean>(false);
  const { darkMode } = useDarkModeStore();

  const [transactionType, setTransactionType] = useState<string>();
  const getData = async (): Promise<void> => {
    try {
      const [basicInfoResponse, inventorySummaryResponse, itemUomResponse] =
        await Promise.all([
          InventoryService.getInventoryItemLookupItemsku({
            path: { itemSku: sku! },
          }),
          InventoryService.getInventoryItemItemskuInventory({
            path: { itemSku: sku! },
          }),
          InventoryService.getInventoryItemItemskuUom({
            path: { itemSku: sku! },
          }),
        ]);

      if (basicInfoResponse.error) {
        handleError(basicInfoResponse.error);
        return;
      }
      if (inventorySummaryResponse.error) {
        handleError(inventorySummaryResponse.error);
        return;
      }
      if (itemUomResponse.error) {
        handleError(itemUomResponse.error);
        return;
      }

      setInventorySummary({
        basicInfo: basicInfoResponse.data.data,
        data: inventorySummaryResponse.data.data,
        selectedUom: basicInfoResponse.data.data?.defaultUomId,
        itemUom: itemUomResponse.data.results,
      });
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  useEffect(() => {
    if (!sku) return;
    getData();
  }, []);

  const getInventoryLog = async (
    warehousesId: string | undefined,
    offset: number = 0,
    logInventoryFiltersParam: LogInventoryFilterType[] = logInventoryFilters
  ): Promise<void> => {
    try {
      setLoading(true);
      let queryString = `offset=${offset}`;
      let warehouseString;

      if (warehousesId) {
        setWarehouseSelected(warehousesId);
        warehouseString = warehousesId;
      } else {
        warehouseString = warehouseSelected;
      }
      if (warehouseString && warehouseString !== "all") {
        queryString += `&warehouseId=${warehouseString}`;
      }

      if (dateValues?.startDate && dateValues?.endDate) {
        queryString += `&startDate=${dateValues.startDate}&endDate=${dateValues.endDate}`;
        setIsDateFiltered(true);
      } else {
        setIsDateFiltered(false);
      }
      if (transactionType) {
        queryString += `&transactionType=${transactionType}`;
      }

      if (logInventoryFiltersParam.length > 0) {
        logInventoryFiltersParam.forEach((element) => {
          if (element.name === "Batch") {
            queryString += `&batchId=${element.id}`;
          } else if (element.name === "Bin") {
            queryString += `&bin=${element.id}`;
          }
        });
      }

      const inventoryLogResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/inventory/item/${sku}/inventory-log?${queryString}`
      );

      const { error, errors } = inventoryLogResponse.data;
      if (error || errors) {
        handleError(inventoryLogResponse.data);
        return;
      }

      setInventoryLog(inventoryLogResponse.data);
      setLoading(false);
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
      setLoading(false);
    }
  };

  return (
    <div className="bg-white dark:bg-bgDarkColor rounded-md">
      {loading ? (
        <div className="p-2 min-h-[460px] min-w-[720px] flex justify-center items-center">
          <ReactLoading
            type="spinningBubbles"
            height="250px"
            width="250px"
            color="#4273B8"
          />
        </div>
      ) : inventoryLog ? (
        <InventoryLog
          setInventoryLog={setInventoryLog}
          inventorySummary={inventorySummary}
          inventoryLog={inventoryLog}
          warehouseSelected={warehouseSelected}
          setWarehouseSelected={setWarehouseSelected}
          getInventoryLog={getInventoryLog}
          setDateValues={setDateValues}
          dateValues={dateValues}
          transactionType={transactionType}
          setTransactionType={setTransactionType}
          logInventoryFilters={logInventoryFilters}
          setLogInventoryFilters={setLogInventoryFilters}
          itemUom={inventorySummary.itemUom}
          isDateFiltered={isDateFiltered}
        />
      ) : (
        <Inventory
          sku={sku}
          getInventoryLog={getInventoryLog}
          inventorySummary={inventorySummary}
          setInventorySummary={setInventorySummary}
        />
      )}
    </div>
  );
};
