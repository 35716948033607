import { PAGES } from "../../utils/pages";
import { useState, FC, useEffect } from "react";
import { IoPieChartOutline } from "react-icons/io5";
import { LuSettings } from "react-icons/lu/index.js";
import type { SidebarProps } from "../../utils/type";
import { NavLink, useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io/index.js";
import { BsGraphUp, BsBox } from "react-icons/bs/index.js";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { useUserInfoStore } from "../../utils/store/userStore";

export const Sidebar: FC<SidebarProps> = ({
  reports,
  isCollapsed,
  onToggle,
}) => {
  const [showInventory, setShowInventory] = useState<boolean>(true);
  const [showSales, setShowSales] = useState<boolean>(false);
  const [showSystem, setShowSystem] = useState<boolean>(false);
  const location = useLocation();

  const contentInventoryHeight = reports
    ? reports?.filter((report) => report.reportClass === "inventory").length * 30
    : 0;

  const contentSalesHeight = reports
    ? reports?.filter((report) => report.reportClass === "sales").length * 30
    : 0;
  const { userInfo } = useUserInfoStore();

  useEffect(() => {
    if (isCollapsed) {
      setShowInventory(false);
      setShowSales(false);
      setShowSystem(false);
    }
  }, [isCollapsed]);

  return (
    <div
      className={`fixed transition-all duration-700 px-2 min-h-[100vh] overflow-hidden text-gray-100 z-[100] md:z-[1] ${
        isCollapsed ? "w-[54px]" : "md:w-[280px] w-full"
      } bg-mainColor dark:bg-mainColorDarkMode border-white lg:border-0 shadow-[1.5px_0px_5px_rgba(100,100,100,1)] dark:shadow-[1.5px_0px_5px_rgba(255,255,255,0.4)]`}
    >
      <div className="flex flex-col justify-between h-[98vh]">
        <div className="flex flex-row mt-2">
          <div className="!w-[290px] flex gap-0.5 flex-col mb-5">
            <div className="flex items-center pl-3 py-0.5 gap-1.5">
              <button onClick={onToggle}>
                {!isCollapsed ? (
                  <RiMenuFoldFill size={27} />
                ) : (
                  <RiMenuUnfoldFill size={27} />
                )}
              </button>
              <h1 className="text-[35px] pb-2 whitespace-nowrap">
                Report {userInfo?.locale === "en-us" ? "Center" : "Centre"}
              </h1>
            </div>
            <NavLink
              to={PAGES.insights}
              onClick={() => {
                if (isCollapsed) {
                  onToggle();
                }
              }}
            >
              {({ isActive }) => (
                <div
                  className={`${
                    isActive
                      ? "border-gray-200 bg-[#6391c5] dark:bg-[#0a2649] transitions-all duration-300"
                      : "border-transparent"
                  } rounded-md flex items-center gap-3 w-full h-[30px] p-5 pl-3 border-l-4`}
                >
                  <IoPieChartOutline size={20} color="white" />

                  <h2 className="text-xl font-semibold text-gray-100 whitespace-nowrap">
                    Insights
                  </h2>
                </div>
              )}
            </NavLink>
            <div
              style={{
                height: showInventory
                  ? `${contentInventoryHeight + 50}px`
                  : "45px",
                transition: "height 0.5s ease",
              }}
              className={`${
                location.pathname.includes(PAGES.inventoryReports)
                  ? "border-gray-200 bg-[#6391c5] dark:bg-[#0a2649] transition-all duration-300"
                  : "border-transparent"
              } overflow-y-hidden pr-2 rounded-md p-2 pl-3 border-l-4`}
            >
              <button
                className="flex items-center justify-between w-full"
                onClick={() => {
                  if (isCollapsed) {
                    onToggle();
                  }
                  setShowInventory(!showInventory);
                }}
              >
                <div className="flex gap-3 items-center">
                  <BsBox size={20} />
                  <h2 className="text-xl font-semibold whitespace-nowrap">
                    Inventory Reports
                  </h2>
                </div>
                <div
                  className={`${
                    showInventory ? "rotate-0" : "-rotate-90"
                  } transition-all duration-200 justify-self-end`}
                >
                  <IoIosArrowDown />
                </div>
              </button>
              <div className={`mt-2 `}>
                {reports
                  ? reports
                      .filter((report) => report.reportClass === "inventory")
                      .map((report) => {
                        return (
                          <div className="mt-1 ml-8" key={report.type}>
                            <NavLink
                              to={`${PAGES.inventoryReports}${report.type}`}
                            >
                              {({ isActive, isPending }) => (
                                <div className="group transition duration-500 w-fit text-gray-100 whitespace-nowrap">
                                  {report.name}
                                  <span
                                    className={`group-hover:max-w-full transition-all duration-500 h-0.5 ${
                                      isActive && "max-w-full"
                                    } bg-gray-200 block max-w-0`}
                                  ></span>
                                </div>
                              )}
                            </NavLink>
                          </div>
                        );
                      })
                  : ""}
              </div>
            </div>
            <div
              style={{
                height: showSales ? `${contentSalesHeight + 50}px` : "45px",
                transition: "height 0.5s ease",
              }}
              className={`${
                location.pathname.includes(PAGES.salesReports)
                  ? "border-gray-200 bg-[#6391c5] dark:bg-[#0a2649] transition-all duration-300"
                  : "border-transparent"
              } overflow-y-hidden pr-2 rounded-md p-2 pl-3 border-l-4`}
            >
              <button
                onClick={() => {
                  if (isCollapsed) {
                    onToggle();
                  }
                  setShowSales(!showSales);
                }}
                className="flex items-center justify-between w-full"
              >
                <div className="flex gap-3 items-center">
                  <BsGraphUp size={20} />
                  <h2 className="text-xl font-semibold whitespace-nowrap">
                    Sales Reports
                  </h2>
                </div>
                <div
                  className={`${
                    showSales ? "rotate-0" : "-rotate-90"
                  } transition-height duration-200 justify-self-end`}
                >
                  <IoIosArrowDown />
                </div>
              </button>
              <div className={`mt-2 `}>
                {reports
                  ? reports
                      .filter((report) => report.reportClass === "sales")
                      .map((report) => {
                        return (
                          <div className="mt-1 ml-8" key={report.type}>
                            <NavLink to={`${PAGES.salesReports}${report.type}`}>
                              {({ isActive, isPending }) => (
                                <div className="group transition duration-500 w-fit text-gray-100 whitespace-nowrap">
                                  {report.name}
                                  <span
                                    className={`group-hover:max-w-full transition-all duration-500 h-0.5 ${
                                      isActive && "max-w-full"
                                    } bg-gray-200 block max-w-0`}
                                  ></span>
                                </div>
                              )}
                            </NavLink>
                          </div>
                        );
                      })
                  : ""}
              </div>
            </div>
            <div
              style={{
                height: showSystem ? `140px` : "45px",
                transition: "height 0.5s ease",
              }}
              className={`${
                location.pathname.includes(PAGES.systemReports)
                  ? "border-gray-200 bg-[#6391c5] dark:bg-[#0a2649] transition-all duration-300"
                  : "border-transparent"
              } overflow-y-hidden pr-2 rounded-md p-2 pl-3 border-l-4`}
            >
              <button
                onClick={() => {
                  if (isCollapsed) {
                    onToggle();
                  }
                  setShowSystem(!showSystem);
                }}
                className="flex items-center justify-between w-full"
              >
                <div className="flex items-center gap-3">
                  <LuSettings size={20} />
                  <h2 className="text-xl font-semibold whitespace-nowrap">
                    System
                  </h2>
                </div>
                <div
                  className={`${
                    showSystem ? "rotate-0" : "-rotate-90"
                  } transition-all duration-200 justify-self-end`}
                >
                  <IoIosArrowDown />
                </div>
              </button>
              <div className={`mt-2 ml-8`}>
                <NavLink to={PAGES.schedules}>
                  {({ isActive }) => (
                    <div className="group transition duration-500 mt-1 w-fit text-gray-100">
                      Scheduled Reports
                      <span
                        className={`block rounded-2xl max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 ${
                          isActive && "max-w-full"
                        } bg-gray-200`}
                      ></span>
                    </div>
                  )}
                </NavLink>
                <NavLink to={PAGES.dashboards}>
                  {({ isActive }) => (
                    <div className="group transition duration-500 mt-1 w-fit text-gray-100 whitespace-nowrap">
                      KPI Dashboards
                      <span
                        className={`block rounded-2xl max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 ${
                          isActive && "max-w-full"
                        } bg-gray-200`}
                      ></span>
                    </div>
                  )}
                </NavLink>
                <NavLink to={PAGES.reportServer}>
                  {({ isActive }) => (
                    <div className="group transition duration-500 mt-1 w-fit text-gray-100 whitespace-nowrap">
                      Report Server
                      <span
                        className={`block rounded-2xl max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 ${
                          isActive && "max-w-full"
                        } bg-gray-200`}
                      ></span>
                    </div>
                  )}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
