import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useMemo, FC } from "react";
import { useTable, useFlexLayout } from "react-table";
import { GetInventoryHistoryProps } from "../../../utils/type";
import { inventoryHistoryColumns } from "../../../utils/columns";
import { useUserInfoStore } from "../../../utils/store/userStore";

export const InventoryHistoryTable: FC<GetInventoryHistoryProps> = ({
  data,
  getItemHistory,
}) => {
  const columns = useMemo(
    () =>
      inventoryHistoryColumns.map((column) => {
        if (column.accessor === "details" && data.results) {
          return {
            ...column,
            Header:
              data.results[0]?.documentType === "So" ? "Customer" : "Supplier",
          };
        } else if (column.accessor === "document" && data.results) {
          return {
            ...column,
            Header: data.results[0]?.documentType === "So" ? "Document" : "Rct",
          };
        }
        return column;
      }),
    [data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: data.results || [],
        defaultColumn: { width: 140 },
      },
      useFlexLayout
    );

  const { userInfo } = useUserInfoStore();

  return (
    <div>
      <div className="max-h-[500px] h-fit overflow-y-auto border border-r-0">
        <div className="max-h-[260px] w-[710px] overflow-x-auto">
          <table
            {...getTableProps()}
            className="divide-y divide-gray-200 border-b border-gray-200 w-full dark:divide-gray-500 dark:border-gray-500"
          >
            <thead className="bg-gray-50 dark:bg-gray-700  ">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className={`shadow relative p-2 text-left text-xs font-medium text-gray-500 border-b border-r border-gray-300 flex dark:text-gray-300 dark:border-gray-500`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className="bg-white divide-y divide-gray-200 dark:divide-gray-500 dark:bg-gray-900"
            >
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <tr
                    {...row.getRowProps()}
                    className={`bg-white divide-gray-200 dark:divide-gray-500 dark:bg-gray-900`}
                  >
                    {row.cells.map((cell) => {
                      const isDocument = cell.column.id === "document";
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="text-left text-xs p-1 text-gray-500 border-r border-gray-300 truncate dark:text-gray-100 dark:border-gray-500"
                        >
                          {isDocument ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:bg-blue-100 hover:text-blue-600 cursor-pointer"
                              href={`${userInfo?.organization?.appUrl}go/${row.original.documentType}/${row.original.documentId}`}
                            >
                              {cell.render("Cell")}
                            </a>
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="">
          {data.results && data.results.length > 0 ? (
            <div className="flex items-center justify-center gap-3 text-sm pt-1 pb-1.5 border-r">
              <button
                onClick={() =>
                  getItemHistory(
                    data.results![0].documentType === "So"
                      ? "SALES"
                      : "PURCHASES",
                    (data.offset || 0) - 5
                  )
                }
                className="disabled:opacity-30"
                disabled={(data.offset || 0) === 0}
              >
                <MdOutlineKeyboardDoubleArrowLeft size={20} />
              </button>
              <span>{(data.offset || 0) / 5 + 1}</span>
              <button
                disabled={(data.totalCount || 0) <= (data.offset || 0) + 5}
                className="disabled:opacity-30"
                onClick={() =>
                  getItemHistory(
                    data.results![0].documentType === "So"
                      ? "SALES"
                      : "PURCHASES",
                    5 + (data.offset || 0)
                  )
                }
              >
                <MdOutlineKeyboardDoubleArrowRight size={20} />
              </button>
            </div>
          ) : (
            <div className="w-full flex justify-center my-2">
              <p className="text-gray-600 dark:text-gray-300 text-sm">
                No data available
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
