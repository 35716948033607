import { Chart, registerables } from "chart.js";
import { useEffect, useRef, FC } from "react";
import { LineChartProps } from "../../utils/type";

export const LineChart: FC<LineChartProps> = ({ data1, data2 }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart | null>(null);

  useEffect(() => {
    // Register the components needed for the chart
    Chart.register(...registerables);

    // This function will set up the chart
    const setupChart = () => {
      const ctx = chartRef.current?.getContext("2d");
      if (ctx) {
        // If a chart instance already exists, destroy it
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }

        const config = {
          type: "line",
          data: {
            labels: data1 ? data1.map((item) => item.dateName) : null,
            datasets: [
              {
                label: "Gross Profit",
                tension: 0.4,
                backgroundColor: "#30e3cb",
                borderColor: "#30e3cb",
                data: data2 ? data2.map((item) => item.grossProfit) : null,
              },
              {
                label: "Sales Value",
                tension: 0.4,
                backgroundColor: "#6e6ed7",
                borderColor: "#6e6ed7",
                data: data1 ? data1.map((item) => item.salesValueGross) : null,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            color: "#94a3b8",
            scales: {
              y: {
                color: "#94a3b8",

                ticks: {
                  color: "#94a3b8",
                },
              },
              x: {
                ticks: {
                  color: "#94a3b8",
                },
              },
            },
            plugins: {
              legend: {
                position: "top",
                labels: {
                  usePointStyle: true,
                },
              },
              title: {
                display: true,
                text: "Sales Insights",
                align: "start",
                color: "#9ca3af",
                font: {
                  size: 20,
                  family: "'Open Sans', sans-serif",
                },
              },
              tooltip: {
                mode: "index",
                intersect: false,
                color: "#6b7280",
              },
              hover: {
                mode: "nearest",
                intersect: true,
              },
            },
          },
        };

        // Create the new chart instance and store the reference
        //@ts-ignore
        chartInstanceRef.current = new Chart(ctx, config);
      }
    };

    setupChart();

    // Cleanup function to destroy the chart instance
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [data1, data2]);

  return (
    <div className="relative flex flex-col min-w-0 break-words shadow-md dark:shadow-slate-600 rounded-md bg-white dark:bg-[#334155] transition-colors duration-500">
      <div className="p-5 flex-auto">
        <div className="relative min-h-[350px]">
          {data1 && data2 ? (
            <canvas ref={chartRef} id="sales-chart" />
          ) : (
            <div role="status" className="space-y-2.5 animate-pulse ">
              <div className="h-[350px] bg-gray-200 rounded-sm dark:bg-gray-500 w-full" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
