
import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md";
import { ReportsService } from "../../api-client";
import { ScheduleTableType } from "../../utils/columns";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { handleError, handleSuccess, swalStyle } from "../../utils/functions";

export const DeleteSchedule = ({
  schedule,
  getSchedules,
}: {
  schedule: ScheduleTableType;
  getSchedules: () => void;
}) => {
  const { darkMode } = useDarkModeStore();

  const handleDeleteSchedule = async () => {
    try {
      const response = await ReportsService.deleteReportsSchedulesId({
        path: { id: schedule?.id! },
      });

      if (response.error) {
        handleError(response.error);
        return;
      }

      handleSuccess("Schedule has been deleted", false);
      getSchedules();
    } catch (error: any) {
      if (error.response) {
        handleError(error.response.data);
      } else {
        handleError(error.message);
      }
    }
  };

  const handleConfirmDelete = () => {
    Swal.fire({
      title: `Are you sure you want to delete ${schedule?.name}?`,
      position: "top",
      ...swalStyle,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.close();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.close();
        handleDeleteSchedule();
      }
    });
  };
  return (
    <div className="flex justify-center">
      <MdDelete
        className="text-red-500 hover:text-red-700 cursor-pointer"
        size={20}
        onClick={handleConfirmDelete}
      />
    </div>
  );
};
