import { create } from "zustand";
import { User } from "../../api-client";

export interface UserInfoInterface {
  userInfo: User | undefined;
  setUserInfo: (value: User | undefined) => void;
}

export const useUserInfoStore = create<UserInfoInterface>((set) => ({
  userInfo: undefined,
  setUserInfo: (value: User | undefined) => set({ userInfo: value }),
}));
