import { SalesOverviewSegmentItem } from "../../api-client";
import { useState, useEffect, ChangeEvent, FC } from "react";
import { useDarkModeStore } from "../../utils/store/darkModeStore";

interface TopItemsProps {
  tableData: SalesOverviewSegmentItem[] | null | undefined;
  currencySymbol: string | undefined | null;
}

export const CardItems: FC<TopItemsProps> = ({
  tableData: initialTableData,
  currencySymbol,
}) => {
  const [tableData, setTableData] = useState(initialTableData);
  const [sortValue, setSortValue] = useState<string>("salesValueGross");

  const sort = (sortField: string) => {
    if (initialTableData) {
      const sortedData = [...initialTableData].sort((a, b) =>
        a[sortField as keyof SalesOverviewSegmentItem]! <
        b[sortField as keyof SalesOverviewSegmentItem]!
          ? 1
          : b[sortField as keyof SalesOverviewSegmentItem]! <
            a[sortField as keyof SalesOverviewSegmentItem]!
          ? -1
          : 0
      );
      setTableData(sortedData);
    } else {
      setTableData(tableData);
    }
  };

  useEffect(() => {
    sort(sortValue);
  }, [sortValue, initialTableData]);

  const handleSortChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSortValue(event.target.value);
  };
  const { darkMode } = useDarkModeStore();

  return (
    <div className="p-5 bg-white dark:bg-[#334155] transition-colors duration-500 rounded shadow-md dark:shadow-slate-600 w-full dark:text-gray-400">
      <div className="flex justify-between items-center mb-6">
        <h4 className="font-bold text-[20px] text-gray-400">Top Items</h4>
        <select
          value={sortValue}
          onChange={handleSortChange}
          disabled={!tableData}
          id="sort-select"
          className="bg-gray-50 dark:bg-transparent dark:text-gray-400 border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5"
        >
          <option value="salesValueGross">By Sales</option>
          <option value="itemsSold">By Sold Items</option>
        </select>
      </div>
      {tableData ? (
        <table className="w-full border rounded">
          <thead>
            <tr className="text-left bg-gray-100 dark:bg-[#0f172a] transition-colors duration-500">
              <th className="p-2 font-medium text-gray-500 dark:text-gray-400 uppercase border-b border-gray-300">
                SKU
              </th>
              <th className="min-w-[60px] hidden sm:table-cell py-3 p-2 font-medium text-gray-500 dark:text-gray-400  uppercase border-b border-gray-300">
                Title
              </th>
              <th className="p-2 text-right font-medium text-gray-500 dark:text-gray-400  uppercase border-b border-gray-300">
                Sales Value
              </th>
              <th className="p-2 text-right font-medium text-gray-500 dark:text-gray-400  uppercase border-b border-gray-300">
                Items Sold
              </th>
            </tr>
          </thead>
          <tbody className="">
            {tableData.map((data, i) => (
              <tr
                key={i}
                className={`border-b ${
                  i % 2 === 1 ? (darkMode ? "bg-[#1e293b]" : "bg-gray-50") : ""
                }`}
              >
                <td className="pr-4 pl-1">
                  <div className="flex gap-2 text-left text-sm p-1 text-gray-500 dark:text-gray-400 border-gray-300">
                    {data.itemSku}
                  </div>
                </td>
                <td className="pr-10 hidden sm:table-cell py-3">
                  <p className="max-w-[300px] md:max-w-[250px] lg:max-w-[550px] xl:max-w-[800px] overflow-hidden whitespace-nowrap text-ellipsis text-left text-sm text-gray-500 dark:text-gray-400 border-gray-300">
                    {data.itemTitle}
                  </p>
                </td>
                <td className="pr-4 text-right text-sm p-2 text-gray-500 dark:text-gray-400 border-gray-300">
                  {currencySymbol}
                  {Math.round(data.salesValueGross!).toLocaleString()}
                </td>
                <td className="pr-2 text-right text-sm p-2 text-gray-500 dark:text-gray-400 border-gray-300">
                  {data.itemsSold?.toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div role="status" className="space-y-2.5 animate-pulse">
          <div className="h-[350px] bg-gray-200 rounded-sm dark:bg-gray-500 w-full"></div>
        </div>
      )}
    </div>
  );
};
