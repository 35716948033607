export type Page = {
  url: string;
  title: string;
};

export type PageKeys =
  | "insights"
  | "inventoryReports"
  | "salesReports"
  | "schedules"
  | "dashboards"
  | "reportServer";

export type Pages = Record<PageKeys, string>;

export const PAGES = {
  insights: "/reports/insights",
  inventoryReports: "/reports/inventory_reports/",
  salesReports: "/reports/sales_reports/",
  systemReports: "/reports/system_reports",
  schedules: "/reports/system_reports/schedules",
  dashboards: "/reports/system_reports/dashboards",
  reportServer: "/reports/system_reports/reportServer",
};
