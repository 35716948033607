import { FC } from "react";
import {
  asMoney,
  inventoryTypeOptions,
  periodTypeOptions,
  as3decimals,
  periodTypeOptionsName,
} from "../../utils/functions";
import { Cell } from "react-table";
import { SkuPopover } from "../quickView/SkuPopover";
import { DateFormatterCell } from "./DateFormatterCell";
import { OrderDrilldown } from "../quickView/OrderDrilldown";
import { useDarkModeStore } from "../../utils/store/darkModeStore";
import { CellTableProps, TableColumnType } from "../../utils/type";
import { Popover, Position, PopoverInteractionKind } from "@blueprintjs/core";

export const ReportTableCell: FC<CellTableProps> = ({
  row,
  virtualRow,
  length,
  columns,
  isForecast,
  period,
  parametersString,
}) => {
  const { darkMode } = useDarkModeStore();

  function getClassName(
    cell: Cell<Record<string, any>, any>,
    tableColumnCell: TableColumnType,
    cellIndex: number
  ) {
    const { accessor } = tableColumnCell;
    const isPeriodColumn = accessor?.startsWith("M");
    const isLeadTime = accessor?.endsWith("leadTimeRequired");
    const isRequiredColumns = accessor === "forecastResults.inventoryRequired";
    const isForecastPPColumn = accessor === "forecastResults.perPeriodConsumed";

    let className =
      "p-1.5 border-r border-gray-300 z-0 text-xs dark:text-gray-100 transition-colors duration-500";

    if (isPeriodColumn) {
      const promoQty = cell.row.original[`periodResults.${accessor}.promoQty`];
      const qty = cell.row.original[`periodResults.${accessor}.qty`];

      if (promoQty > 0 && qty > promoQty) {
        className += " bg-yellow-100";
      } else if (promoQty > 0 && qty === promoQty) {
        className += " bg-red-200";
      }
    }

    if (
      !isForecastPPColumn &&
      !isLeadTime &&
      !isRequiredColumns &&
      virtualRow.index % 2 === 0
    ) {
      className += " bg-white dark:bg-bgDarkColor";
    } else {
      className += " bg-blue-50 dark:bg-black";
    }

    if (cell.row.index !== length - 1) {
      className += " border-b";
    }

    if (cellIndex === 0) {
      className += " shadow-tableRight dark:shadow-darkTableRight border-l";
    }

    if (tableColumnCell.boxShadowLeft) {
      className += " shadow-tableLeft dark:shadow-darkTableLeft";
    }

    if (isForecastPPColumn) {
      className += " bg-green-200 dark:bg-green-900 font-semibold";
    }

    if (isLeadTime) {
      className += " bg-yellow-100 dark:bg-yellow-800";
    }

    if (isRequiredColumns) {
      className += " bg-red-200 dark:bg-red-900 font-semibold";
    }

    return className;
  }

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell, cellIndex) => {
        const column = columns[cellIndex];
        const accessor = column.accessor;
        const columnType = column.type;

        const isPeriodColumn = accessor.startsWith("M");
        const isLeadTime = accessor?.endsWith("leadTimeRequired");
        const isInventoryColumn = accessor?.endsWith("currenctInventory");
        const isForecastColumn =
          accessor?.startsWith("forecastResults") &&
          !accessor.endsWith("currenctInventory");

        return (
          <td
            {...cell.getCellProps()}
            key={cellIndex}
            className={getClassName(cell, column, cellIndex)}
          >
            {columnType === "DATE" && <DateFormatterCell date={cell.value} />}
            {columnType === "SKU" ? (
              <Popover
                popoverClassName={darkMode ? "bp5-dark" : ""}
                content={<SkuPopover sku={cell.value} />}
                position={Position.RIGHT}
                hoverOpenDelay={100}
                interactionKind={PopoverInteractionKind.CLICK}
              >
                <p
                  className={`text-blue-600 dark:text-blue-500 cursor-pointer mb-0 ${
                    isForecast ? "line-clamp-5" : "whitespace-nowrap truncate"
                  }`}
                >
                  {cell.value}
                </p>
              </Popover>
            ) : null}
            {isForecastColumn &&
              (() => {
                if (isLeadTime) {
                  return (
                    <div>
                      <p className="mb-0">
                        {cell.row.original["leadTime"]}{" "}
                        {cell.row.original["leadTime"] > 1 && " Days"}
                        {cell.row.original["leadTime"] === 1 && " Day"}
                      </p>
                      <p className="mb-0">
                        {cell.row.original["forecastResults.leadTimeRequired"] >
                          0 &&
                          as3decimals(
                            cell.row.original[
                              "forecastResults.leadTimeRequired"
                            ]
                          )}
                      </p>
                    </div>
                  );
                } else {
                  const relevantKey = Object.keys(cell.row.original).find(
                    (key) => key === accessor
                  );
                  if (relevantKey !== undefined) {
                    return (
                      <p key={relevantKey} className="mb-0">
                        {cell.row.original[relevantKey] > 0
                          ? as3decimals(cell.row.original[relevantKey])
                          : null}
                        {relevantKey === "forecastResults.inventoryDuration" &&
                          cell.row.original[relevantKey] > 1 &&
                          " Days"}
                        {relevantKey === "forecastResults.inventoryDuration" &&
                          cell.row.original[relevantKey] === 1 &&
                          " Day"}
                      </p>
                    );
                  } else {
                    return null;
                  }
                }
              })()}
            {isPeriodColumn &&
              (() => {
                const daysOtOfStockPercent =
                  cell.row.original[
                    `periodResults.${accessor}.daysOtOfStockPercent`
                  ];
                let barColor;
                if (daysOtOfStockPercent < 33) barColor = "bg-green-500";
                if (daysOtOfStockPercent < 66) barColor = "bg-orange-500";
                if (daysOtOfStockPercent < 100) barColor = "bg-yellow-400";
                if (daysOtOfStockPercent === 100) barColor = "bg-red-400";
                return (
                  <div className="flex flex-col justify-start -mt-1">
                    <div className="w-fit h-[24px]">
                      {cell.row.original[`periodResults.${accessor}.qty`] >
                        0 && (
                        <Popover
                          popoverClassName={darkMode ? "bp5-dark" : ""}
                          content={
                            <OrderDrilldown
                              itemSku={cell.row.original.itemSku}
                              period={period.filter((p) => p.name === accessor)}
                              parametersString={parametersString}
                            />
                          }
                          hoverOpenDelay={100}
                          interactionKind={PopoverInteractionKind.CLICK}
                        >
                          <p className="text-lg font-semibold text-blue-600 dark:text-blue-500 cursor-pointer whitespace-nowrap truncate mb-0 w-fit">
                            {as3decimals(
                              cell.row.original[`periodResults.${accessor}.qty`]
                            )}
                          </p>
                        </Popover>
                      )}
                    </div>
                    <div className="h-[16px] text-[11px]">
                      {cell.row.original[`periodResults.${accessor}.promoQty`] >
                        0 && (
                        <p className="mb-0 ">
                          <span
                            data-tooltip-id="name"
                            data-tooltip-content="Promotion quantity"
                          >
                            <strong className="dark:text-gray-100">P</strong>:{" "}
                          </span>
                          {as3decimals(
                            cell.row.original[
                              `periodResults.${accessor}.promoQty`
                            ]
                          )}
                        </p>
                      )}
                    </div>
                    {Object.keys(cell.row.original).map((key) => {
                      if (
                        isPeriodColumn &&
                        key.startsWith(`periodResults.${accessor}.`) &&
                        (key.endsWith(`openingStock`) ||
                          key.endsWith(`closingStock`) ||
                          key.endsWith(`daysOtOfStock`))
                      ) {
                        return (
                          <div
                            key={key}
                            className="flex justify-start text-[11px]"
                          >
                            <span
                              data-tooltip-id="name"
                              data-tooltip-content={`${
                                periodTypeOptionsName[key.split(".")[2]]
                              }`}
                            >
                              <p key={key} className="mb-0 dark:text-gray-100">
                                <strong>
                                  {periodTypeOptions[key.split(".")[2]]}
                                </strong>
                                : {as3decimals(cell.row.original[key])}
                              </p>
                            </span>
                          </div>
                        );
                      }
                      return null;
                    })}
                    <span
                      data-tooltip-id="percentage"
                      data-tooltip-content={`${daysOtOfStockPercent}% of the period out of stock`}
                    >
                      <div className="h-1.5 w-[70px] bg-green-400 rounded shadow-md">
                        <div
                          style={{ width: `${daysOtOfStockPercent}%` }}
                          className={`h-1.5 ${barColor} rounded shadow-md`}
                        />
                      </div>
                    </span>
                  </div>
                );
              })()}
            {isInventoryColumn && (
              <div>
                {cell.row.original["forecastResults.currenctInventory"] !==
                  0 && (
                  <p className="font-bold text-md mb-0">
                    {as3decimals(
                      cell.row.original["forecastResults.currenctInventory"]
                    )}
                  </p>
                )}
                {Object.keys(cell.row.original).map((key, i) => {
                  if (
                    isInventoryColumn &&
                    key.startsWith("inventory") &&
                    cell.row.original[key] > 0
                  ) {
                    return (
                      <p key={key + i} className="mb-0">
                        <strong>
                          {inventoryTypeOptions[key.split(".")[1]]}
                        </strong>
                        : {as3decimals(cell.row.original[key])}
                      </p>
                    );
                  }
                  return null;
                })}
              </div>
            )}
            {columnType === "TEXT" && !isPeriodColumn && !isInventoryColumn ? (
              <p
                className={`${
                  isForecast ? "line-clamp-5" : "whitespace-nowrap truncate"
                } mb-0`}
              >
                {cell.value}
              </p>
            ) : (
              typeof cell.value === "number" &&
              cell.value !== 0 &&
              (columnType === "MONEY"
                ? asMoney(cell.value)
                : as3decimals(cell.value))
            )}
          </td>
        );
      })}
    </tr>
  );
};
